/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

import CenterLayout from "../../../layouts/CenterLayout";
import NewsPage from "../../News/NewsPage";

import NewsFrom from "./NewsForm";
import { LittleCard } from "../../../components/molecules/AdminPanel/index";
import { useParams } from "react-router";
import { fetchOneNews } from "../../../api/News/News";

export default function ANews({ propsEditing }) {
  const [edit, setEdit] = useState(false);
  const [news, setNews] = useState(null);
  const { id } = useParams();

  const getOneNews = async () => {
    setNews(await fetchOneNews(id));
  };

  useEffect(() => {
    if (propsEditing && id) {
      getOneNews();
      setEdit(true);
    }
  }, [propsEditing]);
  return (
    <CenterLayout title="News" className="Shares_Pages News_Pages">
      {edit ? (
        <NewsFrom
          id="admin-shares-form"
          onClick={() => setEdit(false)}
          newsProps={propsEditing ? news : null}
        />
      ) : (
        <div className="admin-display-littlecard-and-other-things">
          <div style={{ marginTop: "-5%", zIndex: "-1" }} />
          <LittleCard
            buttonText="Rédiger un article"
            icon="icon-write-bubble"
            onClick={() => setEdit(true)}
            style={{ marginTop: "-30px" }}
          />
          <div>
            <NewsPage />
          </div>
        </div>
      )}
    </CenterLayout>
  );
}
