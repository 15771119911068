export default function LinkIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16.08" height="16.066" viewBox="0 0 16.08 16.066">
            <g id="Groupe_1884" data-name="Groupe 1884" transform="translate(-149.26 -369.175)">
                <g id="Icon_feather-link" data-name="Icon feather-link" transform="translate(146.007 367.909)">
                    <path id="Tracé_15938" data-name="Tracé 15938" d="M15,11.019a3.643,3.643,0,0,0,5.494.393L22.68,9.227a3.643,3.643,0,1,0-5.152-5.152L16.275,5.321" transform="translate(-5.165 -0.991)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                    <path id="Tracé_15939" data-name="Tracé 15939" d="M11.74,14.953a3.643,3.643,0,0,0-5.494-.393L4.06,16.746A3.643,3.643,0,1,0,9.212,21.9l1.246-1.246" transform="translate(1.01 -6.383)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                </g>
            </g>
        </svg>

    )
}