import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import DefaultModal from "../../molecules/Modals/Default";
const SecondDropdown = ({ data }) => {
  const [isFocused, setIsFocused] = useState(false);
  const { title, icon, subLinks, color, path } = data;
  let location = useLocation();

  useEffect(() => {
    setIsFocused(false);
  }, [location]);

  return subLinks ? (
    <ul>
      <li
        className="mob-navbar__second-dropdown-title"
        onClick={() => setIsFocused(!isFocused)}
      >
        <img src={"/assets/img/icons/" + icon} alt="icon" />
        <p>{title}</p>
      </li>
      {isFocused && (
        <DefaultModal
          isOpen={isFocused}
          setIsOpen={setIsFocused}
          className="mob-navbar__second-dropdown-modal"
        >
          <li>
            <div className="mob-navbar__second-dropdown">
              <div>
                <p className={`bg-${color}`}>
                  <Link to={path}>
                    <i className="icon-full-arrow"></i> {title}
                  </Link>
                </p>
                <ul>
                  {subLinks &&
                    subLinks.map((item, index) => (
                      <li key={index}>
                        <div
                          className={
                            "mob-navbar__second-dropdown-title__point bg-" +
                            color
                          }
                        ></div>
                        <Link to={item.path}>{item.title}</Link>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </li>
        </DefaultModal>
      )}
    </ul>
  ) : (
    <ul>
      <li className="mob-navbar__second-dropdown-title">
        <Link to={path}>
          <img src={"/assets/img/icons/" + icon} alt="icon" />
          <p>{title}</p>
        </Link>
      </li>
    </ul>
  );
};

export default SecondDropdown;
