import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/swiper-bundle.css";
import "./style.css";

import SwiperCore, { Scrollbar, Mousewheel, Navigation } from "swiper/core";
import { Link } from "react-router-dom";

SwiperCore.use([Scrollbar, Mousewheel, Navigation]);

export function Slider({ cta, title, children }) {
  return (
    <div className="sliderCTA__container">
      <h1 className="sliderCTA__title">{title}</h1>
      <div className="sliderCTA__pagination-arrow-prev"></div>
      <div className="sliderCTA__pagination-arrow-next"></div>
      <Swiper
        direction={"horizontal"}
        slidesPerView={"auto"}
        freeMode={true}
        mousewheel={true}
        navigation={{
          prevEl: ".sliderCTA__pagination-arrow-prev",
          nextEl: ".sliderCTA__pagination-arrow-next",
        }}
      >
        {children}
        <SwiperSlide>
          <Link to={cta.path}>
            <div>
              <h1>Voir</h1>
              <h2>{cta.text}</h2>
            </div>
          </Link>
        </SwiperSlide>
      </Swiper>
    </div>
  );
}
