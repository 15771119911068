import { useState } from "react";
import Map from "components/Map/map.component";
import SearchBar from "../searchbar/searchBar";

import List from "./list";
import Modal from "./modal";

export default function Content({
  loading = false,
  mode = "list",
  setMode = () => { },
  setSearch = () => { },
  search = "",
  users,
  onScrollEnd,
  isEndScroll = false,
}) {
  const [selectedUser, setSelectedUser] = useState(null);
  const [isOpened, setIsOpened] = useState(false);
  const handleSelectUser = (user) => {
    setSelectedUser(user);
    setIsOpened(true);
  };
  return (
    <div className="annuaire__content">
      <SearchBar
        mode={mode}
        setMode={setMode}
        setSearch={setSearch}
        search={search}
        list={users}
      />

      <div className={mode === "filter" && "filter-open"}>
        {(mode === "list" || mode === "search" || mode === "filter") && (
          <>
            {users.length ? <List list={users} onSeletedUser={handleSelectUser} onScrollEnd={onScrollEnd} loading={loading} isEndScroll={isEndScroll} /> : <p className="annuaire__no-result">Aucun résultat</p>}
          </>
        )}
      </div>
      {mode === "map" && <Map data={users} onSeletedUser={handleSelectUser} />}



      <Modal
        user={selectedUser}
        isOpen={isOpened}
        onClose={() => setIsOpened(false)}
      />
    </div>
  );
}
