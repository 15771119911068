import axios from "axios";
import getFormatedToken from "../../helpers/getFormatedToken";

export async function sendSubscribe(id) {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/api/main-themes/${id}/subscribe`,
    null,
    getFormatedToken()
  );

  return response;
}
