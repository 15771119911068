export const LoginAzure = ({ authAD }) => {
  return (
    <div className="div-login-microsoft">
      <p className="p-login-microsoft">
        Connexion <br />
        HRTEAM à <strong>Microsoft - Azure</strong>
      </p>
      <button
        className="button-login-microsoft"
        style={{ marginTop: "10px" }}
        onClick={(e) => {
          e.preventDefault();
          window.open(
            `${process.env.REACT_APP_API_URL}/api/azure/signin/`,
            "_self"
          );
        }}
      >
        <img src="/assets/img/microsoft-logo.png" height={20} />
        S'IDENTIFIER
      </button>
      {/*       {authAD.status && (
        <div className="error-popup">
          <h3>ERREUR</h3>
          <p>{authAD.message}</p>
        </div>
      )} */}
    </div>
  );
};
