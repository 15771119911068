export default function WarningIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.746"
      height="15.456"
      viewBox="0 0 16.746 15.456"
    >
      <path
        d="M10.656,5.14,3.528,18.149a1.223,1.223,0,0,0,1.091,1.807H18.879a1.226,1.226,0,0,0,1.091-1.807L12.838,5.14A1.25,1.25,0,0,0,10.656,5.14Zm1.8,5.4-.145,4.91H11.183l-.145-4.91Zm-.708,7.583a.741.741,0,1,1,.769-.741A.748.748,0,0,1,11.747,18.121Z"
        transform="translate(-3.375 -4.5)"
        fill="currentColor"
      />
    </svg>
  );
}
