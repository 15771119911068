import axios from "axios";
import moment from "moment";
import Cookies from "universal-cookie";
const FileDownload = require("js-file-download");

export async function ConnectionReports() {
  try {
    const cookies = new Cookies();
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/reports/login `,
      {
        headers: {
          Authorization: `Bearer ${cookies.get("::token")}`,
        },
        responseType: "blob",
      }
    );
    FileDownload(
      response.data,
      `connection-reporting-${moment().format("DD-MM-YYYY")}.xls`
    );
    return true;
  } catch (error) {
    // TODO: Handle error properly
    // console.log(error);
    return false;
  }
}
