import axios from "axios";
import getFormatedToken from "helpers/getFormatedToken";

export async function editExpertises(userId, expertiseId, name, force) {
  const response = await axios.put(
    `${process.env.REACT_APP_API_URL}/api/expertises/user/update`,
    {
      userId: userId,
      [expertiseId ? "expertiseId" : "name"]: expertiseId || name,
      force: force,
    },
    getFormatedToken()
  );

  const { data } = response.data;
  return data || response.data;
}
