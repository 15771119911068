/* eslint-disable react-hooks/exhaustive-deps */
import DefaultModal from "../Modals/Default";
import { useState, useEffect } from "react";
import "./style.css";

export default function CookiesGDPR({ openModal, setOpenModal }) {
  const [cookiegdprParams, setCookiegdprParams] = useState({
    analytics: true,
    engine: true,
  });

  useEffect(() => {
    if (localStorage.getItem("cookiegdpr")) {
      setCookiegdprParams(JSON.parse(localStorage.getItem("cookiegdpr")));
    } else {
      setOpenModal(true);
    }
  }, []);

  const changeCookiesParams = (e) => {
    e.preventDefault();
    localStorage.setItem("cookiegdpr", JSON.stringify(cookiegdprParams));
    setOpenModal(false);
    // if (cookiegdprParams.analytics === false) {
    //   document.cookie = "_gid" + "=; Max-Age=0";
    //   document.cookie = "_ga" + "=; Max-Age=0";
    //   document.cookie = "_gat" + "=; Max-Age=0";
    // }
  };

  return (
    <DefaultModal
      isOpen={openModal}
      setIsOpen={setOpenModal}
      title="Gérer mes cookies"
      className="cookies__modal"
      zIndex={10000}
    >
      <form onSubmit={changeCookiesParams}>
        <p>
          Nous conservons votre choix pendant 6 mois. Vous pouvez changer d'avis
          à tout moment en cliquant sur l'icône "gérer les cookies" en bas à
          gauche de chaque page de notre site.
        </p>
        <br />
        <br />
        <h2>Matomo Analytics</h2>
        <div className="cookies__input-container">
          <div className="switch-input">
            {cookiegdprParams.analytics ? (
              <input
                type="checkbox"
                id="analytics"
                name="analytics"
                checked={cookiegdprParams.analytics}
                onChange={(e) => {
                  setCookiegdprParams({
                    ...cookiegdprParams,
                    analytics: e.target.checked,
                  });
                }}
              />
            ) : (
              <input
                type="checkbox"
                id="analytics"
                name="analytics"
                onChange={(e) => {
                  setCookiegdprParams({
                    ...cookiegdprParams,
                    analytics: e.target.checked,
                  });
                }}
              />
            )}
            <div className="switch-input__visual"></div>
          </div>
          <div className="cookies__description">
            <p>
              Le cookie Matomo Analytics nous permet d'analyser les performances
              de la plateforme HR TEAM. Il nous permet également de suivre les
              actions et les pages les plus populaires.
            </p>
          </div>
        </div>
        <br />
        <h2>Cookie de session (obligatoire)</h2>
        <div className="cookies__input-container">
          <div className="switch-input disabled">
            <input
              type="checkbox"
              id="enginecookies"
              name="enginecookies"
              checked={true}
              disabled={false}
            />
            <div className="switch-input__visual"></div>
          </div>
          <div className="cookies__description">
            <p>
              Le cookie ::token est une chaîne de caractères unique générée par
              le protocole JWT (JSON Web Token) et permettant d'authentifier
              l'utilisateur avec persistance (rechargement). Le cookie
              connect.sid est un cookie de session généré par le serveur qui
              permet de stocker les informations de session créer avec Azure
              (microsoft).
            </p>
          </div>
        </div>

        <button
          className="btn btn-small bg-main"
          type="submit"
          style={{ marginTop: "40px" }}
        >
          Valider mes choix
        </button>
      </form>
    </DefaultModal>
  );
}
