import React, { useState } from "react";
import CenterLayout from "../../../layouts/CenterLayout";
import QuestionPage from "../../Questions/QuestionPage";

import { LittleCard } from "../../../components/molecules/AdminPanel/index";
import FaqForm from "./FaqForm";

export default function Questions() {
  const [edit, setEdit] = useState(false);

  return (
    <CenterLayout title="FAQ" className={false}>
      {edit ? (
        <FaqForm onClick={() => setEdit(false)} />
      ) : (
        <React.Fragment>
          <LittleCard
            buttonText="Ajouter une question RH"
            icon="icon-write-bubble"
            onClick={() => setEdit(true)}
            style={{ maxWidth: "900px", margin: "auto" }}
          />
          <QuestionPage />
        </React.Fragment>
      )}
    </CenterLayout>
  );
}
