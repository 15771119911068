import Theme from "../Icons";
import "./style.css";

export default function DropdownIcons({ thematics }) {
  return (
    <div className="dropdown-icons__thematics">
      <p>
        <Theme theme={thematics[0]} />
        {thematics.length > 1 && `+${thematics.length - 1}`}
      </p>
      {thematics.length > 1 && (
        <div>
          {thematics.map((thematic, index) => {
            return index > 0 && <Theme key={index} theme={thematic} />;
          })}
        </div>
      )}
    </div>
  );
}
