import axios from "axios";
import getFormatedToken from "../../helpers/getFormatedToken";

export async function fetchAllThemes(id) {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/api/main-themes/`,
    getFormatedToken()
  );
  const { data } = response.data;

  return data.mainThemes;
}

export async function updateTheme({ id, n1, n2, n3, description, content }) {
  try {
    let themeData = {};
    themeData["n1"] = n1;
    themeData["n2"] = n2;
    themeData["n3"] = n3;
    if (description) {
      themeData["description"] = description;
    }
    if (content) {
      themeData["Content"] = { html: content, lang: "FR", state: "PUBLISHED" };
    }

    await axios({
      method: "PUT",
      url: `${process.env.REACT_APP_API_URL}/api/main-themes/${id}`,
      data: themeData,
      ...getFormatedToken(false, true),
    });
  } catch (error) {
    // TODO: Handle error properly
    // console.log(error);
    return error;
  }
}
