/* eslint-disable no-unused-vars */
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function useAnalytics() {
  const { trackPageView } = useMatomo();
  let location = useLocation();

  useEffect(() => {
    if (!localStorage.getItem("cookiegdpr")) return;
    const { analytics } = JSON.parse(localStorage.getItem("cookiegdpr"));

    if (analytics) {
      trackPageView();
    }
  }, [location, trackPageView]);
}
