import axios from "axios";
import Cookies from "universal-cookie";
export async function PostAvatar(avatar, id) {
  const cookies = new Cookies();
  await axios({
    method: "POST",
    url: `${process.env.REACT_APP_API_URL}/api/users/${id}/avatar`,
    data: avatar,
    headers: {
      Authorization: `Bearer ${cookies.get("::token")}`,
      "Content-Type": "multipart/form-data",
    },
    validateStatus: () => true,
  });
}
