import axios from "axios";
import getFormatedToken from "../helpers/getFormatedToken";

export async function searchOnMap(query) {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/api/map/${query}`,
    getFormatedToken()
  );

  const { data } = response.data;
  return data;
}
