/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import "./style.css";
import { fetchAllFiles } from "../../../api/Files";
import CTAnotFound from "../ErrorsMessages/CTAnotFound";
import ItemHrTrends from "./Item";
export default function HRTrendsList({
  title = true,
  limit,
  filter = "",
  isHome,
  thematic,
  subthemes_of_maintheme,
}) {
  const [files, setFiles] = useState([]);

  async function getAllFiles() {
    const filesToSort = await fetchAllFiles([
      filter,
      "isOfficial:Library:false,HRTrends:true",
    ]);
    filesToSort.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    setFiles(filesToSort);
  }

  async function getAllFilesWhereThematic(formatedSubthemeFilter) {
    const filesToSort = await fetchAllFiles(formatedSubthemeFilter);
    console.log("before", filesToSort);
    filesToSort.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    console.log("after", filesToSort);
    setFiles(filesToSort);
  }

  useEffect(() => {
    if ((filter && filter.length) || isHome) {
      getAllFiles();
    } else if (thematic && thematic.id) {
      let formatedSubthemeFilter = [];
      for (const key in subthemes_of_maintheme) {
        formatedSubthemeFilter.push(`sub_theme:${subthemes_of_maintheme[key]}`);
      }
      formatedSubthemeFilter.push(filter);
      formatedSubthemeFilter.push("isOfficial:Library:false,HRTrends:true");
      getAllFilesWhereThematic(formatedSubthemeFilter);
    }
  }, [filter, thematic]);

  return (
    <div className="right-container">
      {isHome ? (
        <h2>
          <i className="icon-document" />
          HR Trends
        </h2>
      ) : (
        title && <p className="big-title">HR Trends</p>
      )}
      <div className="HRTrends_container" style={{ marginTop: "0px" }}>
        {files && files.length ? (
          //    .slice(0).reverse()
          files.map((file, index) => {
            if (limit) {
              return index < limit && <ItemHrTrends file={file} />;
            } else {
              return <ItemHrTrends file={file} />;
            }
          })
        ) : isHome ? (
          ""
        ) : (
          <CTAnotFound
            color={thematic.color}
            thematic_id={thematic.id}
            contentType={6}
          />
        )}
      </div>
    </div>
  );
}
