import CenterLayout from "../layouts/CenterLayout";

export default function PolitiqueDonneesPersonnelles() {
  return (
    <CenterLayout>
      <div class="subtheme__content main">
        <h1>POLITIQUE DONNÉES PERSONNELLES COLLABORATEURS BOUYGUES SA</h1>
        <p>17/02/2022</p>
        <p>Chers collaborateurs de la communauté RH,</p>
        <p>&nbsp;</p>
        <p>
          Nous avons le plaisir de vous présenter notre politique de données
          personnelles relative aux informations personnelles que nous sommes
          amenés à traiter dans le cadre de votre portail HR team.
        </p>
        <p>Cette politique est présentée sous forme de questions/réponses.</p>
        <p>
          Elle détaille de façon concise les données personnelles vous
          concernant en notre possession et l’utilisation que nous en faisons.
        </p>
        <p>
          Elle vous rappelle également vos droits spécifiques en matière de
          données personnelles et vous explique comment les faire valoir.
        </p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <h3>VOS QUESTIONS - VOS DROITS</h3>
        <p>&nbsp;</p>
        <p>
          1. Qui est le responsable du traitement de mes données personnelles ?
        </p>
        <p>2. Pourquoi avez-vous besoin de ces données personnelles ?</p>
        <p>
          3. Sur quelle base légale utilisez-vous mes données personnelles ?
        </p>
        <p>4. Comment avez-vous récolté mes données ?</p>
        <p>
          5. Quelles catégories de données personnelles me concernant
          récoltez-vous ?
        </p>
        <p>
          6. Utilisez-vous un prestataire (sous-traitant) pour le traitement de
          mes données ?
        </p>
        <p>
          7. Transmettez-vous mes données personnelles à d’autres personnes ?
        </p>
        <p>8. Avez-vous des données sensibles me concernant ?</p>
        <p>
          9. Mes données personnelles sont-elles transférées en dehors de
          l’Union européenne ?
        </p>
        <p>10. Où sont stockées mes données ?</p>
        <p>11. Combien de temps stockez-vous mes données ?</p>
        <p>
          12. Mes données seront-elles utilisées pour effectuer des décisions
          automatisées ou un profilage ?
        </p>
        <p>13. Quels sont mes droits concernant mes données personnelles ?</p>
        <p>
          14. Que se passera-t-il si je m’oppose au traitement de mes données
          personnelles ou si je retire mon consentement ?
        </p>
        <p>15. Comment exercer mes droits et qui dois-je contacter ?</p>
        <p>
          16. Je ne suis pas sûr(e) que votre traitement respecte mes droits, je
          voudrais plus d’informations sur mes droits, à qui puis-je
          m’adresser ?
        </p>

        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <h3>
          1. Qui est le responsable du traitement de mes données personnelles ?
        </h3>
        <p>&nbsp;</p>
        <p>Le responsable de traitement est la société :</p>
        <p>Bouygues</p>
        <p>32 avenue Hoche</p>
        <p>75008 Paris, France</p>
        <p>RCS Paris 572 015 246</p>
        <p>&nbsp;</p>
        <p>
          La Direction Centrale développement RH innovation et responsabilité
          sociale Bouygues SA est à votre écoute pour toute question concernant
          ce document.
        </p>
        <p>&nbsp;</p>
        <p>
          Adresse mail :<a href="mailto:rgpd@bouygues.com">rgpd@bouygues.com</a>
        </p>
        <p>&nbsp;</p>
        <h3>2. Pourquoi avez-vous besoin de ces données personnelles ?</h3>
        <p>&nbsp;</p>
        <p>
          Nous utilisons vos données personnelles pour vous donner un accès
          personnalisé au site HR team.
        </p>

        <p>
          Nous utilisons également vos données personnelles pour la gestion de
          votre compte utilisateur et pour vous proposer les services de la
          plateforme (tchat, rédaction d’articles, etc.).
        </p>

        <p>
          Ces données nous permettent également de communiquer avec
          vous concernant les newsletters et lorsque vous souhaitez exercer vos
          droits.
        </p>
        <p>&nbsp;</p>
        <h3>
          3. Sur quelle base légale utilisez-vous mes données personnelles ?
        </h3>
        <p>&nbsp;</p>
        <p>Nous utilisons vos données :</p>
        <p>
          - car elles nous sont indispensables pour vous fournir un accès au
          portail HT team avec des codes d’identification ;
        </p>
        <p>- pour établir des statistiques de connexion au site.</p>
        <p>&nbsp;</p>
        <h3>4. Comment avez-vous récolté mes données ?</h3>
        <p>&nbsp;</p>
        <p>
          Les données personnelles vous concernant que nous traitons sont celles
          liées à votre compte informatique et votre compte utilisateur sur le
          site.
        </p>
        <p>&nbsp;</p>
        <h3>
          5. Quelles catégories de données personnelles me concernant
          récoltez-vous ?
        </h3>
        <p>&nbsp;</p>
        <p>Nous traitons les catégories de données personnelles suivantes :</p>

        <p>• nom, prénom,</p>
        <p>• adresse email professionnelle,</p>
        <p>• photographie,</p>
        <p>• fonction renseignée,</p>
        <p>• entité d’appartenance renseignée,</p>
        <p>• lien individuel dans l’annuaire RH.</p>
        <p>&nbsp;</p>
        <h3>
          6. Utilisez-vous un prestataire (sous-traitant) pour le traitement de
          mes données ?
        </h3>
        <p>&nbsp;</p>
        <p>
          Oui, la sociétés RJS a accès à vos données personnelles dans le cadre
          de ses missions de gestion du site HR team.
        </p>
        <p>
          La société Ask for the moon a accès à vos données de l’annuaire RH en
          tant qu’éditeur de la solution.
        </p>
        <p>&nbsp;</p>
        <h3>
          7. Transmettez-vous mes données personnelles à d’autres personnes ?
        </h3>
        <p>&nbsp;</p>
        <p>
          Non. Vos données personnelles ne sont ni partagées, ni revendues à qui
          que ce soit.
        </p>
        <p>&nbsp;</p>
        <h3>8. Avez-vous des données sensibles* me concernant ?</h3>
        <p>&nbsp;</p>
        <p>
          Non, nous ne collectons pas de données personnelles sensibles vous
          concernant.
        </p>
        <p>&nbsp;</p>
        <h3>
          9. Mes données personnelles sont-elles transférées en dehors de
          l’Union européenne ?
        </h3>
        <p>&nbsp;</p>
        <p>
          Non, nous ne transférons pas vos données personnelles en dehors de
          l’Union européenne.
        </p>
        <p>&nbsp;</p>
        <h3>10. Où sont stockées mes données ?</h3>
        <p>&nbsp;</p>
        <p>Vos données personnelles sont stockées dans l’Union européenne**.</p>
        <p>&nbsp;</p>
        <h3>11. Combien de temps stockez-vous mes données ?</h3>
        <p>&nbsp;</p>
        <p>
          Nous conservons vos données pendant les durées détaillées
          ci-après :&nbsp;
        </p>
        <p>
          • votre utilisation du service jusqu’à la suppression de votre
          compte ;&nbsp;
        </p>
        <p>&nbsp;</p>
        <h3>
          12. Mes données seront-elles utilisées pour effectuer des décisions
          automatisées ou un profilage*** ?
        </h3>
        <p>&nbsp;</p>
        <p>Non.</p>
        <p>&nbsp;</p>
        <h3>13. Quels sont mes droits concernant mes données personnelles ?</h3>
        <p>&nbsp;</p>
        <p>
          En tant que personne physique, vous disposez des droits suivants
          :&nbsp;
        </p>

        <strong>1. Droit d’accès à vos données</strong>
        <p>C’est-à-dire que vous pouvez nous demander directement :</p>
        <p>
          • Si nous détenons des informations personnelles vous concernant ; et
        </p>
        <p>
          • Que l’on vous communique l’intégralité de ces données personnelles.
        </p>
        <p>
          Ce droit d’accès vous permet de contrôler l'exactitude des données et,
          au besoin, de nous demander de les rectifier ou de les effacer, si
          elles sont inexactes ou périmées.&nbsp;
        </p>
        <p>&nbsp;</p>
        <strong>2. Droit de modification de vos données&nbsp;</strong>
        <p>
          Vous pouvez nous demander de rectifier des informations inexactes vous
          concernant. Ce droit vous permet d’éviter que nous ne diffusions ou
          traitions des informations incorrectes vous concernant.
        </p>

        <p>&nbsp;</p>
        <strong>3. Droit de suppression de vos données&nbsp;</strong>
        <p>&nbsp;</p>
        <p>4. Droit de s’opposer au traitement de vos données</p>
        <p>
          Vous pouvez vous opposer, pour des motifs légitimes, à ce que les
          données vous concernant soient diffusées, transmises ou conservées.
        </p>

        <p>&nbsp;</p>
        <strong>5. Droit de limiter le traitement de vos données&nbsp;</strong>
        <p>
          Ce droit vous permet de demander que seules les données nécessaires au
          responsable de traitement soient traitées.&nbsp;
        </p>
        <p>&nbsp;</p>
        <strong>6. Droit à la portabilité de vos données&nbsp;</strong>
        <p>
          Vous avez la possibilité de récupérer une partie de vos données dans
          un format ouvert et lisible par machine (format électronique). Vos
          données pourront ainsi être stockées ou transmises facilement d’un
          système d’information à un autre, en vue de leur réutilisation.
        </p>
        <p>&nbsp;</p>
        <strong>
          7. Droit de ne pas faire l’objet d’une décision automatisée&nbsp;
        </strong>
        <p>
          Le droit de ne pas faire l'objet d'une décision fondée exclusivement
          sur un traitement automatisé comprend le profilage et généralement
          tout traitement produisant des effets juridiques vous concernant ou
          vous affectant de manière significative de façon similaire.
        </p>
        <p>
          Votre droit ne s’applique cependant pas lorsque la décision prise à la
          suite de la décision automatisée :
        </p>
        <p>
          • Est nécessaire à la conclusion ou à l'exécution d'un contrat entre
          vous et un responsable de traitement,&nbsp;
        </p>
        <p>
          • Est autorisée par le droit de l'Union européenne ou le droit
          français et qui prévoit également des mesures appropriées pour la
          sauvegarde de vos droits et libertés et de vos intérêts
          légitimes.&nbsp;
        </p>
        <p>• Est fondée sur votre consentement explicite.&nbsp;</p>
        <p>&nbsp;</p>
        <strong>
          8. Droit de retirer à tout moment votre consentement au traitement de
          vos données (si vos données sont traitées sur la base de votre
          consentement)&nbsp;
        </strong>
        <p>&nbsp;</p>
        <strong>9. Droits post-mortem</strong>
        <p>
          Vous avez la possibilité de définir des directives relatives au sort
          de vos données à caractère personnel après votre décès.&nbsp;
        </p>
        <p>&nbsp;</p>
        <p>
          NB : Ces droits ne sont pas absolus. Vous pouvez les exercer dans le
          cadre légal prévu et dans les limites de ces droits. Dans certains cas
          nous ne pourrons pas répondre favorablement à votre demande
          (obligation légale, respect de nos engagements envers vous, ...). Si
          c’est le cas, nous vous communiquerons la ou les raisons de ce
          refus.&nbsp;
        </p>
        <p>&nbsp;</p>
        <p>
          Pour plus d’informations sur vos droits, consultez le site de la CNIL
          :
          <a href="https://www.cnil.fr/fr/comprendre-vos-droits">
            https://www.cnil.fr/fr/comprendre-vos-droits
          </a>
        </p>

        <p>&nbsp;</p>
        <h3>
          14. Que se passera-t-il si je m’oppose au traitement de mes données
          personnelles ou si je retire mon consentement ?
        </h3>
        <p>&nbsp;</p>
        <p>
          Si vous demandez la suppression des informations vous concernant ou si
          vous vous opposez au traitement nous ne pourrons pas vous donner accès
          au site HR team et aux newsletters associées.&nbsp;
        </p>
        <p>&nbsp;</p>
        <p>
          NB : Dans certains cas nous ne pourrons pas répondre favorablement à
          votre demande (obligation légale, respect de nos engagements envers
          vous, ...). Dans un tel cas, nous vous communiquerons la ou les
          raisons de ce refus.
        </p>

        <p>&nbsp;</p>
        <h3>15. Comment exercer mes droits et qui dois-je contacter ?</h3>
        <p>&nbsp;</p>
        <p>Pour exercer vos droits, merci de contacter :</p>
        <p>
          Adresse mail: <a href="mailto:rgpd@bouygues.com">rgpd@bouygues.com</a>
        </p>
        <p>&nbsp;</p>
        <p>
          Vous pouvez également joindre la Direction Juridique par courrier à
          l’adresse de son siège social :&nbsp;
        </p>
        <p>Bouygues&nbsp;</p>
        <p>A l’attention de la Direction Juridique&nbsp;</p>
        <p>32 avenue Hoche&nbsp;</p>
        <p>75008 Paris, France&nbsp;</p>
        <p>&nbsp;</p>
        <p>
          En cas de difficultés, vous pouvez aussi effectuer une réclamation
          auprès des autorités de supervision compétentes.&nbsp;
        </p>

        <p>
          En France, l’autorité compétente est la CNIL :
          https://www.cnil.fr/fr/agir
        </p>
        <p>&nbsp;</p>
        <h3>
          16. Je ne suis pas sûr(e) que votre traitement respecte mes droits, je
          voudrais plus d’informations sur mes droits, à qui puis-je
          m’adresser ?
        </h3>
        <p>&nbsp;</p>
        <p>
          Pour la France, vous pouvez vous adresser à la CNIL :
          https://www.cnil.fr/ (informations générales).
        </p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>------------------------</p>
        <i>
          <p>
            * Les <strong>données sensibles ou particulières</strong> sont :
          </p>
          <ul>
            <li>
              Les données personnelles qui relèvent de l’origine raciale ou
              ethnique, les opinions politiques, les convictions religieuses ou
              philosophiques ou l’appartenance syndicale, les données
              personnelles génétiques, les données biométriques aux fins
              d’identifier une personne physique de manière unique, les données
              personnelles concernant la santé ou les données personnelles
              concernant la vie sexuelle ou l’orientation sexuelle d’une
              personne physique ; et
            </li>
            <li>
              Les données à caractère personnel relatives aux condamnations
              pénales et aux infractions.
            </li>
          </ul>
          <p>
            ** Au 07.03.2018, les <strong>pays de l’Union européenne</strong>{" "}
            sont les suivants : Allemagne, Autriche, Belgique, Bulgarie, Chypre,
            Croatie, Danemark, Espagne, Estonie, Finlande, France, Grèce,
            Hongrie, Irlande, Italie, Lettonie, Lituanie, Luxembourg, Malte,
            Pologne, Portugal, Pays-Bas, République tchèque, Roumanie,
            Royaume-Uni, Slovaquie, Slovénie, Suède.
          </p>
          <p>
            *** Le <strong>profilage</strong> concerne : toute forme de
            traitement automatisé de données à caractère personnel consistant à
            utiliser ces données à caractère personnel pour évaluer certains
            aspects personnels relatifs à une personne physique, notamment pour
            analyser ou prédire des éléments concernant le rendement au travail,
            la situation économique, la santé, les préférences personnelles, les
            intérêts, la fiabilité, le comportement, la localisation ou les
            déplacements de cette personne physique.
          </p>
        </i>
        <p>------------------------</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>
          <i>
            Politique Données Personnelles HR Team – Direction Juridique –
            17/02/2022 – INTERNE
          </i>
        </p>
      </div>
    </CenterLayout>
  );
}
