import axios from "axios";
import filtersCompose from "../helpers/FiltersCompose";
import getFormatedToken from "../helpers/getFormatedToken";

export async function fetchAllLinks(filters) {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/links${filtersCompose(filters)}`,
      getFormatedToken()
    );

    const { data } = response.data;
    return data.links || [];
  } catch (error) {
    // TODO: Handle error properly
    // console.log(error);
  }
}

export async function createNewLinks(
  title,
  image,
  description,
  url,
  branchId,
  mainThemeId,
  subThemeId
) {
  try {
    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("url", url);
    if (branchId && branchId.length > 0) {
      formData.append("branchId", branchId);
    }
    if (mainThemeId && mainThemeId.length > 0) {
      formData.append("mainThemeId", mainThemeId);
    }
    if (subThemeId && subThemeId.length > 0) {
      formData.append("subThemeId", subThemeId);
    }
    formData.append("image", image);
    await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/api/links`,
      data: formData,
      ...getFormatedToken(true),
    });
  } catch (error) {
    return error;
  }
  if (!title) {
    return;
  }
}

export async function updateOneLinks(
  id,
  title,
  image,
  description,
  url,
  branchId,
  mainThemeId,
  subThemeId
) {
  try {
    if (!id) {
      return true;
    }
    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("url", url);
    if (branchId && branchId.length > 0) {
      for (const key in branchId) {
        formData.append("branchId[]", branchId[key]);
      }
    }
    if (mainThemeId && mainThemeId.length > 0) {
      for (const key in mainThemeId) {
        formData.append("mainThemeId[]", mainThemeId[key]);
      }
    }
    if (subThemeId && subThemeId.length > 0) {
      for (const key in subThemeId) {
        formData.append("subThemeId[]", subThemeId[key]);
      }
    }
    if (image) {
      formData.append("image", image);
    }
    await axios({
      method: "PUT",
      url: `${process.env.REACT_APP_API_URL}/api/links/${id}`,
      data: formData,
      ...getFormatedToken(true),
    });
  } catch (error) {
    // TODO: Handle error properly
    // console.log(error);
    return error;
  }
}

export async function deleteLinks(id) {
  if (!id) {
    return;
  }

  await axios({
    method: "DELETE",
    url: `${process.env.REACT_APP_API_URL}/api/links/${id}`,
    ...getFormatedToken(true),
  });
}

export async function fetchOneLinks(id) {
  if (!id) {
    return;
  }

  const response = await axios({
    method: "GET",
    url: `${process.env.REACT_APP_API_URL}/api/links/${id}`,
    ...getFormatedToken(true),
  });
  const { data } = response.data;
  return data.link;
}
