/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";

import ErrorDiv from "../../components/molecules/ErrorsMessages/errorForm";

import { Card } from "../../components/molecules/AdminPanel/index";
import { fetchAllThematics } from "../../api/MainThemesSubThemes";
import { updateUser, addUser } from "../../api/Users/addUser";

import { fetchAllSubthemes } from "../../api/SubThemes";
import { fetchAllBranches } from "../../api/Branches";

import "./Account.css";
import { Link, useParams, useHistory } from "react-router-dom";

export default function AccountForm({ onClick, userProps }) {
  const [email, setEmail] = useState(null);
  const [branchId, setBranchId] = useState();
  const [job, setJob] = useState();
  const [wingzy, setWingzy] = useState("");
  const [firstname, setFirstname] = useState();
  const [lastname, setLastname] = useState();

  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [isNewUser, setIsNewUser] = useState(true);
  const [newUserDate, setNewUserDate] = useState(null);

  const [thematics, setThematics] = useState([]);
  const [subThemes, setSubThemes] = useState([]);
  const [branches, setBranches] = useState([]);

  const [permSelec, setPermSelec] = useState(null);
  const [themPerm, setThemPerm] = useState([]);

  const [permJSelec, setPermJSelec] = useState(null);
  const [jobPerm, setJobPerm] = useState([]);

  const [subThSelec, setSubThSelec] = useState(null);
  const [subTheme, setSubTheme] = useState([]);

  const [success, setSuccess] = useState(false);
  const [errorF, setErrorF] = useState(false);

  const history = useHistory();
  const { id } = useParams();

  async function getAllThematics() {
    setThematics(await fetchAllThematics());
  }

  async function getAllSubthemes() {
    setSubThemes(await fetchAllSubthemes());
  }

  async function getAllBranches() {
    setBranches(await fetchAllBranches());
  }

  function format(arrayT, arrayS) {
    const array = [];
    arrayT.forEach((value) => {
      array.push(`MainThemes:${value.id}`);
    });
    arrayS.forEach((value) => {
      array.push(`SubThemes:${value.id}`);
    });
    return array;
  }

  useEffect(() => {
    if (userProps) {
      setFirstname(userProps.firstname);
      setLastname(userProps.lastname);
      setEmail(userProps.email);
      setBranchId(userProps.Branch && userProps.Branch.id);
      setJob(userProps.job);
      setWingzy(userProps.wingzy);

      setIsSuperAdmin(
        userProps.Role &&
        userProps.Role[0] &&
        userProps.Role[0].name === "ADMIN"
      );
      setJobPerm(
        format(userProps.AdminOfMainThemes, userProps.AdminOfSubThemes)
      );
      setThemPerm(
        format(
          userProps.GroupReferentOfMainThemes,
          userProps.GroupReferentOfSubThemes
        )
      );
      setSubTheme(
        format(userProps.ReferentOfMainThemes, userProps.ReferentOfSubThemes)
      );
    }
  }, [userProps]);

  useEffect(() => {
    getAllThematics();
    getAllSubthemes();
    getAllBranches();
  }, []);

  const sendUser = async () => {
    if (!email) {
      setErrorF("Ajoutez un Email");
      return;
    } else if (!branchId) {
      setErrorF("Veuillez ajouter un métier");
      return;
    } else if (!firstname) {
      setErrorF("Veuillez ajouter un prénom");
      return;
    } else if (!lastname) {
      setErrorF("Veuillez ajouter un nom");
      return;
    }
    if (id) {
      const res = await updateUser({
        id,
        firstname,
        lastname,
        email: email.toLowerCase(),
        job,
        wingzy,
        branchId,
        permissions: jobPerm,
        referentGroup: themPerm,
        referentMetier: subTheme,
        isSuperAdmin,
      });
      if (!res) {
        setSuccess(true);
        history.push("/account");
        return;
      }
      setErrorF("Une erreur est survenue...");
    } else {
      const res = await addUser({
        firstname,
        lastname,
        welcome: isNewUser,
        dateIn: new Date(newUserDate),
        email: email.toLowerCase(),
        job,
        wingzy,
        branchId,
        permissions: jobPerm,
        referentGroup: themPerm,
        referentMetier: subTheme,
        isSuperAdmin,
      });
      if (!res) {
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
          window.location.reload();
        }, 1500);
        return;
      } else if (res.toString().includes(400)) {
        setErrorF("Cette EMail est déjà utilisée");
        return;
      }
      setErrorF("Une erreur est survenue...");
    }
  };

  useEffect(() => {
    if (isNewUser) {
      setIsSuperAdmin(!isNewUser);
    }
  }, [isNewUser]);
  useEffect(() => {
    if (isSuperAdmin) {
      setIsNewUser(!isSuperAdmin);
    }
  }, [isSuperAdmin]);

  return (
    <div style={{ marginBottom: "30px" }}>
      <Card>
        <Link to="/account">
          <a className="back" onClick={onClick}>
            <i className="icon-short-arrow" style={{ fontSize: "9px" }} />{" "}
            Retour
          </a>
        </Link>
        <h1 className="title">Ajouter un utilisateur</h1>
        <form className="aform-account">
          <input
            type="text"
            placeholder="Prénom"
            className="aform-input"
            value={firstname && firstname}
            onChange={(e) => setFirstname(e.target.value)}
            require
          />
          <input
            type="text"
            placeholder="Nom"
            className="aform-input"
            value={lastname && lastname}
            onChange={(e) => setLastname(e.target.value)}
            require
          />
          <input
            type="email"
            placeholder="Email"
            className="aform-input"
            value={email && email}
            onChange={(e) => setEmail(e.target.value)}
            require
          />
          <select
            className="aform-input"
            style={{ backgroundColor: "white" }}
            type="text"
            placeholder="Choisir le métier*"
            onChange={(e) => setBranchId(e.target.value)}
            required
          >
            <option disabled default selected value="">
              Choisir le métier*
            </option>
            {branches.map((branch) => (
              <option value={branch.id} selected={branch.id === branchId}>
                {branch.name}
              </option>
            ))}
          </select>
          <input
            type="text"
            placeholder="Poste"
            className="aform-input"
            value={job && job}
            onChange={(e) => setJob(e.target.value)}
            require
          />
          {/* <input
            type="text"
            placeholder="https://home.wingzy.com/"
            className="aform-input"
            value={wingzy && wingzy}
            onChange={(e) => setWingzy(e.target.value)}
          /> */}
          <div style={{ width: "100%" }}>
            <label style={{ display: "flex", alignItems: "center" }}>
              <input
                type="checkbox"
                checked={isSuperAdmin}
                onChange={(e) => {
                  setIsSuperAdmin(e.target.checked);
                  if (e.target.checked === true) {
                    setJobPerm([]);
                  }
                }}
              />
              <h1 className="title">SUPER ADMIN</h1>
            </label>
            {isSuperAdmin && (
              <p style={{ fontFamily: "var(--cera-light)", fontSize: "13px" }}>
                Attention, en cochant cette case vous donnez à ce nouvel
                utilisateur tout les droits. <br /> Il pourra modifier toutes
                les informations et ajouter-supprimer d'autres utilisateurs.
              </p>
            )}
            {!userProps && (
              <div>
                <label style={{ display: "flex", alignItems: "center" }}>
                  <input
                    type="checkbox"
                    checked={isNewUser}
                    onChange={(e) => {
                      setIsNewUser(e.target.checked);
                    }}
                  />
                  <h1 className="title">WELCOME</h1>
                </label>
                {isNewUser && (
                  <label>
                    <input type="date" className="aform-input" onChange={(e) => setNewUserDate(e.target.value)} />
                    <p style={{ fontFamily: "var(--cera-light)", fontSize: "13px", paddingLeft: 10 }}>
                      Permet de choisir la date d'arrivé qui sera affiché dans Welcome
                    </p>
                  </label>
                )}
              </div>
            )}
          </div>
          {!isSuperAdmin && (
            <>
              <div
                style={{
                  borderBottom: "1px rgba(0, 0, 0, 0.15) solid",
                  width: "100%",
                  margin: "10px 0px",
                }}
              ></div>
              <div style={{ width: "100%" }}>
                <h2 className="paragraph">Permission*</h2>
                <div>
                  {jobPerm &&
                    thematics &&
                    thematics.length &&
                    subThemes &&
                    subThemes.length &&
                    jobPerm.map((step, index) =>
                      step && "MainThemes" === step.split(":")[0] ? (
                        <div key={index} className="th-p-map">
                          <img
                            style={{ width: "25px" }}
                            src={`/assets/img/icons/${thematics.find(
                              ({ id }) => id === Number(step.split(":")[1])
                            ).icon
                              }`}
                            alt={
                              thematics.find(
                                ({ id }) => id === Number(step.split(":")[1])
                              ).name
                            }
                          />
                          <p style={{ width: "200px" }}>
                            {
                              thematics.find(
                                ({ id }) => id === Number(step.split(":")[1])
                              ).name
                            }
                          </p>
                          <i
                            className="icon-trash"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              let temp = [...jobPerm];
                              temp.splice(index, 1);
                              setJobPerm(temp);
                            }}
                          />
                        </div>
                      ) : (
                        step && (
                          <div key={index} className="th-p-map">
                            <p
                              style={{
                                width: "300px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <div
                                className={`account_bullet-point bg-${subThemes.find(
                                  ({ id }) =>
                                    id === Number(step.split(":")[1])
                                ).color
                                  }`}
                                style={{ marginRight: "10px" }}
                              ></div>
                              {
                                subThemes.find(
                                  ({ id }) => id === Number(step.split(":")[1])
                                ).name
                              }
                            </p>
                            <i
                              className="icon-trash"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                let temp = [...jobPerm];
                                temp.splice(index, 1);
                                setJobPerm(temp);
                              }}
                            />
                          </div>
                        )
                      )
                    )}
                </div>
                <select
                  className="aform-input"
                  onChange={(e) => setPermJSelec(e.target.value)}
                >
                  <option value="null" disabled selected>
                    Choisir la thématique ou la sous-thématique...
                  </option>
                  <option value="null" disabled>
                    ##### thématiques #####
                  </option>
                  {thematics.map((theme, index) => (
                    <option key={index} value={`MainThemes:${theme.id}`}>
                      {theme.name}
                    </option>
                  ))}
                  <option value="null" disabled>
                    ##### sous-thématiques #####
                  </option>
                  {subThemes.map((job, index) => (
                    <option key={index} value={`SubThemes:${job.id}`}>
                      {job.name}
                    </option>
                  ))}
                </select>
                <button
                  className="btn-mode"
                  onClick={(e) => {
                    e.preventDefault();
                    if (permJSelec !== "null")
                      setJobPerm([...jobPerm, permJSelec]);
                  }}
                >
                  Ajouter
                </button>
              </div>
            </>
          )}
          <div
            style={{
              borderBottom: "1px rgba(0, 0, 0, 0.15) solid",
              width: "100%",
              margin: "10px 0px",
            }}
          ></div>
          <div style={{ width: "100%" }}>
            <h2 className="paragraph">Référent Groupe*</h2>
            <div>
              {themPerm &&
                thematics &&
                thematics.length &&
                subThemes &&
                subThemes.length &&
                themPerm.map((step, index) =>
                  step && "MainThemes" === step.split(":")[0] ? (
                    <div key={index} className="th-p-map">
                      <img
                        style={{ width: "25px" }}
                        src={`/assets/img/icons/${thematics.find(
                          ({ id }) => id === Number(step.split(":")[1])
                        ).icon
                          }`}
                        alt={
                          thematics.find(
                            ({ id }) => id === Number(step.split(":")[1])
                          ).name
                        }
                      />
                      <p style={{ width: "200px" }}>
                        {
                          thematics.find(
                            ({ id }) => id === Number(step.split(":")[1])
                          ).name
                        }
                      </p>
                      <i
                        className="icon-trash"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          let temp = [...themPerm];
                          temp.splice(index, 1);
                          setThemPerm(temp);
                        }}
                      />
                    </div>
                  ) : (
                    step && (
                      <div key={index} className="th-p-map">
                        <p
                          style={{
                            width: "300px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div
                            className={`account_bullet-point bg-${subThemes.find(
                              ({ id }) => id === Number(step.split(":")[1])
                            ).color
                              }`}
                            style={{ marginRight: "10px" }}
                          ></div>
                          {
                            subThemes.find(
                              ({ id }) => id === Number(step.split(":")[1])
                            ).name
                          }
                        </p>
                        <i
                          className="icon-trash"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            let temp = [...themPerm];
                            temp.splice(index, 1);
                            setThemPerm(temp);
                          }}
                        />
                      </div>
                    )
                  )
                )}
            </div>
            <select
              className="aform-input"
              onChange={(e) => setPermSelec(e.target.value)}
            >
              <option value="null" disabled selected>
                Choisir la thématique ou la sous-thématique (un seul Régérent
                group)
              </option>
              <option value="null" disabled>
                ##### thématiques #####
              </option>
              {thematics.map((theme, index) => (
                <option
                  key={index}
                  value={`MainThemes:${theme.id}`}
                  disabled={theme.GroupReferent}
                >
                  {theme.name}
                </option>
              ))}
              <option value="null" disabled>
                ##### sous-thématiques #####
              </option>
              {subThemes.map((job, index) => (
                <option
                  key={index}
                  value={`SubThemes:${job.id}`}
                  disabled={job.GroupReferent}
                >
                  {job.name}
                </option>
              ))}
            </select>
            <button
              className="btn-mode"
              onClick={(e) => {
                e.preventDefault();
                if (permSelec !== "null") setThemPerm([...themPerm, permSelec]);
              }}
            >
              Ajouter
            </button>
          </div>
          <div
            style={{
              borderBottom: "1px rgba(0, 0, 0, 0.15) solid",
              width: "100%",
              margin: "10px 0px",
            }}
          ></div>
          <div>
            <h2 className="paragraph">Référent Métier*</h2>
            <div>
              {subTheme &&
                thematics &&
                thematics.length &&
                subThemes &&
                subThemes.length &&
                subTheme.map((step, index) =>
                  step && "MainThemes" === step.split(":")[0] ? (
                    <div key={index} className="th-p-map">
                      <img
                        style={{ width: "25px" }}
                        src={`/assets/img/icons/${thematics.find(
                          ({ id }) => id === Number(step.split(":")[1])
                        ).icon
                          }`}
                        alt={
                          thematics.find(
                            ({ id }) => id === Number(step.split(":")[1])
                          ).name
                        }
                      />
                      <p style={{ width: "200px" }}>
                        {
                          thematics.find(
                            ({ id }) => id === Number(step.split(":")[1])
                          ).name
                        }
                      </p>
                      <i
                        className="icon-trash"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          let temp = [...subTheme];
                          temp.splice(index, 1);
                          setSubTheme(temp);
                        }}
                      />
                    </div>
                  ) : (
                    step && (
                      <div key={index} className="th-p-map">
                        <p
                          style={{
                            width: "300px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div
                            className={`account_bullet-point bg-${subThemes.find(
                              ({ id }) => id === Number(step.split(":")[1])
                            ).color
                              }`}
                            style={{ marginRight: "10px" }}
                          ></div>
                          {
                            subThemes.find(
                              ({ id }) => id === Number(step.split(":")[1])
                            ).name
                          }
                        </p>
                        <i
                          className="icon-trash"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            let temp = [...subTheme];
                            temp.splice(index, 1);
                            setSubTheme(temp);
                          }}
                        />
                      </div>
                    )
                  )
                )}
            </div>
            <select
              className="aform-input"
              onChange={(e) => setSubThSelec(e.target.value)}
            >
              <option value="null" disabled selected>
                Choisir la thématique ou la sous-thématique...
              </option>
              <option value="null" disabled>
                ##### thématiques #####
              </option>
              {thematics.map((theme, index) => (
                <option key={index} value={`MainThemes:${theme.id}`}>
                  {theme.name}
                </option>
              ))}
              <option value="null" disabled>
                ##### sous-thématiques #####
              </option>
              {subThemes.map((job, index) => (
                <option key={index} value={`SubThemes:${job.id}`}>
                  {job.name}
                </option>
              ))}
            </select>
            <button
              className="btn-mode"
              onClick={(e) => {
                e.preventDefault();
                if (subThSelec !== "null")
                  setSubTheme([...subTheme, subThSelec]);
              }}
            >
              Ajouter
            </button>
          </div>
          <div
            style={{
              borderBottom: "1px rgba(0, 0, 0, 0.15) solid",
              width: "100%",
              margin: "10px 0px",
            }}
          ></div>

          <button
            className="save-btn"
            onClick={(e) => {
              e.preventDefault();
              sendUser();
            }}
          >
            {id ? "Enregistrer" : "Inviter"}
          </button>
          {errorF && <ErrorDiv message={errorF} />}
          {success && (
            <div
              style={{
                backgroundColor: "var(--green-color)",
                color: "white",
                padding: 10,
                fontFamily: "var(--cera-bold)",
                textAlign: "center",
                marginTop: 10,
              }}
            >
              Changements enregistrés avec succès !
            </div>
          )}
        </form>
      </Card>
    </div>
  );
}
