import React from "react";
import SideBar from "../components/SideBar/SideBar";
import Footer from "../components/Footer/Footer";
import "./layout.css";
import TopBar from "../components/TopBar";

export default function DefaultLayout({ children, className, title }) {
  return (
    <React.Fragment>
      <TopBar />
      <SideBar />
      <div className="position_from_menu">
        <div className="layout-default__container">
          {title && (
            <h1
              className={`layout__title ${
                title instanceof Object && title.className
              }`}
            >
              {title instanceof Object ? title.name : title}
            </h1>
          )}
          <div
            className={
              className ? "child__container " + className : "child__container"
            }
          >
            {children}
          </div>
        </div>

        <Footer />
      </div>
    </React.Fragment>
  );
}
