import axios from "axios";
import getFormatedToken from "helpers/getFormatedToken";

export async function editEntities(userId, entityId, name, force) {
  const response = await axios.put(
    `${process.env.REACT_APP_API_URL}/api/entities/user/update`,
    {
      userId: userId,
      [entityId ? "entityId" : "name"]: entityId || name,
      force: force,
    },
    getFormatedToken()
  );

  const { data } = response.data;
  return data || response.data;
}
