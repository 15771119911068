/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";

import { Card } from "../../../components/molecules/AdminPanel/index";
import ErrorDiv from "../../../components/molecules/ErrorsMessages/errorForm";
import "./LinksForm.css";

import { createNewLinks, updateOneLinks } from "../../../api/Links";
import FilterForm from "../../../components/Filter/FilterForm";
import { Link, useHistory, useParams } from "react-router-dom";
import LoadingBar from "../../../components/molecules/Loaders/Bar";
import { FiltersContext } from "../../../contexts/filters";
import CropImage from "../../../components/molecules/CropImage";

export default function LinksForm({ onClick, linkProps }) {
  const [title, setTitle] = useState();
  const [link, setLink] = useState("");
  const [description, setDescription] = useState("");
  const [file, setFile] = useState(null);
  const [fileBlob, setFileBlob] = useState(null);
  const [filter, setFilter] = useState([]);
  const [errorField, setErrorField] = useState(false);
  const [sending, setSending] = useState(false);

  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    const zob = document.querySelector(".reset_filter_title");
    const zob2 = document.querySelector(".calendar_selection");
    zob.style = "display: none";
    zob2.style = "display: none";

    if (linkProps) {
      setTitle(linkProps.title);
      setLink(linkProps.url);
      setDescription(linkProps.description);
    }
  }, [linkProps]);

  const conversionDuTableauAvantLenvoie = () => {
    let mainThemes = [];
    let subThemes = [];
    let branches = [];

    filter.forEach((row, index) => {
      if (row.includes("main_theme")) {
        mainThemes.push(row.split(":")[1]);
      } else if (row.includes("sub_theme")) {
        subThemes.push(row.split(":")[1]);
      } else if (row.includes("branch")) {
        branches.push(row.split(":")[1]);
      }
    });
    return { mainThemes, subThemes, branches };
  };

  const createLinks = async () => {
    let filt = conversionDuTableauAvantLenvoie();
    if (!filt) {
      filt = {};
    }
    if (!title && !file) {
      setErrorField(true);
      return;
    }
    if (id) {
      const res = await updateOneLinks(
        id,
        title,
        file,
        description,
        link,
        filt.branches,
        filt.mainThemes,
        filt.subThemes
      );
      if (!res) {
        history.push("/admin/links");
        return;
      }
    } else {
      const res = await createNewLinks(
        title,
        file,
        description,
        link,
        filt.branches,
        filt.mainThemes,
        filt.subThemes
      );
      if (!res) {
        onClick();
      }
    }
  };

  return (
    <Card>
      <Link to="/admin/links">
        <a className="back" onClick={onClick}>
          <i className="icon-short-arrow" style={{ fontSize: "9px" }} /> Retour
        </a>
      </Link>
      <h1 className="title">Description du document</h1>
      <form className="admin-form">
        <div className="admin-input-box">
          <input
            className="admin-input"
            type="text"
            placeholder="Titre du document*"
            value={title && title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
          <input
            className="admin-input"
            type="text"
            placeholder="Lien du site"
            value={link && link}
            onChange={(e) => setLink(e.target.value)}
            required
          />
          <textarea
            className="admin-input"
            placeholder="Description..."
            value={description && description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
          {errorField && <ErrorDiv message="Veuillez ajouter un titre" />}
        </div>
        <div>
          <label
            className="upload-style"
            htmlFor="upload-file"
            style={{
              backgroundImage: `linear-gradient(0deg, #161b4b2b 0%, #161b4b2b 100%), url("${fileBlob ? fileBlob : linkProps ? linkProps.image : ""
                }")`,
            }}
          >
            <i className="icon-cloud" style={{ fontSize: "2rem" }} />
            <p className="btn btn-small bg-main">
              {file || (linkProps && linkProps.image.length)
                ? "Changer l'image"
                : "Sélectionner une image"}
            </p>
          </label>
          <input
            id="upload-file"
            type="file"
            onChange={(e) => setFile(e.target.files[0])}
            name="file"
          />
          {file ? (
            <CropImage
              setImage={setFile}
              setImageBlob={setFileBlob}
              src={file}
              ratio={16 / 7}
            />
          ) : (
            ""
          )}
          <br />
          <p className="text-center default-p">
            Recommandé: 200px x 150px, 5MO (max)
          </p>
          <br />
        </div>
      </form>
      <div
        style={{
          borderBottom: "1px rgba(0, 0, 0, 0.15) solid",
          marginBottom: "30px",
        }}
      ></div>
      <div className="admin-fitler-container">
        <div className="admin-filter-box">
          <FiltersContext.Provider value={linkProps}>
            <FilterForm
              filtersCallback={setFilter}
              category={false}
              edition={true}
            />
          </FiltersContext.Provider>
        </div>
        {sending ? (
          <button
            className="save-btn"
            style={{ backgroundColor: "#66666b" }}
            disabled
          >
            Enregistrer
          </button>
        ) : (
          <button
            className="save-btn"
            onClick={(e) => {
              e.preventDefault();
              createLinks();
              setSending(true);
              setTimeout(() => {
                setSending(false);
                setErrorField(false);
              }, 2000);
            }}
          >
            Enregistrer
          </button>
        )}
        {sending && !errorField && <LoadingBar done={false} />}
      </div>
    </Card>
  );
}
