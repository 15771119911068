import React from "react";
import Item from "./Item";
import NotFound from "../molecules/ErrorsMessages/NotFound";
import "./welcome.css";
import moment from "moment";

const monthNames = [
  "Janvier",
  "Février",
  "Mars",
  "Avril",
  "Mai",
  "Juin",
  "Juillet",
  "Août",
  "Septembre",
  "Octobre",
  "Novembre",
  "Decembre",
];

const Welcome = ({ items, deleteWelcome }) => {
  let nowMonth = moment().format("MM");
  const getMonthName = (month) => {
    return monthNames[month];
  };

  return (
    <div className="welcome__newcomers-container">
      {items && Object.keys(items).length < 1 && <NotFound element="welcome" />}
      <h2 style={{ marginBottom: "10px" }} className="welcome__first_title">
        Bienvenue dans la communauté RH
      </h2>

      {Object.keys(items)
        .reverse()
        .map((year) =>
          Object.keys(items[year])
            .reverse()
            .map((key) => (
              <div>
                {Number(key) + 1 !== Number(nowMonth) ? (
                  <p className="welcome__second_title">{getMonthName(key)}</p>
                ) : (
                  ""
                )}
                <div>
                  {Object.values(items[year][key]).map((item) => (
                    <Item
                      item={item}
                      key="welcome"
                      deleteWelcome={deleteWelcome}
                    />
                  ))}
                </div>
              </div>
            ))
        )}
    </div>
  );
};
export default Welcome;
