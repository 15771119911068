import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import moment from "moment";
import "./style.css";

import { fetchMessages } from "../../../api/Messages";
import FormatChatMessage from "helpers/FormatChatMessage";

export default function LastMessage() {
  const [messages, setMessages] = useState([]);
  const [seeMore, setSeeMore] = useState(false);
  const [messageContent, setMessageContent] = useState("");

  const getMessages = async () => {
    const messagesArray = await fetchMessages();
    setMessages(messagesArray);
  };

  useEffect(() => {
    getMessages();
  }, []);
  useEffect(() => {
    if (messages && messages[0]) {
      setMessageContent(messages[0].content);
    }
  }, [messages]);

  return (
    <div className="right-container">
      <h2>
        <i className="icon-chat"></i> Tchat
      </h2>
      <div
        className="all-thematics__list-container last-message"
        style={{ marginBottom: "10px" }}
      >
        <p className="right-p1">Dernier post :</p>
        <div className="right-flex">
          <div className="right-left">
            <img
              className="right__image"
              src={
                messages &&
                messages[0] &&
                messages[0].author &&
                messages[0].author.avatarPath
              }
              alt={"avatar"}
            />
          </div>
          <div className="right-right">
            {messages && messages[0] && (
              <>
                <p className="right-p2">
                  {messages[0].author.firstname} {messages[0].author.lastname}
                  <i className="light">
                    - {moment(messages[0].createdAt).fromNow()}
                  </i>
                </p>
                {!seeMore ? (
                  <FormatChatMessage
                    text={messageContent}
                    className="right-p3 fold"
                  />
                ) : (
                  <FormatChatMessage
                    text={messageContent}
                    className="right-p3"
                  />
                )}

                <div className="d-flex justify-content-between">
                  <p
                    className="right-p4"
                    onClick={() => {
                      setSeeMore(!seeMore);
                    }}
                  >
                    {seeMore ? "Réduire" : "Voir plus"}
                  </p>
                  <Link to="/feed">
                    <p className="right-p4">Rejoindre</p>
                  </Link>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="alignright">
        <Link to="/feed" className="troispetitspoints">
          <span>.</span>
          <span>.</span>
          <span>.</span>
        </Link>
      </div>
    </div>
  );
}
