import ButtonsAction from "./ButtonsAction";

export default function ItemList({
  item = {},
  onEdit = () => {},
  onDelete = () => {},
}) {
  return (
    <tr className="phototheque-page__photos__list__item">
      <td>
        <div className="phototheque-page__photos__list__item__avatar">
          <img
            src={
              process.env.REACT_APP_API_URL +
              "/api/phototheque/" +
              item.customId
            }
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "/assets/img/welcome.jpeg";
            }}
            alt=""
          />
          <div>
            <h1>
              {item.firstname} {item.lastname}
            </h1>
          </div>
        </div>
      </td>
      <td>
        <div>{item.Branch.name}</div>
      </td>
      <td>ID: {item.customId}</td>
      <td style={{ overflow: "initial" }}>
        <ButtonsAction item={item} onEdit={onEdit} onDelete={onDelete} />
      </td>
    </tr>
  );
}
