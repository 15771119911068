import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { fetchAllThemes } from "../../../../api/Thematics/Themes";
import { ThemeIcon } from "../../IconTheme";
import "./style.css";

export default function ThematicsList() {
  const [themesInfo, setThemesInfo] = useState([]);

  async function getAllThemes() {
    setThemesInfo(await fetchAllThemes());
  }
  const convertNameToUrl = (name) => {
    const nameFormated = name
      .replace(/ /g, "_")
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
    return `/thematics/${nameFormated}`;
  };

  useEffect(() => {
    getAllThemes();
  }, []);

  return (
    <div className="right-container">
      <h2>
        <i className="icon-thematics"></i> Explore
      </h2>
      <div className="all-thematics__list-container">
        <ul>
          {themesInfo && themesInfo.length ? (
            themesInfo
              .sort((a, b) => (a.id > b.id ? 1 : -1))
              .map((theme, index) => (
                <Link key={index} to={convertNameToUrl(theme.name)}>
                  <li className={`text-${theme.color}-hover`}>
                    <ThemeIcon theme={theme} />
                    {theme.name}
                  </li>
                </Link>
              ))
          ) : (
            <li>Chargement...</li>
          )}
        </ul>
      </div>
    </div>
  );
}
