import { Link } from "react-router-dom";

import "./style.css";
import searchNearestDate from "../../../../helpers/searchNearestDate";
export default function EventCard({
  data: { id, EventSession, title, hashtags },
}) {
  return (
    <Link to={`/events/${id}`} className="text-deco-none">
      <div className="card-event__container">
        {EventSession ? searchNearestDate(EventSession) : ""}
        <div className="card-event__content">
          <div>
            <h2>{title}</h2>
            <p>#{hashtags.map((hashtag, index) => `${hashtag} `)}</p>
          </div>
        </div>
      </div>
    </Link>
  );
}
