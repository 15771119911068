export default function CloseIcon() {
  return (
    <svg
      id="close_icon"
      xmlns="http://www.w3.org/2000/svg"
      width="14.701"
      height="14.7"
      viewBox="0 0 14.701 14.7"
    >
      <g transform="translate(-8701.64 -12385.056)">
        <path
          d="M8702.346,12386.16l13.087,13.086"
          transform="translate(0.001 -0.197)"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
        />
        <path
          d="M8702.346,12399.049l13.287-13.286"
          transform="translate(0.001)"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
        />
      </g>
    </svg>
  );
}
