export default function EditIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8.553"
      height="13.049"
      viewBox="0 0 8.553 13.049"
    >
      <path
        d="M115.978,101.43l.235-.359a2.8,2.8,0,0,0,0-2.81,1.021,1.021,0,0,0-1.839,0l-.256.391a.353.353,0,0,0-.367.187L109,106.1c-.009.014-.018.03-.027.045a.8.8,0,0,0-.075.2l-.822,3.5a.928.928,0,0,0,.1.692.336.336,0,0,0,.453.155l2.294-1.257a.464.464,0,0,0,.16-.156l4.4-6.728a1.872,1.872,0,0,1,0,1.873l-1.778,2.716a2.6,2.6,0,0,0,.061,2.717.339.339,0,0,0,.613,0,.927.927,0,0,0,0-.937l-.061-.094a.743.743,0,0,1,0-.75l1.778-2.716a3.738,3.738,0,0,0,0-3.747Zm-.684-2.425a.374.374,0,0,1,.307.192.94.94,0,0,1,0,.937l-.194.3-.613-.937.194-.3a.374.374,0,0,1,.306-.192Zm-6.091,9.969.282-1.2.5.77Zm1.576-1.1-.858-1.311,4.138-6.323.858,1.311Z"
        transform="translate(-108.051 -97.685)"
        fill="currentColor"
      />
    </svg>
  );
}
