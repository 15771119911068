import axios from "axios";
import getFormatedToken from "../../helpers/getFormatedToken";

export async function fetchOneArticle(id) {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/news/${id}`,
      getFormatedToken()
    );
    const { news } = response.data;
    return news || [];
  } catch (error) {
    // TODO: Handle error properly
    // console.log(error);
  }
}
