import React, { useState, useEffect } from "react";
import { fetchOneTheme } from "../../../api/Thematics/Theme";
import { ThemeIcon } from "../../molecules/IconTheme";
import "./style.css";
export default function Item({ themeId, isPreview }) {
  const [thematic, setThematic] = useState(null);

  async function getOneThematic(themeId) {
    setThematic(await fetchOneTheme(themeId));
  }

  useEffect(() => {
    getOneThematic(themeId);
  }, [themeId]);

  return (
    <>
      {thematic ? (
        <div className="themactics__subscribed-list_item">
          <div>
            <ThemeIcon theme={thematic} />
            <p>{thematic.name}</p>
          </div>
          {isPreview ? (
            ""
          ) : (
            <button
              id={`$un_subscribe-${themeId}`}
              className="btn btn-fill bg-main-hover text-main"
            >
              Ne plus suivre
            </button>
          )}
        </div>
      ) : (
        ""
      )}
    </>
  );
}
