import { useEffect, useState } from "react";
import CloseCircleIcon from "components/atoms/icons/close-circle";
import { searchOnExpertises } from "api/Expertises/searchExpertises";

export default function SearchMissions({
  setMissions = () => {},
  missions = [],
}) {
  const [list, setList] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);

  // if user click to select an item
  const handleSelect = (item) => {
    console.log("item", item);
    setTimeout(() => {
      setSearch("");
      setMissions([...missions, item]);
    }, 200);
  };

  const getSearch = async (value) => {
    if (value.length < 2) return setList([]);
    setLoading(true);
    const response = await searchOnExpertises(value.toLowerCase());
    setList(response);
    setLoading(false);
  };

  // if user click to remove an item
  const handleRemove = (value) => {
    setMissions(missions.filter((item) => item !== value));
  };

  useEffect(() => {
    const timeOutId = setTimeout(() => getSearch(search), 500);
    return () => clearTimeout(timeOutId);
  }, [search]);

  return (
    <div className="annuaire__filter-search">
      <input
        name="missions"
        placeholder="Missions et expertises"
        onChange={(e) => setSearch(e.target.value)}
        value={search}
        className="annuaire__filter-search-input"
      />
      {/* list of results */}
      <ul className="annuaire__filter-search-list" id="missions_list">
        {!loading ? (
          list.map((item, index) => (
            <li key={index} onClick={() => handleSelect(item)}>
              {item.name}
            </li>
          ))
        ) : (
          <li>Chargement...</li>
        )}
      </ul>

      {/* selected items */}
      <div className="annuaire__filter-search-selected">
        {missions.map((item, index) => {
          return (
            <div key={index} style={{ backgroundColor: "#21297C" }}>
              <button onClick={() => handleRemove(item)}>
                <CloseCircleIcon />
              </button>
              <span>{item.name}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
}
