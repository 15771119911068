/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback, useRef } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import DefaultModal from "../Modals/Default";

export default function CropImage({ src, setImage, setImageBlob, ratio }) {
  // eslint-disable-next-line no-unused-vars
  const [defaultImage, setDefaultImgae] = useState(null);
  const [openModal, setOpenModal] = useState(true);
  const [upImg, setUpImg] = useState();
  const [fileType, setFileType] = useState(null);
  const [lastModified, setLastModified] = useState(null);
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState({ unit: "%", width: 30, aspect: ratio });
  const [completedCrop, setCompletedCrop] = useState(null);

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    if (src.name && !src.name.includes("resized-hrteam")) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setUpImg(reader.result));
      reader.readAsDataURL(src);
      setFileType(src.type);
      setLastModified(src.lastModified);
      setDefaultImgae(src);
      setOpenModal(true);
    }
  }, [src]);

  useEffect(() => {
    if (!upImg) {
      setOpenModal(false);
    } else {
      setOpenModal(true);
      setCrop({ unit: "%", width: 30, aspect: ratio });
    }
  }, [upImg]);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    setImage(blobToFile(toBlob(canvas), Date.now()));
    setImageBlob(canvas.toDataURL(fileType));
  }, [completedCrop]);

  // fonction qui convertis un canvas src en blob
  function toBlob(canvas) {
    const dataURL = canvas.toDataURL(fileType);
    const byteString = atob(dataURL.split(",")[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const bb = new Blob([ab], { type: fileType });

    return bb;
  }

  // fonction qui convertis un blob en file javaScript
  function blobToFile(blob, fileName) {
    const file = new File(
      [blob],
      `${fileName}_resized-hrteam.${blob.type.split("/")[1]}`,
      {
        type: blob.type,
        lastModified: lastModified,
      }
    );
    return file;
  }

  return (
    <DefaultModal
      isOpen={openModal}
      setIsOpen={setOpenModal}
      title="Redimensionner l'image"
    >
      <ReactCrop
        src={upImg}
        onImageLoaded={onLoad}
        crop={crop}
        onChange={(c) => setCrop(c)}
        onComplete={(c) => setCompletedCrop(c)}
      />
      <div>
        <canvas
          ref={previewCanvasRef}
          style={{
            width: "0px",
          }}
        />
      </div>
      <button
        class="btn btn-small bg-main"
        style={{ marginTop: "20px" }}
        onClick={(e) => {
          e.preventDefault();
          setOpenModal(false);
        }}
      >
        Valider
      </button>
    </DefaultModal>
  );
}
