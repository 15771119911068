import { Link } from "react-router-dom";
import "./style.css";
import { useParams } from "react-router-dom";

export default function Content({ content, color, html }) {
  const { theme } = useParams();

  const convertNameToURL = (name) => {
    const nameFormated = name
      .replace(/ /g, "_")
      .replace("/", "&&")
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
    return nameFormated;
  };

  const randomPosition = () => {
    return Math.floor(Math.random() * 3) + 1;
  };
  return (
    <>
      {html ? (
        <div
          className={"subtheme__content " + color}
          dangerouslySetInnerHTML={html.html && { __html: html.html }}
        />
      ) : (
        <div className="themactics__content">
          {content && content.length
            ? content
                .sort((a, b) => (a.position > b.position ? 1 : -1))
                .map((item, index) => (
                  <div key={index} className="themactics__content-item">
                    <div className="themactics__content-texte">
                      <span>{item.baseline}</span>
                      <h3 className={`text-${color}`}>{item.name}</h3>
                      <p>{item.description}</p>

                      <Link
                        to={`${theme}/${convertNameToURL(item.name)}`}
                        className={`btn bg-${color}`}
                      >
                        en savoir plus
                      </Link>
                    </div>
                    <div className="themactics__content-image">
                      <div
                        className={`themactics__content-image_decoration ${randomPosition()}`}
                      >
                        <div className={`bg-${color}`}></div>
                        <div className={`bg-${color}`}></div>
                      </div>
                      <img
                        src={`${
                          item && item.thumbnailPath
                            ? item.thumbnailPath
                            : item.bannerImgPath &&
                              item.bannerImgPath[0] === "/"
                            ? item.bannerImgPath
                            : "/" + item.bannerImgPath
                        }`}
                        alt={item.name || "image"}
                      />
                    </div>
                  </div>
                ))
            : ""}
        </div>
      )}
    </>
  );
}
