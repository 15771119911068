import axios from "axios";
import filtersCompose from "../../helpers/FiltersCompose";
import getFormatedToken from "../../helpers/getFormatedToken";
import moment from "moment";

export async function fetchAllEvents(filters) {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/events${filtersCompose(filters)}`,
      getFormatedToken()
    );

    const { data } = response.data;

    return data.events;
  } catch (error) {
    // TODO: Handle error properly
    // console.log(error);
  }
}

export async function createNewEvent(
  title,
  text,
  hashtag,
  isFirst,
  branchId,
  mainThemeId,
  subThemeId,
  file,
  banner,
  sessions,
  content,
  published,
  link
) {
  try {
    let eventData = {};
    eventData["title"] = title;
    eventData["text"] = text;
    if (hashtag) {
      eventData["hashtags"] = hashtag;
      // if (eventData.hashtags[0] == "") {
      //   eventData.hashtags.splice(0, 1);
      // }
    }

    eventData["isFirst"] = isFirst;
    if (branchId && branchId.length > 0) {
      eventData["branchId"] = branchId;
    }
    if (mainThemeId && mainThemeId.length > 0) {
      eventData["mainThemeId"] = mainThemeId;
    }
    if (subThemeId && subThemeId.length > 0) {
      eventData["subThemeId"] = subThemeId;
    }
    if (subThemeId && content) {
      eventData["Content"] = {
        html: content,
        lang: "FR",
        state: published ? "PUBLISHED" : "DRAFT",
      };
    }

    if (link && link.length > 0) {
      eventData["link"] = link;
    }

    const response = await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/api/events`,
      data: JSON.stringify(eventData),
      ...getFormatedToken(false, true),
    });

    const eventId = response.data.data.event.id;

    if (eventId && file) {
      const formData = new FormData();
      formData.append("logo", file);
      await axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/api/events/${eventId}/logo`,
        data: formData,
        ...getFormatedToken(true),
      });
    }

    if (eventId && banner) {
      const formData = new FormData();
      formData.append("banner", banner);
      await axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/api/events/${eventId}/banner`,
        data: formData,
        ...getFormatedToken(true),
      });
    }

    if (eventId && sessions) {
      sessions.map((session) =>
        axios({
          method: "POST",
          url: `${process.env.REACT_APP_API_URL}/api/events/${eventId}/sessions `,
          data: {
            startDate: moment(
              session.dateStart + " " + session.timeStart
            ).toISOString(),
            endDate: moment(
              session.dateEnd + " " + session.timeEnd
            ).toISOString(),
          },
          ...getFormatedToken(false, true),
        })
      );
    }
  } catch (error) {
    return error;
  }
}

export async function deleteEvent(id) {
  if (!id) {
    return;
  }

  await axios({
    method: "DELETE",
    url: `${process.env.REACT_APP_API_URL}/api/events/${id}`,
    ...getFormatedToken(true),
  });
}

export async function fetchOneEvent(id) {
  if (!id) {
    return;
  }

  const response = await axios({
    method: "GET",
    url: `${process.env.REACT_APP_API_URL}/api/events/${id}`,
    ...getFormatedToken(true),
  });
  const { data } = response.data;
  return data.event;
}

export async function updateOneEvent(
  id,
  title,
  text,
  hashtag,
  isFirst,
  branchId,
  mainThemeId,
  subThemeId,
  file,
  banner,
  sessions,
  content,
  published,
  link
) {
  try {
    let eventData = {};
    eventData["title"] = title;
    eventData["text"] = text;

    if (hashtag) {
      eventData["hashtags"] = hashtag;
    } else {
      eventData["hashtags"] = [];
    }
    if (branchId && branchId.length > 0) {
      eventData["branchId"] = branchId;
    }
    if (mainThemeId && mainThemeId.length > 0) {
      eventData["mainThemeId"] = mainThemeId;
    }
    if (subThemeId && subThemeId.length > 0) {
      eventData["subThemeId"] = subThemeId;
    }
    if (subThemeId && content) {
      eventData["Content"] = {
        html: content,
        lang: "FR",
        state: published ? "PUBLISHED" : "DRAFT",
      };
    }

    if (link && link.length > 0) {
      eventData["link"] = link;
    }

    await axios({
      method: "PUT",
      url: `${process.env.REACT_APP_API_URL}/api/events/${id}`,
      data: JSON.stringify(eventData),
      ...getFormatedToken(false, true),
    });

    if (isFirst) {
      await axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/api/events/${id}/pin`,
        data: { isFirst: true },
        ...getFormatedToken(false, true),
      });
    }

    if (id && file && typeof file != "string") {
      const formData = new FormData();
      formData.append("logo", file);
      await axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/api/events/${id}/logo/`,
        data: formData,
        ...getFormatedToken(true),
      });
    }

    if (id && banner && typeof banner != "string") {
      const formData = new FormData();
      formData.append("banner", banner);
      await axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/api/events/${id}/banner/`,
        data: formData,
        ...getFormatedToken(true),
      });
    }
    if (id && sessions) {
      sessions.map(async (session, index) => {
        if (session.id) {
          return;
        }
        await axios({
          method: "POST",
          url: `${process.env.REACT_APP_API_URL}/api/events/${id}/sessions/`,
          data: {
            startDate: moment(
              session.dateStart + " " + session.timeStart
            ).toISOString(),
            endDate: moment(
              session.dateEnd + " " + session.timeEnd
            ).toISOString(),
          },
          ...getFormatedToken(false, true),
        });
      });
    }
  } catch (error) {
    // TODO: Handle error properly
    // console.log(error);
    return error;
  }
}

export const deleteOneSession = async (eventId, sessionId) => {
  if (!eventId || !sessionId) {
    return;
  }
  axios({
    method: "DELETE",
    url: `${process.env.REACT_APP_API_URL}/api/events/${eventId}/sessions/${sessionId}`,
    ...getFormatedToken(false, true),
    validateStatus: () => true,
  });
};
