import ButtonsAction from "./ButtonsAction";

export default function ItemThumbnail({
  item = {},
  onEdit = () => {},
  onDelete = () => {},
}) {
  return (
    <li className="phototheque-page__photos__thumbnails__item">
      <div>
        <img
          src={
            process.env.REACT_APP_API_URL + "/api/phototheque/" + item.customId
          }
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = "/assets/img/welcome.jpeg";
          }}
          alt=""
        />
        <h2>ID: {item.customId}</h2>
        <h1>{item.firstname}</h1>
        <h1>{item.lastname}</h1>
        <h3>{item.Branch.name}</h3>
      </div>
      <ButtonsAction item={item} onEdit={onEdit} onDelete={onDelete} />
    </li>
  );
}
