import axios from "axios";
import getFormatedToken from "../../helpers/getFormatedToken";

export async function ReadNotifications(id) {
  await axios.put(
    `${process.env.REACT_APP_API_URL}/api/notifications/${id}/read`,
    null,
    getFormatedToken()
  );
}
