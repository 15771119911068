import axios from "axios";
import getFormatedToken from "../../helpers/getFormatedToken";

export async function PostMe(form) {
  const response = await axios.put(
    `${process.env.REACT_APP_API_URL}/api/users/me`,
    form,
    getFormatedToken()
  );

  const { data } = response.data;
  return data.user;
}
