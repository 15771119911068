import axios from "axios";
import getFormatedToken from "../../helpers/getFormatedToken";

export async function fetchOneEvent(id) {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/api/events/${id}`,
    getFormatedToken()
  );

  const { data } = response.data;

  return data.event;
}

export async function fetchIsSubscribedEvent(id) {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/events/${id}/is-subscribe`,
      getFormatedToken()
    );

    if (response.status === 400) {
      return false;
    } else {
      return true;
    }
  } catch (error) {
    return false;
  }
}

export async function sendSubscribeEvent(id) {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/api/events/${id}/subscribe`,
    null,
    getFormatedToken()
  );

  return response;
}

export async function sendUnSubscribeEvent(id) {
  const response = await axios.delete(
    `${process.env.REACT_APP_API_URL}/api/events/${id}/unsubscribe`,
    getFormatedToken()
  );

  return response;
}
