import AddCircleIcon from "components/atoms/icons/addcircle";
import ListIcon from "components/atoms/icons/list";
import SearchIcon from "components/atoms/icons/search";
import ThumbnailIcon from "components/atoms/icons/thumbnail";
import joinClasses from "helpers/joinClasses";
import PropTypes from "prop-types";

export default function TabBar({
  view,
  onChangeView = () => {},
  onSearch = () => {},
  onAddPhoto = () => {},
}) {
  return (
    <div className="phototheque-page__tab-bar">
      <div className="phototheque-page__tab-bar__search">
        <div>
          <SearchIcon />
          <input
            type="search"
            placeholder="Nom, prénom, ID"
            onChange={onSearch}
          />
        </div>
        <button className="btn btn-small bg-red" onClick={onAddPhoto}>
          <AddCircleIcon />
          Ajouter une photo
        </button>
      </div>
      <div className="phototheque-page__tab-bar__buttons">
        <div
          className={joinClasses(
            "phototheque-page__tab-bar__button",
            view === "list" && "active"
          )}
          onClick={() => onChangeView("list")}
        >
          <ListIcon />
          <p>Vue Liste</p>
        </div>
        <div
          className={joinClasses(
            "phototheque-page__tab-bar__button",
            view === "thumbnail" && "active"
          )}
          onClick={() => onChangeView("thumbnail")}
        >
          <ThumbnailIcon />
          <p>Vue Vignette</p>
        </div>
      </div>
    </div>
  );
}

TabBar.propTypes = {
  view: PropTypes.oneOf(["list", "thumbnail"]).isRequired,
  onChangeView: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onAddPhoto: PropTypes.func.isRequired,
};
