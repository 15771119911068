export default function AddCircleIcon() {
    return <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
        <g id="Groupe_1868" data-name="Groupe 1868" transform="translate(-356.017 -265.017)">
            <g id="Groupe_1867" data-name="Groupe 1867" transform="translate(356 265)">
                <g id="Rectangle_1439" data-name="Rectangle 1439" transform="translate(0.017 0.017)" fill="none" stroke="currentColor" stroke-width="1">
                    <rect width="26" height="26" rx="13" stroke="none" />
                    <rect x="0.5" y="0.5" width="25" height="25" rx="12.5" fill="none" />
                </g>
            </g>
            <path id="Tracé_15938" data-name="Tracé 15938" d="M2.07,12.867V10.9H7.18V5.754h2V10.9h5.109v1.969H9.184V18h-2V12.867Z" transform="translate(361.017 267.017)" fill="currentColor" />
        </g>
    </svg>
}