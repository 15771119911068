import CenterLayout from "../../layouts/CenterLayout";

export default function NotFound() {
  return (
    <CenterLayout title="404">
      <div style={{ paddingTop: "10%" }}>
        <h1 className="title" style={{ fontSize: "80px " }}>
          Page introuvable
        </h1>
        <a
          style={{
            fontFamily: "var(--cera-bold)",
            textDecoration: "none",
            fontSize: "50px",
          }}
          href="/"
        >
          <i className="icon-short-arrow" style={{ fontSize: "35px" }} /> Retour
        </a>
      </div>
    </CenterLayout>
  );
}
