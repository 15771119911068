/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import "./subtheme.css";
import "./subcontent.css";
import moment from "moment";

// components
import LinksByThematic from "../../components/molecules/Links";
import Informations from "../../components/Thematics/Informations/";
import Files from "../../components/Thematics/Files/";

// api
import { fetchOneTheme } from "../../api/Thematics/Theme";
import { fetchAllSubthemes } from "../../api/SubThemes";

import { fetchIsSubscribed } from "../../api/Thematics/IsSubscribed";
import { sendUnSubscribe } from "../../api/Thematics/UnSubscribe";
import { sendSubscribe } from "../../api/Thematics/Subscribe";

// molecules
import { ShortBanner } from "../../components/molecules/ShortBanner";
import { ThreeNumbers } from "../../components/molecules/ThreeNumbers";
import ReferentModal from "../../components/molecules/Modals/Referent";
import User from "../../components/molecules/Cards/User";
import EventCard from "../../components/molecules/Cards/Event";

export default function Subtheme() {
  const [subthemesInfo, setSubthemesInfo] = useState([]);
  const [subthemeInfo, setSubthemeInfo] = useState({});
  const [themeInfo, setThemeInfo] = useState([]);
  const [nextEvent, setNextEvent] = useState([]);

  const [isSubscribed, setIsSubscribed] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const { subtheme } = useParams();

  async function getAllSubthemes() {
    setSubthemesInfo(await fetchAllSubthemes());
  }

  async function getOneTheme(mainThemeId) {
    setThemeInfo(await fetchOneTheme(mainThemeId));
  }

  async function getIsSubscribed(id) {
    setIsSubscribed(null);
    setIsSubscribed(await fetchIsSubscribed(id));
  }

  function filtreClosestDate(sessions) {
    let EventSessionInDate = [];
    for (const key in sessions) {
      EventSessionInDate.push({
        days: moment(sessions[key].startDate).diff(),
        ...sessions[key],
      });
    }

    const closest = EventSessionInDate.reduce((a, b) => {
      if (b.days >= 0 && a.days >= 0) {
        return b.days < a.days ? b : a;
      } else if (a.days >= 0) {
        return a;
      } else if (b.days >= 0) {
        return b;
      }
    });

    return closest.startDate;
  }

  function getNextEvent(events) {
    let eventFilter = [];
    events
      .filter((article) => article.Content.state === "PUBLISHED")
      .sort(function (a, b) {
        return (
          new Date(filtreClosestDate(a.EventSession)) -
          new Date(filtreClosestDate(b.EventSession))
        );
      })
      // eslint-disable-next-line array-callback-return
      .map((event) => {
        eventFilter.push(event);
      });
    setNextEvent(eventFilter);
  }

  useEffect(() => {
    getAllSubthemes();
  }, [subtheme]);

  useEffect(() => {
    if (subthemeInfo.Event && subthemeInfo.Event.length > 0) {
      getNextEvent(Event);
    } else {
      setNextEvent([]);
    }
  }, [subthemeInfo.Event]);

  const subscribe = async () => {
    setIsSubscribed(null);
    if (isSubscribed) {
      await sendUnSubscribe(subthemeInfo.mainThemeId);
      setIsSubscribed(false);
    } else {
      await sendSubscribe(subthemeInfo.mainThemeId);
      setIsSubscribed(true);
    }
  };

  useEffect(() => {
    const subthemeParsed = subtheme
      .replace(/_/g, " ")
      .replace(/-/g, "'")
      .replace(/&&/g, "/");
    let subthemeWhereName = {};
    if (subthemesInfo.length) {
      subthemeWhereName = subthemesInfo.filter(
        (item) =>
          subthemeParsed ===
          item.name
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
      );
      console.log(subthemeWhereName);

      if (subthemeWhereName.length === 0) {
        return window.alert("page introuvable sub");
      }
      setSubthemeInfo(...subthemeWhereName);
    }
  }, [subthemesInfo]);

  useEffect(() => {
    //console.log(subthemeInfo.Referents);
    if (subthemeInfo.mainThemeId) {
      getOneTheme(subthemeInfo.mainThemeId);
      getIsSubscribed(subthemeInfo.mainThemeId);
    }
  }, [subthemeInfo]);

  const { color, Question } = themeInfo;
  const {
    name,
    n1,
    n2,
    n3,
    description,
    baseline,
    bannerImgPath,
    GroupReferent,
    Referents,
    Event,
  } = subthemeInfo;
  return (
    <React.Fragment>
      <ShortBanner
        image={
          bannerImgPath && bannerImgPath[0] === "/"
            ? bannerImgPath
            : `/${bannerImgPath}`
        }
        baseline={baseline}
      />
      <div className="subtheme__header">
        <div className="subtheme__header_title">
          <h2 className={"text-" + color}>{name}</h2>
          <p>{description}</p>
          {isSubscribed === null ? (
            <button className={`btn bg-${color}`}>Chargement...</button>
          ) : isSubscribed === true ? (
            <button
              className={`btn btn-fill text-${color} bg-${color}-hover border-${color}`}
              onClick={subscribe}
            >
              ne plus suivre cette thématique
            </button>
          ) : (
            <button className={`btn bg-${color}`} onClick={subscribe}>
              suivre cette thématique
            </button>
          )}
        </div>
        <div className="subtheme__header_other">
          <div>
            {GroupReferent && GroupReferent.id ? (
              <>
                <h2>
                  <i className="icon-idea"></i>Group referents
                </h2>
                <User data={GroupReferent} />
              </>
            ) : (
              ""
            )}

            {Referents && Referents.length ? (
              <User
                dataToggle={{
                  icon: "idea",
                  title: "Référents Métiers",
                  description: "sur la thématique",
                  color: color,
                }}
                toggle={setOpenModal}
              />
            ) : (
              ""
            )}
          </div>
          <br />
          <br />
          <div>
            {nextEvent && nextEvent.length ? (
              <>
                <h2>
                  <i className="icon-calendar"></i> Next events
                </h2>
                {nextEvent.map((event, index) => {
                  return index < 1 && <EventCard data={event} />;
                })}
                <p style={{ textAlign: "right" }}>
                  <Link to="/events" className="text-deco-none">
                    <i className="icon-options_horizontal"></i>
                  </Link>
                </p>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>

      <ThreeNumbers color={color} numbers={[n1, n2, n3]} />
      <div
        className={"subtheme__content " + color}
        dangerouslySetInnerHTML={
          subthemeInfo &&
          subthemeInfo.Content && { __html: subthemeInfo.Content.html }
        }
      />
      <Informations
        thematic_id={subthemeInfo.mainThemeId}
        questions={Question}
        thematic_name={name}
        color={color}
        sub_theme={subthemeInfo.id}
      />
      <Files
        color={color}
        sub_thematic_id={subthemeInfo.id}
        filter={`sub_theme:${subthemeInfo.id}`}
      />
      <LinksByThematic
        color={color}
        thematic_id={subthemeInfo.mainThemeId}
        sub_theme={subthemeInfo.id}
      />
      {themeInfo ? (
        <ReferentModal
          isOpen={openModal}
          setIsOpen={setOpenModal}
          thematic={{
            icon: themeInfo.icon,
            name: themeInfo.name,
          }}
        >
          {Referents &&
            Referents.map((referent, index) => (
              <User key={index} data={referent} />
            ))}
        </ReferentModal>
      ) : (
        ""
      )}
    </React.Fragment>
  );
}
