/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";

import "./pwd-check.css";

export function PasswordCheck({
  length,
  maj,
  min,
  number,
  special,
  fetch,
  callback = null,
}) {
  useEffect(() => {
    if (callback && length && maj && min && number && special && fetch) {
      callback(true);
    } else {
      callback(false);
    }
  }, [fetch]);

  return (
    <div>
      <ul className="pwd-check-container">
        <li className={special ? "valid-pwd-check" : "error-pwd-check"}>
          <div
            className={
              special ? "valid-rounded-pwd-check" : "error-rounded-pwd-check"
            }
          >
            {special && <i className="icon-check " />}
          </div>
          <p>Au moins 1 caractère spécial</p>
        </li>
        <li className={fetch ? "valid-pwd-check" : "error-pwd-check"}>
          <div
            className={
              fetch ? "valid-rounded-pwd-check" : "error-rounded-pwd-check"
            }
          >
            {fetch && <i className="icon-check " />}
          </div>
          <p>Confirmer le mot de passe</p>
        </li>
        <li className={length ? "valid-pwd-check" : "error-pwd-check"}>
          <div
            className={
              length ? "valid-rounded-pwd-check" : "error-rounded-pwd-check"
            }
          >
            {length && <i className="icon-check " />}
          </div>
          <p>Au moins 12 caractères</p>
        </li>
        <li className={maj ? "valid-pwd-check" : "error-pwd-check"}>
          <div
            className={
              maj ? "valid-rounded-pwd-check" : "error-rounded-pwd-check"
            }
          >
            {maj && <i className="icon-check " />}
          </div>
          <p>Au moins 1 majuscule</p>
        </li>
        <li className={min ? "valid-pwd-check" : "error-pwd-check"}>
          <div
            className={
              min ? "valid-rounded-pwd-check" : "error-rounded-pwd-check"
            }
          >
            {min && <i className="icon-check " />}
          </div>
          <p>Au moins 1 minuscule</p>
        </li>
        <li className={number ? "valid-pwd-check" : "error-pwd-check"}>
          <div
            className={
              number ? "valid-rounded-pwd-check" : "error-rounded-pwd-check"
            }
          >
            {number && <i className="icon-check " />}
          </div>
          <p>Au moins 1 chiffre</p>
        </li>
      </ul>
    </div>
  );
}
