/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import "./style.css";
import { fetchAllQuestions } from "../../api/Questions/Questions";
import { EditMode } from "../../contexts/editmode";
import { deleteQuestion } from "../../api/Questions/Questions";
import { PencilIcon } from "components/atoms/icons/pencil";

export default function Questions({ filter = null, callback = null, onEdit = () => { }, selectedQuestion = null }) {
  const [questions, setQuestions] = useState([]);
  const { editing } = useContext(EditMode);

  async function getAllQuestions() {
    const temp = await fetchAllQuestions(filter ? filter : []);
    if (filter) {
      setQuestions(temp);
      return;
    }
    let mainQuestions = [];
    temp.forEach((elem) => {
      if (!elem.MainThemes.length && !elem.SubThemes.length) {
        mainQuestions.push(elem);
      }
    });
    setQuestions(mainQuestions);
  }

  useEffect(() => {
    getAllQuestions();
  }, [filter]);

  function deleteOneQuestion(id) {
    setQuestions((questions) => {
      return questions.filter((questions) => questions.id !== id);
    });

    deleteQuestion(id);
  }
  if (callback) {
    callback(questions.length);
  }
  return (
    <React.Fragment>
      <div className="card faq__container ">
        <div>
          <h1>Questions RH</h1>
          <ul className="faq__list">
            {questions.length > 0 &&
              questions.map((question, index) => (
                <li
                  key={index}
                  style={{
                    backgroundColor: selectedQuestion && selectedQuestion.id === question.id ? "#f5f5f5" : "white",
                    borderBottom: selectedQuestion && selectedQuestion.id === question.id ? "1px solid #e5e5e5" : "none",
                    paddingLeft: selectedQuestion && selectedQuestion.id === question.id ? "20px" : "0px",
                  }}
                  onClick={(e) => {
                    e.target.parentElement.parentElement.classList.toggle(
                      "active"
                    );
                  }}
                >
                  <div className="faq-question-li-list">
                    <h3>{question.entitled}</h3>
                    {editing && (
                      <div className="links__edit_container gap-5" onClick={(e) => {
                        e.preventDefault();
                        onEdit(question)
                      }}>
                        <div class="links__edit">
                          <PencilIcon />
                        </div>
                        <div
                          className="links__edit_trash"
                          onClick={() => {
                            if (
                              window.confirm(
                                `Êtes vous sûr de vouloir suprimer la question :  ${question.entitled} ?`
                              )
                            ) {
                              deleteOneQuestion(question.id);
                            }
                          }}
                        >
                          <i className="icon-trash" />
                        </div>
                      </div>
                    )}
                  </div>
                  <p>{question.answer}</p>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
}
