import React, { useState } from "react";
import SecondDropdown from "./SecondDropdown";

const FirstDropdown = ({ data }) => {
  const [isFocused, setIsFocused] = useState(false);

  const { title, icon, dropdownItems } = data;

  return (
    <ul className="mob-navbar__dropdown">
      <li onClick={() => setIsFocused(!isFocused)}>
        <div className="mob-navbar__dropdown-title">
          <i className={"icon-" + icon} />
          <i className={`icon-arrow ${isFocused && "open"}`} />
          <p>{title}</p>
        </div>
      </li>
      {isFocused && (
        <ul className="mob-navbar__dropdown-items">
          {dropdownItems.map((item, index) => (
            <SecondDropdown data={item} key={index} />
          ))}
        </ul>
      )}
    </ul>
  );
};
export default FirstDropdown;
