import axios from "axios";
import getFormatedToken from "../helpers/getFormatedToken";

export async function fetchMessages() {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/api/messages/`,
    getFormatedToken()
  );
  return response.data.data.messages;
}

export async function fetchMessageById(messageId) {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/api/messages/${messageId}`,
    getFormatedToken()
  );
  console.log(response);
  return response.data.data;
}
