import axios from "axios";

export async function fetchAPIStatus() {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/api/health-check`
  );

  const { data } = response.data;
  return data?.metadata;
}
