import React, { useState } from "react";
export default function Footer({ changeStep, currentStep, currentForm }) {
  const [errorMessage, setErrorMessage] = useState("");

  const changeCurrentStep = (type) => {
    if (type === "prev" && currentStep > 0) {
      setErrorMessage("");
      return changeStep(currentStep - 1);
    } else if (type === "next" && currentStep <= 3) {
      if (currentStep === 1 && currentForm.thematic.length === 0) {
        return setErrorMessage("Sélectionnez au moins une thématique.");
      }
      if (currentStep === 2 && currentForm.filetype === 0) {
        return setErrorMessage("Sélectionnez un type de fichier.");
      }
      if (currentStep === 3) {
        if (currentForm.title.length === 0) {
          return setErrorMessage("Ajoutez un titre au contenu.");
        }
        if (currentForm.file.length === 0) {
          return setErrorMessage("Ajoutez au moins un contenu.");
        }
      }
      setErrorMessage("");
      return changeStep(currentStep + 1);
    }
  };

  return (
    <div className="propose-content__footer">
      {errorMessage && <p>{errorMessage}</p>}
      {currentStep > 0 && currentStep !== 4 && (
        <button id="prevStep" onClick={() => changeCurrentStep("prev")}>
          <i className="icon-short-arrow"></i>Précédent
        </button>
      )}
      {currentStep <= 3 && (
        <button id="nextStep" onClick={() => changeCurrentStep("next")}>
          {currentStep < 3 ? "Suivant" : "Envoyer"}
          <i className="icon-short-arrow"></i>
        </button>
      )}
    </div>
  );
}
