import React from "react";
import CenterLayout from "../../layouts/CenterLayout";

import LinksPage from "./Links";

export default function Links() {
  return (
    <CenterLayout title="Links" className={false}>
      <LinksPage />
    </CenterLayout>
  );
}
