/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { SwiperSlide } from "swiper/react";
import moment from "moment";
import "./style.css";
import "./eventcontent.css";

//components
import DefaultLayout from "../../layouts/DefaultLayout";
import EventTitle from "../../components/Event/Title";
import GradientCard from "../../components/molecules/Cards/Gradient";

// api
import { fetchOneEvent } from "../../api/Events/Event";
import { fetchAllEvents } from "../../api/Events/Events";

// molecules
import { Slider } from "../../components/molecules/Slider/sliderWithCTA";

export default function Event(props) {
  const [OneEvent, setOneEvent] = useState(null);
  const [events, setEvents] = useState([]);

  async function getOneEvent() {
    setOneEvent(await fetchOneEvent(props.match.params.event_id));
    setEvents(await fetchAllEvents([]));
  }

  useEffect(() => {
    getOneEvent();
  }, [props.match.params.event_id]);

  if (!OneEvent) {
    return <DefaultLayout></DefaultLayout>;
  }

  return (
    <DefaultLayout>
      <div className="event__container">
        <header className="event__header">
          <img
            src={OneEvent.bannerImgPath}
            alt={OneEvent.title}
          />
        </header>
        <main>
          <section className="event__main">
            {OneEvent && <EventTitle content={OneEvent} />}
            <div
              className="subtheme__content"
              dangerouslySetInnerHTML={
                OneEvent.Content && { __html: OneEvent.Content.html }
              }
            />
            <p className="event__author">
              <span>
                {`Article publié le ${moment(OneEvent.createAt).format(
                  "DD/MM/YYYY"
                )} - ${OneEvent.Author && OneEvent.Author.firstname} ${OneEvent.Author && OneEvent.Author.lastname
                  }`}
              </span>
            </p>
          </section>
          <section className="event__next-event">
            <Slider
              cta={{ text: "Tous les events", path: "/events" }}
              title="NEXT EVENTS"
            >
              {events
                .filter(
                  (article) =>
                    article &&
                    article.Content &&
                    article.Content.state === "PUBLISHED"
                )
                .sort(function (a, b) {
                  return new Date(b.createdAt) - new Date(a.createdAt);
                })
                .map((item, index) => {
                  return (
                    index < 3 && (
                      <SwiperSlide key={index}>
                        <Link to={`/events/${item.id}`}>
                          <GradientCard content={item} />
                        </Link>
                      </SwiperSlide>
                    )
                  );
                })}
            </Slider>
          </section>
        </main>
      </div>
    </DefaultLayout>
  );
}
