import React from "react";

import DropdownIcons from "../../Thematics/DropdownIcons";
import "./style.css";

export default function GradientCard({
  content: { logoImgPath, MainThemes, title },
}) {
  return (
    <div
      className="card-gradient__container"
      style={{
        backgroundImage: `url(${logoImgPath})`,
      }}
    >
      <div>
        <h5>{title}</h5>
        <DropdownIcons thematics={MainThemes} />
      </div>
    </div>
  );
}
