/* eslint-disable react/jsx-no-target-blank */
import { Link, useLocation } from "react-router-dom";
const getStyle = (pathname, path, exactMatch) => {
  if (!exactMatch) {
    if (pathname === path) {
      return "active";
    }
  } else {
    if (pathname === "/") {
      return "active";
    }
  }
};
const Item = ({ data }) => {
  const { pathname } = useLocation();
  const { title, path, icon, exactMatch } = data;

  const isExternalURL = (path) => {
    return path.toLowerCase().includes("https://");
  };

  return (
    <li className={getStyle(pathname, path, exactMatch)}>
      {isExternalURL(path) ? (
        <a href={path} target="_blank">
          <i className={"icon-" + icon}></i>
          <p>{title}</p>
        </a>
      ) : (
        <Link to={path}>
          <i className={"icon-" + icon}></i>
          <p>{title}</p>
        </Link>
      )}
    </li>
  );
};
export default Item;
