import axios from "axios";
import getFormatedToken from "../../helpers/getFormatedToken";

export async function fetchAllSessions() {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/api/events-sessions`,
    getFormatedToken()
  );

  const { data } = response.data;

  return data.eventsSessions;
}
