export default function LogoutIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 8.864 8.864"
    >
      <path
        d="M7.878,10.5l.666.666,2.379-2.379L8.544,6.4l-.666.666L9.115,8.306H4.5v.952H9.091Zm4.235-6H5.452a.954.954,0,0,0-.952.952v1.9h.952v-1.9h6.661v6.661H5.452v-1.9H4.5v1.9a.954.954,0,0,0,.952.952h6.661a.954.954,0,0,0,.952-.952V5.452A.954.954,0,0,0,12.113,4.5Z"
        transform="translate(-4.35 -4.35)"
        fill="currentColor"
        stroke="#f8f8fa"
        stroke-width="0.3"
      />
    </svg>
  );
}
