export default function LocationIcon() {
  return (
    <svg
      id="location_icon"
      xmlns="http://www.w3.org/2000/svg"
      width="14.383"
      height="17.356"
      viewBox="0 0 14.383 17.356"
    >
      <g transform="translate(-4 -1)">
        <path
          d="M17.883,8.191c0,5.2-6.691,9.665-6.691,9.665S4.5,13.4,4.5,8.191a6.691,6.691,0,1,1,13.383,0Z"
          transform="translate(0 0)"
          fill="none"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1"
        />
        <path
          d="M17.961,12.73a2.23,2.23,0,1,1-2.23-2.23A2.23,2.23,0,0,1,17.961,12.73Z"
          transform="translate(-4.539 -4.539)"
          fill="none"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1"
        />
      </g>
    </svg>
  );
}
