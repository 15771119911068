import { useState, useEffect } from "react";

import { fetchAllFiles } from "../../api/Files";
import SliderCollum from "../molecules/Slider/SliderCollum";
import { SwiperSlide } from "swiper/react";
import NotFound from "../molecules/ErrorsMessages/NotFound";
import ItemListShares from "./Item";
import { Link } from "react-router-dom";

export default function HomeSharesContent() {
  const [files, setFiles] = useState([]);

  async function getAllFiles() {
    setFiles(await fetchAllFiles([]));
  }

  useEffect(() => {
    getAllFiles();
  }, []);
  return (
    <div className="home__shares-container">
      <h2 class="subtheme__subtitle">Library</h2>
      {files && files.length ? (
        <SliderCollum className="slider__shares_home" column={3}>
          {files
            .filter((file) => file.isOfficial === true)
            .sort(function (a, b) {
              return new Date(b.createdAt) - new Date(a.createdAt);
            })
            .map((item, index) => {
              return index <= 11 ? (
                <SwiperSlide key={index}>
                  <ItemListShares item={item} isHomepage={true} />
                </SwiperSlide>
              ) : (
                ""
              );
            })}
          <SwiperSlide className="slider-cta-button ">
            <div className="shares__list">
              <Link to="/shares">
                <h1>Voir</h1>
                <h2>Toute la library</h2>
              </Link>
            </div>
          </SwiperSlide>
        </SliderCollum>
      ) : (
        <NotFound element="fichier" />
      )}
    </div>
  );
}
