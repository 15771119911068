/* eslint-disable react/jsx-no-target-blank */
import filetypes from "../../../utils/filetypes.json";

export default function ItemHrTrends({ file }) {
  return (
    <>
      <a
        href={
          file.blobPath
            ? file.blobPath.startsWith("/")
              ? `${file.blobPath}`
              : `/${file.blobPath}`
            : file.link
            ? file.link
            : ""
        }
        className="HRTrends_link"
        target="_blank"
      >
        <div className="HRTrends-flex">
          <div className="HRTrends-left">
            <p className={"HRTrends-p1"}>
              {file.fileType > 0 ? (
                <i
                  className={`icon-${
                    file.fileType
                      ? filetypes.filetypes[file.fileType - 1].icon
                      : ""
                  } text-${
                    file.MainThemes[0] ? file.MainThemes[0].color : "main"
                  }`}
                ></i>
              ) : (
                <i
                  className={`icon-${
                    file.fileType ? filetypes.filetypes[file.fileType].icon : ""
                  } text-${
                    file.MainThemes && file.MainThemes[0]
                      ? file.MainThemes[0].color
                      : ""
                  }`}
                ></i>
              )}
            </p>
          </div>
          <div className={"HRTrends-right"}>
            <p className="HRTrends-p2">{file.title ? file.title : ""}</p>
          </div>
        </div>
      </a>
      <hr />
    </>
  );
}
