import { useState, useEffect } from "react";

import { fetchAllLinks } from "../../api/Links";
import SliderCollum from "../molecules/Slider/SliderCollum";
import NotFound from "../molecules/ErrorsMessages/NotFound";
import { SwiperSlide } from "swiper/react";
import Item from "./Item";

export default function HomeLinksContent() {
  const [links, setLinks] = useState([]);

  async function getAllLinks() {
    setLinks(await fetchAllLinks([]));
  }

  useEffect(() => {
    getAllLinks();
  }, []);

  return (
    <div className="home__shares-container">
      <h2 class="subtheme__subtitle">Links</h2>
      {links && links.length ? (
        <SliderCollum className="slider__links_home" column={2}>
          {links
            .sort(function (a, b) {
              return new Date(b.createdAt) - new Date(a.createdAt);
            })
            .map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <Item item={item} />
                </SwiperSlide>
              );
            })}
        </SliderCollum>
      ) : (
        <NotFound element="lien" />
      )}
    </div>
  );
}
