import { ThemeIcon } from "../../IconTheme";
import "./style.css";

export default function ReferentModal({
  children,
  isOpen,
  setIsOpen,
  title,
  thematic,
}) {
  return (
    <>
      <div
        className={`default-modal__backgound-trigger ${
          isOpen ? "d-block" : "d-none"
        }`}
        onClick={() => {
          setIsOpen(false);
        }}
      />
      <div
        className={`large-modal__container ${isOpen ? "d-block" : "d-none"}`}
      >
        <div className="large-modal__header">
          <div>
            <div>
              <ThemeIcon theme={thematic} />
              <p>{thematic.name}</p>
            </div>
            <h1>Liste des référents Métiers</h1>
          </div>
          <button
            onClick={() => {
              setIsOpen(false);
            }}
          >
            <i className="icon-close"></i>
          </button>
        </div>
        <div className="large-modal__content">{children}</div>
      </div>
    </>
  );
}
