export default function CloseCircleIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="13"
      viewBox="0 0 13 13"
    >
      <g transform="translate(-263.646 -408.646)">
        <g transform="translate(267.559 412.559)">
          <line
            x2="5.176"
            y2="5.176"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-width="1"
          />
          <line
            y1="5.176"
            x2="5.176"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-width="1"
          />
        </g>
        <g
          transform="translate(263.646 408.646)"
          fill="none"
          stroke="currentColor"
          stroke-width="1"
        >
          <circle cx="6.5" cy="6.5" r="6.5" stroke="none" />
          <circle cx="6.5" cy="6.5" r="6" fill="none" />
        </g>
      </g>
    </svg>
  );
}
