import axios from "axios";
import filtersCompose from "../../helpers/FiltersCompose";
import getFormatedToken from "../../helpers/getFormatedToken";

export async function fetchAllNews(filters) {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/news${filtersCompose(filters)}`,
      getFormatedToken()
    );
    const { data } = response.data;
    return data.news || [];
  } catch (error) {
    // TODO: Handle error properly
    // console.log(error);
  }
}

export async function createNewNews(
  title,
  author,
  file,
  banner,
  content,
  mainThemeId,
  subThemeId,
  branchId,
  published
) {
  try {
    const response = await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/api/news`,
      data: {
        title,
        author: author,
        mainThemesId: mainThemeId,
        subThemesId: subThemeId,
        branchesId: branchId,
      },
      ...getFormatedToken(false, true),
    });

    const newsId = response.data.data.news.id;

    if (newsId && file) {
      const formData = new FormData();
      formData.append("logo", file);
      await axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/api/news/${newsId}/logo`,
        data: formData,
        ...getFormatedToken(true),
      });
    }

    if (newsId && banner) {
      const formData = new FormData();
      formData.append("banner", banner);
      await axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/api/news/${newsId}/banner`,
        data: formData,
        ...getFormatedToken(true),
      });
    }

    if (newsId && content) {
      await axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/api/news/${newsId}/contents`,
        data: {
          lang: "FR",
          html: content,
          state: published ? "PUBLISHED" : "DRAFT",
        },
        ...getFormatedToken(false, true),
      });
    }
  } catch (error) {
    return error;
  }
}

/* UPDATE */
export const updateOneNews = async (
  eventId,
  title,
  author,
  file,
  banner,
  content,
  mainThemeId,
  subThemeId,
  branchId,
  published
) => {
  try {
    await axios({
      method: "PUT",
      url: `${process.env.REACT_APP_API_URL}/api/news/${eventId}`,
      data: {
        title,
        author,
        mainThemeId,
        subThemeId,
        branchId,
      },
      ...getFormatedToken(false, true),
    });

    // ~ Upload Banner File
    if (banner && typeof banner != "string") {
      const formData = new FormData();
      formData.append("banner", banner);
      await axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/api/news/${eventId}/banner`,
        data: formData,
        ...getFormatedToken(false, true),
      });
    }

    // ~ Upload Logo File
    if (file && typeof file != "string") {
      const formData = new FormData();
      formData.append("logo", file);
      await axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/api/news/${eventId}/logo`,
        data: formData,
        ...getFormatedToken(false, true),
      });
    }

    // ~ Update Content
    if (content) {
      await axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/api/news/${eventId}/contents`,
        data: {
          lang: "FR",
          html: content,
          state: published ? "PUBLISHED" : "DRAFT",
        },
        ...getFormatedToken(false, true),
        validateStatus: () => true,
      });
    }
  } catch (error) {
    return error;
  }
};

export async function deleteNews(id) {
  if (!id) {
    return;
  }

  await axios({
    method: "DELETE",
    url: `${process.env.REACT_APP_API_URL}/api/news/${id}`,
    ...getFormatedToken(true),
  });
}

export async function fetchOneNews(id) {
  if (!id) {
    return;
  }

  const response = await axios({
    method: "GET",
    url: `${process.env.REACT_APP_API_URL}/api/news/${id}`,
    ...getFormatedToken(true),
  });
  const { data } = response;
  return data.news;
}
