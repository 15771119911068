import { InstallPWA } from "../components/molecules/InstallPWA";
import CenterLayout from "../layouts/CenterLayout";

export default function TestPagePWA() {
  return (
    <CenterLayout>
      <div className="subtheme__content main">
        <InstallPWA />
      </div>
    </CenterLayout>
  );
}
