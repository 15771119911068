import "./littleCard.css";
import joinClasses from "../../../helpers/joinClasses";
import NewsletterIcon from "components/atoms/icons/newsletter";
import ImageIcon from "components/atoms/icons/image";

export function LittleCard({ buttonText, icon, onClick, style }) {
  return (
    <div className="little-card-container" style={style}>
      <div className="little-card">
        <i
          className={icon}
          style={{ color: "var(--main-color)", fontSize: "40px" }}
        />
        <button className="button" onClick={onClick}>
          {buttonText}
        </button>
      </div>
    </div>
  );
}

export function Card({ children, className = "" }) {
  return <div className={joinClasses("card", className)}>{children}</div>;
}

export function HrefBox() {
  return (
    <>
      <p
        style={{
          fontFamily: "var(--cera-light)",
          margin: "40px auto auto",
          maxWidth: "900px",
        }}
      >
        Vous pouvez consulter et éditer tous les contenus de la plateforme via
        le menu de navigation de gauche ou en passant par les raccourcis
        présents sur cette page
      </p>
      <div className="href-box">
        <div>
          <a href="/admin/news">
            <i className="icon-news" />
            Editer les News
          </a>
          <a href="/admin/shares">
            <i className="icon-partage" />
            Editer les Shares
          </a>
          <a href="/admin/links">
            <i className="icon-links" />
            Editer les Links
          </a>
          <a href="/admin/newsletter">
            <NewsletterIcon />
            Créer une Newsletter
          </a>
          <a href="/admin/phototheque">
            <ImageIcon />
            Photothèque
          </a>
        </div>
      </div>
    </>
  );
}
