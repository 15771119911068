import axios from "axios";

export async function sendAzureLogin() {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/azure/signin`
    );
    return window.open(response.data?.redirectUrl, "_self");
  } catch (error) {
    console.log(error);
  }
}

export async function sendHelpForm(payload) {
  try {
    delete payload.sending;
    delete payload.responseMessage;
    delete payload.status;
    delete payload.error;
    delete payload.step;

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/help-form`,
      payload
    );
    console.log(response);
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

export async function sendLogin(email, password) {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/authentication/login`,
      {
        email,
        password,
      },
      { validateStatus: () => true }
    );
    return response.data;
  } catch (error) {
    // TODO: Handle error when calling sendLogin, avoid console.log in production
    // console.log(error);
  }
}

export async function sendCode(email, code, token) {
  try {
    const response = await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/api/authentication/login/verify`,
      data: {
        email,
        code,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    // TODO: Handle error properly
    // console.log(error);
  }
}

export async function checkMe(token) {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/users/me`,
      {
        headers: { Authorization: `Bearer ${token}` },
        validateStatus: () => true,
      }
    );
    return response.data.data.user;
  } catch (error) {
    // TODO: Handle error properly
    // console.log(error);
    // return null;
    throw error;
  }
}
export async function sendNewRecoverLink(email) {
  try {
    const response = await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/api/authentication/recover-link`,
      data: {
        email,
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
}
