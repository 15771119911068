export const sideBarItemsAdmin = [
  { icon: "home", path: "/admin/", title: "homepage", exactMatch: true },
  {
    icon: "thematics",
    path: "/admin/thematics",
    title: "themes",
    dropdownItems: [
      {
        icon: "Icon_Affaires_Sociales.svg",
        path: "/admin/thematics/affaires_sociales",
        title: "Affaires Sociales",
        color: "blue",
        subLinks: [
          {
            path: "/admin/thematics/affaires_sociales/journee_d-actualisation_en_droit_du_travail",
            title: "Journée d'actualisation en Droit du travail",
          },
          {
            path: "/admin/thematics/affaires_sociales/relations_sociales",
            title: "Relations sociales",
          },
          {
            path: "/admin/thematics/affaires_sociales/protection_sociale",
            title: "Protection sociale",
          },
          {
            path: "/admin/thematics/affaires_sociales/outils",
            title: "Outils",
          },
        ],
      },
      {
        icon: "Icon_Compensation_&_Benefits.svg",
        path: "/admin/thematics/remuneration_&_avantages_sociaux",
        title: "Rémunération & Avantages sociaux",
        color: "purple",
        subLinks: [
          {
            path: "/admin/thematics/remuneration_&_avantages_sociaux/politique_de_remuneration",
            title: "Politique de rémunération",
          },
          {
            path: "/admin/thematics/remuneration_&_avantages_sociaux/epargne_salariale",
            title: "Epargne salariale",
          },
        ],
      },
      {
        icon: "Icon_Developpement_RH.svg",
        path: "/admin/thematics/developpement_rh",
        title: "Développement RH",
        color: "red",
        subLinks: [
          {
            path: "/admin/thematics/developpement_rh/campus_management",
            title: "Campus management",
          },
          {
            path: "/admin/thematics/developpement_rh/recrutement_&_onboarding",
            title: "Recrutement & onboarding",
          },
          {
            path: "/admin/thematics/developpement_rh/formation",
            title: "Formation",
          },
          {
            path: "/admin/thematics/developpement_rh/mobilite_&_carrieres",
            title: "Mobilité & Carrières",
          },
          {
            path: "/admin/thematics/developpement_rh/talent_management",
            title: "Talent management",
          },
          {
            path: "/admin/thematics/developpement_rh/marque_employeur",
            title: "Marque employeur",
          },
        ],
      },
      {
        icon: "Icon_Diversite.svg",
        path: "/admin/thematics/diversite",
        title: "Diversité",
        color: "orange",
        subLinks: [
          {
            path: "/admin/thematics/diversite/mixite",
            title: "Mixité",
          },
          {
            path: "/admin/thematics/diversite/handicap",
            title: "Handicap",
          },
          {
            path: "/admin/thematics/diversite/inclusion",
            title: "Inclusion",
          },
          {
            path: "/admin/thematics/diversite/maintien_dans_l-emploi_&&_inaptitudes",
            title: "Maintien dans l'emploi / Inaptitudes",
          },
        ],
      },
      {
        icon: "Icon_Data_RH.svg",
        path: "/admin/thematics/data_rh",
        title: "Data RH",
        color: "main",
        subLinks: [
          {
            path: "/admin/thematics/data_rh/la_data_rh_du_groupe",
            title: "La Data RH du Groupe",
          },
          {
            path: "/admin/thematics/data_rh/le_rgpd",
            title: "Le RGPD",
          },
        ],
      },
      {
        icon: "Icon_community_RH.svg",
        path: "/admin/thematics/communaute_rh",
        title: "Communauté RH",
        color: "green",
        subLinks: [
          {
            path: "/admin/thematics/communaute_rh/ambition_rh_groupe",
            title: "Ambition RH Groupe",
          },
          {
            path: "/admin/thematics/communaute_rh/learning_rh_groupe",
            title: "Learning RH Groupe",
          },
          {
            path: "/admin/thematics/communaute_rh/innovation_rh",
            title: "Innovation RH",
          },
        ],
      },
    ],
  },
  { icon: "news", path: "/admin/news", title: "news" },
  { icon: "events", path: "/admin/events", title: "events" },
  { icon: "mouvement", path: "/admin/welcome", title: "welcome" },
  { icon: "partage", path: "/admin/shares", title: "shares" },
  { icon: "links", path: "/admin/links", title: "links" },
];
