import "./ItemListNews.css";
import { ThemeIcon } from "../molecules/IconTheme";
import { Link } from "react-router-dom";
import React, { useContext } from "react";
import { EditMode } from "../../contexts/editmode";
import PermissionContext from "../../contexts/permission";

const ItemListNews = ({ item, deleteOneNews }) => {
  const {
    id,
    title,
    logoImgPath,
    MainThemes,
    SubThemes,
    NewsContent,
  } = item;
  const { editing } = useContext(EditMode);
  const { Role, AdminOfSubThemes, AdminOfMainThemes } =
    useContext(PermissionContext);

  const isValideSub = SubThemes.map((step) => {
    if (AdminOfSubThemes.some(({ id }) => id === step.id)) {
      return true;
    } else {
      return false;
    }
  });
  const isValideMain = MainThemes.map((step) => {
    if (AdminOfMainThemes.some(({ id }) => id === step.id)) {
      return true;
    } else {
      return false;
    }
  });

  return (
    <div className="admin-panel-edit-background">
      <Link to={`/news/${id}`}>
        <div
          className="item_container_news"
          style={{
            backgroundImage: `url("${logoImgPath}")`,
          }}
        >
          <div className="item_list_news"></div>
          <div className="item_list_news0">
            <p className="item_p_news">
              <p className="item_p_news_cut">{title}</p>
              {MainThemes.length > 0 && (
                <div className="news__item-thematics">
                  <p>
                    <ThemeIcon theme={MainThemes[0]} />
                    {MainThemes.length > 1 && `+${MainThemes.length - 1}`}
                  </p>
                  {MainThemes.length > 1 && (
                    <div>
                      {MainThemes.map((thematic, index) => {
                        return (
                          index > 0 && (
                            <ThemeIcon key={index} theme={thematic} />
                          )
                        );
                      })}
                    </div>
                  )}
                </div>
              )}
            </p>
            {/* <p className="item_p0_news">{id}</p> */}
          </div>
        </div>
      </Link>
      {(Role.length > 0 && Role.some(({ name }) => name === "ADMIN")) ||
        isValideSub.includes(true) ||
        isValideMain.includes(true) ? (
        editing && (
          <div className="links__edit_container">
            <Link to={"/admin/news/" + id}>
              <button className="links__edit_button">Modifier</button>
            </Link>
            <div
              className="links__edit_trash"
              onClick={() => {
                if (
                  window.confirm(
                    `Êtes vous sûr de vouloir suprimer la news :  ${title}?`
                  )
                ) {
                  deleteOneNews(id);
                }
              }}
            >
              <i className="icon-trash" />
            </div>
            <div className="published">
              {NewsContent &&
                NewsContent[0] &&
                NewsContent[0].Content &&
                NewsContent[0].Content.state === "DRAFT" ? (
                <p className="published_align">
                  <div
                    className="account_bullet-point bg-grey"
                    style={{ marginRight: "5px" }}
                  ></div>
                  Brouilon
                </p>
              ) : NewsContent[0].Content.state === "ARCHIVED" ? (
                <p className="published_align">
                  {console.log(NewsContent[0].Content.state)}
                  <div
                    className="account_bullet-point bg-red"
                    style={{ marginRight: "5px" }}
                  ></div>
                  Archivé
                </p>
              ) : (
                <p className="published_align">
                  <div
                    className="account_bullet-point bg-green"
                    style={{ marginRight: "5px" }}
                  ></div>
                  Publié
                </p>
              )}
            </div>
          </div>
        )
      ) : (
        <React.Fragment />
      )}
    </div>
  );
};

export default ItemListNews;
