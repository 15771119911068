import axios from "axios";
import getFormatedToken from "../helpers/getFormatedToken";

export async function sendContent(form) {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/send-email`,
      form,
      getFormatedToken()
    );

    const { data } = response.data;

    return data.file;
  } catch (error) {
    // TODO: Handle error properly
    // console.log(error);
  }
}
