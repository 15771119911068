import { useEffect, useState } from "react";
import CloseCircleIcon from "components/atoms/icons/close-circle";
import { searchOnMap } from "api/Map";

export default function SearchLocalisations({
  setLocalisations = () => {},
  localisations = [],
}) {
  const [list, setList] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);

  // if user click to select an item
  const handleSelect = (value) => {
    setSearch("");

    const exist = localisations.filter(
      (item) => item.display_name === value.display_name
    ).length;
    if (exist || !search.length) return;

    setLocalisations([...localisations, value]);
  };

  const getSearch = async (value) => {
    if (!value.length) return;
    setLoading(true);
    const adrTab = await searchOnMap(value.toLowerCase());
    setLoading(false);
    setList(adrTab);
  };

  // if user click to remove an item
  const handleRemove = (value) => {
    setLocalisations(localisations.filter((item) => item !== value));
  };

  useEffect(() => {
    const timeOutId = setTimeout(() => getSearch(search), 500);
    return () => clearTimeout(timeOutId);
  }, [search]);

  return (
    <div className="annuaire__filter-search">
      <input
        name="localisations"
        placeholder="Adresse professionnelle"
        onChange={(e) => setSearch(e.target.value)}
        value={search}
        className="annuaire__filter-search-input"
      />
      {/* list of results */}
      <ul className="annuaire__filter-search-list" id="localisations_list">
        {!loading ? (
          list.map((item, index) => (
            <li key={index} onClick={() => handleSelect(item)}>
              {item.display_name}
            </li>
          ))
        ) : (
          <li>Chargement...</li>
        )}
      </ul>

      {/* selected items */}
      <div className="annuaire__filter-search-selected">
        {localisations.map((item, index) => {
          return (
            <div key={index} style={{ backgroundColor: "#3CB2C5" }}>
              <button onClick={() => handleRemove(item)}>
                <CloseCircleIcon />
              </button>
              <span>{item.display_name.slice(0, 15)}...</span>
            </div>
          );
        })}
      </div>
    </div>
  );
}
