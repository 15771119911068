import axios from "axios";
import getFormatedToken from "../helpers/getFormatedToken";

export async function postShares(
  title,
  link,
  description,
  hashtag,
  file,
  isOfficial,
  fileType,
  branchId,
  mainThemeId,
  subThemeId
) {
  try {
    if (!title) {
      return;
    }
    const formData = new FormData();
    formData.append("title", title);
    formData.append("link", link);
    formData.append("description", description);
    if (hashtag) {
      let temp;
      if (hashtag) {
        temp = hashtag.split(",");
        if (temp[0] === "") {
          temp.splice(0, 1);
        }
      }
      formData.append("hashtags", temp);
    }
    formData.append("file", file);
    formData.append("isOfficial", isOfficial);
    formData.append("fileType", fileType);
    if (branchId && branchId.length > 0) {
      formData.append("branchId", branchId);
    }
    if (mainThemeId && mainThemeId.length > 0) {
      formData.append("mainThemeId", mainThemeId);
    }
    if (subThemeId && subThemeId.length > 0) {
      formData.append("subThemeId", subThemeId);
    }
    await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/api/files`,
      data: formData,
      ...getFormatedToken(true),
    });
  } catch (error) {
    // TODO: Handle error properly
    // console.log(error);
    return error;
  }
}

export async function updateShares(
  id,
  title,
  link,
  description,
  hashtag,
  file,
  isOfficial,
  fileType,
  branchId,
  mainThemeId,
  subThemeId
) {
  try {
    if (!title) {
      return;
    }
    const formData = new FormData();
    formData.append("title", title);
    formData.append("link", link);
    formData.append("description", description);
    if (hashtag) {
      let temp;
      if (hashtag) {
        temp = hashtag.split(",");
        if (temp[0] === "") {
          temp.splice(0, 1);
        }
      }
      formData.append("hashtags", temp);
    }
    formData.append("file", file);
    formData.append("isOfficial", isOfficial);
    if (fileType) {
      formData.append("fileType", fileType);
    } else {
      formData.append("fileType", 1);
    }
    if (branchId && branchId.length > 0) {
      formData.append("branchId", branchId);
    }
    if (mainThemeId && mainThemeId.length > 0) {
      formData.append("mainThemeId", mainThemeId);
    }
    if (subThemeId && subThemeId.length > 0) {
      formData.append("subThemeId", subThemeId);
    }

    await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/api/files/${id}`,
      data: formData,
      ...getFormatedToken(true),
    });
  } catch (error) {
    // TODO: Handle error properly
    // console.log(error);
    return error;
  }
}

export async function deleteShares(id) {
  if (!id) {
    return;
  }

  await axios({
    method: "DELETE",
    url: `${process.env.REACT_APP_API_URL}/api/files/${id}`,
    ...getFormatedToken(true),
  });
}
