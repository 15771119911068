import axios from "axios";
import getFormatedToken from "../../helpers/getFormatedToken";

export async function sendUnSubscribe(id) {
  const response = await axios.delete(
    `${process.env.REACT_APP_API_URL}/api/main-themes/${id}/unsubscribe`,
    getFormatedToken()
  );

  return response;
}
