import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import SubLink from "./SubLink/SubLink";
import { MenuItemBloc } from "./MenuItemBloc/MenuItemBloc";

const DropDownSubItem = ({
  item,
  isSideBarOpen,
  isParentFocused,
  openSideBar,
}) => {
  const { path, title, color, icon, subLinks } = item;
  const { pathname } = useLocation();
  const [showSubMenu, setShowSubMenu] = useState(false);

  const subThemeDropdown = useRef();

  useEffect(() => {
    pathname.includes(path) ? setShowSubMenu(true) : setShowSubMenu(false);
    pathname.includes(path + "/") && openSideBar();

    subThemeDropdown.current.addEventListener("mouseenter", () => {
      document.getElementById("navSideBar").classList.add("isHover");
    });
    subThemeDropdown.current.addEventListener("mouseleave", () => {
      document.getElementById("navSideBar").classList.remove("isHover");
    });
  }, [path, pathname, openSideBar]);

  return (
    <ul className={isParentFocused ? "d-block" : "d-none"}>
      <li
        ref={subThemeDropdown}
        className={`side-bar__dropdown ${color} ${
          pathname.includes(path) ? "active" : ""
        } ${pathname.includes(path + "/") && "open"}`}
        onClick={() => {
          setShowSubMenu(!showSubMenu);
        }}
      >
        <Link to={path}>
          <img src={"../../../assets/img/icons/" + icon} alt="icon" />
          <p>{title}</p>
        </Link>
        <MenuItemBloc
          title={title}
          items={subLinks}
          color={color}
          itemLink={path}
        />
      </li>
      {pathname.includes(path + "/") &&
        isSideBarOpen &&
        subLinks &&
        subLinks.map((link, index) => (
          <SubLink key={index} link={link} color={color} />
        ))}
    </ul>
  );
};

export default DropDownSubItem;
