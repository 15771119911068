import DefaultModal from "components/molecules/Modals/Default";
import joinClasses from "helpers/joinClasses";
import { sinceCalculator } from "helpers/TimeCalculator";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export const CommentFooter = ({
  comment,
  socket,
  messageLiked,
  setIsWritingMode = () => {},
  userId,
}) => {
  const [isCurrentLiked, setIsCurrentLiked] = useState(false);
  const [likesModal, setLikesModal] = useState(false);
  const [like, setLike] = useState(comment?.MessageLike?.length);
  useEffect(() => {
    setIsCurrentLiked(
      comment?.MessageLike?.some((like) => like.userId === userId)
    );
  }, [comment, userId]);

  // useEffect(() => {
  //   console.log("like", like);
  //   console.log("comment", comment?.MessageLike);
  // }, [like]);

  const updateLike = () => {
    socket.emit(
      "feed:message:like",
      { messageId: comment?.id },
      (err, data) => {
        if (err) {
          console.log(err);
          return;
        }
        console.log("data", data);
        setLike(data.likes);
      }
    );
  };

  const handleLikeClick = (e) => {
    e.preventDefault();
    setIsCurrentLiked(!isCurrentLiked);
    updateLike();
  };

  return (
    <div className="comment-footer-box">
      <span>
        <p
          onClick={(e) => handleLikeClick(e)}
          className={joinClasses(isCurrentLiked && "active")}
        >
          J'aime
        </p>
        <p onClick={() => setIsWritingMode(comment)}>Répondre</p>
      </span>
      <span>
        <span style={{ fontFamily: "var(--cera-regular)" }}>
          {sinceCalculator(comment.createdAt)}
        </span>
        <span
          className="comment-like-box"
          onClick={() => {
            if (!like || !comment?.MessageLike?.length) return;
            setLikesModal(true);
          }}
        >
          <img
            src={
              isCurrentLiked
                ? "/assets/icons/pouce_on.svg"
                : "/assets/icons/pouce_off.svg"
            }
            alt="pouce"
          />
          {Number(like) > 0 ? like : 0}
        </span>
      </span>
      <DefaultModal
        isOpen={likesModal}
        setIsOpen={setLikesModal}
        className="likes-list-modal"
        title={`Commentaire aimé par ${comment?.MessageLike?.length} personne${
          comment?.MessageLike?.length === 1 ? "" : "s"
        } :`}
      >
        <div>
          <ul className="">
            {comment?.MessageLike?.map((like) => (
              <li key={like.id}>
                <img
                  src={like.User.avatarPath || "/assets/icons/user-solid.svg"}
                  alt="avatar"
                />
                <Link to={`/users/${like.User.id}`}>
                  {like.User.firstname} {like.User.lastname}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </DefaultModal>
    </div>
  );
};
