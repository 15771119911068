import React from "react";
import "./theme.css";

export function ThemeIcon({ theme }) {
  return (
    <React.Fragment>
      {theme && (
        <img
          src={`/assets/img/icons/${theme.icon}`}
          alt="icon thematique"
          className="icon__theme"
        />
      )}
    </React.Fragment>
  );
}
