import React, { useEffect, useState, useContext } from "react";

//:: CSS import
import "./Comment.css";
import CommentForm from "./CommentForm";
import CommentContent from "./CommentContent";

//:: Context
import socketContext from "../../../contexts/socket";
import axios from "axios";
import getFormatedToken from "helpers/getFormatedToken";

export default function Comment({
  comment,
  avatarPath,
  isComment = false,
  messageLiked,
  userId,
  users = [],
}) {
  const [cmtTrigger, setCmtTrigger] = useState(false);
  const [commentContent, setCommentContent] = useState(null);
  const { messageId, commentsArray } = comment;
  const { socket } = useContext(socketContext);

  const sendComment = (content, file, messageId) => {
    socket.emit(
      "feed:create:comment",
      { content, messageId },
      async (err, data) => {
        if (err) return;
        if (file) {
          const formData = new FormData();
          formData.append("file", file);
          try {
            await axios({
              method: "POST",
              url: `${process.env.REACT_APP_API_URL}/api/messages/${data.id}/upload`,
              data: formData,
              ...getFormatedToken(true),
            });
            setTimeout(() => {
              window.location.reload();
            }, 1550);
          } catch (error) {
            console.log(error);
            return;
          }
        }
        if (data) {
          if (!commentContent) {
            setCommentContent([data]);
          } else {
            setCommentContent([data, ...commentContent]);
          }
        }
      }
    );
  };

  const handleDeleteComment = (commentId) => {
    socket.emit("feed:delete:message", { messageId: commentId }, (err) => {
      if (err) {
        console.log(err);
        return;
      }
      setCommentContent(commentContent.filter((cmt) => cmt.id !== commentId));
    });
  };

  const handleUpdateComment = (content, commentId) => {
    socket.emit(
      "feed:update:message",
      { messageId: commentId, content: content, userId: userId },
      (err) => {
        if (err) {
          console.log(err);
          return;
        }
        const updatedComment = commentContent.map((cmt) => {
          if (cmt.id === commentId) {
            cmt.content = content;
          }
          return cmt;
        });
        setCommentContent(updatedComment);
      }
    );
  };

  useEffect(() => {
    setCommentContent(commentsArray);
  }, [commentsArray]);

  return (
    <div className="comment-container">
      {commentContent &&
        commentContent.length > 0 &&
        commentContent.map((comment, index) => {
          if (!cmtTrigger && index > 4) return null;
          return (
            <div key={comment.id}>
              <CommentContent
                comment={comment}
                messageLiked={messageLiked}
                avatarPath={avatarPath}
                userId={userId}
                key={comment.id}
                onDeleteComment={handleDeleteComment}
                onUpdateComment={handleUpdateComment}
                users={users}
              />
            </div>
          );
        })}
      {commentContent?.length > 5 ? (
        <div className="comment-box">
          <button
            onClick={() => {
              setCmtTrigger(!cmtTrigger);
            }}
          >
            {cmtTrigger ? `Afficher moins` : `Afficher toute la discussion`}
          </button>
        </div>
      ) : null}

      {/* <div className="separator"></div> */}
      {isComment && (
        <CommentForm
          sender={{ sendFct: sendComment, messageId }}
          comment={false}
          avatarPath={avatarPath}
          isComment={false}
          users={users}
        />
      )}
    </div>
  );
}

/*  */
