import axios from "axios";
import getFormatedToken from "../../helpers/getFormatedToken";

export async function fetchIsSubscribed(id) {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/main-themes/${id}/is-subscribe`,
      getFormatedToken()
    );

    if (response.status === 400) {
      return false;
    } else {
      return true;
    }
  } catch (error) {
    return false;
  }
}
