export default function SearchIcon() {
  return (
    <svg
      id="search_icon"
      xmlns="http://www.w3.org/2000/svg"
      width="20.372"
      height="20.372"
      viewBox="0 0 20.372 20.372"
    >
      <path
        d="M8.833-.02a8.833,8.833,0,0,0,0,17.666,8.73,8.73,0,0,0,4.189-1.035,2.523,2.523,0,0,0,.328.328l2.524,2.524a2.574,2.574,0,1,0,3.634-3.634l-2.524-2.524a2.523,2.523,0,0,0-.4-.328,8.714,8.714,0,0,0,1.11-4.189A8.843,8.843,0,0,0,8.858-.045Zm0,2.524a6.279,6.279,0,0,1,6.309,6.309,6.328,6.328,0,0,1-1.666,4.341l-.076.076a2.524,2.524,0,0,0-.328.328,6.315,6.315,0,0,1-4.265,1.59,6.309,6.309,0,1,1,0-12.618Z"
        transform="translate(0 0.045)"
        fill="currentColor"
      />
    </svg>
  );
}
