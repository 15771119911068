/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from "react";
import { EditMode } from "../../contexts/editmode";
import moment from "moment";
import "./style.css";

//components
import Items from "../../components/Events/Items";
import Item from "../../components/Events/Item";
import Search from "../../components/Search/SearchFilter";
import FilterForm from "../../components/Filter/FilterForm";

// api
import { fetchOneEvent } from "../../api/Events/OneEvent";
import { fetchAllEvents } from "../../api/Events/Events";
import { fetchAllSessions } from "../../api/Events/Sessions";
import { deleteEvent } from "../../api/Events/Events";

// molecules
import CustomCalendar from "../../components/molecules/Calendar";

export default function Events() {
  const [sessions, setSessions] = useState([]);
  const [OneEvent, setOneEvent] = useState([]);

  const [events, setEvents] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState(false);
  const [reset, setReset] = useState(false);
  const [filters, setFilters] = useState([]);
  const [searchFilter, setSearchFilter] = useState("");

  const [dateSelected, setDateSelected] = useState("");

  const { editing } = useContext(EditMode);

  async function getAllSessions() {
    setSessions(await fetchAllSessions());
    setOneEvent(await fetchOneEvent());
  }

  async function getAllEvents(filter) {
    setEvents(await fetchAllEvents(filter));
  }

  useEffect(() => {
    getAllSessions();
  }, []);

  useEffect(() => {
    getAllEvents(filters);
  }, [filters]);

  useEffect(() => {
    /* les hashtags arrivent sous la forme d'un tableau avec qu'une itération dans laquel y a les # séparés d'une virgule */
    setFilteredEvents(
      events &&
      events.filter(
        ({ title, hashtags }) =>
          title.toLowerCase().includes(searchFilter) ||
          (hashtags.length > 0 &&
            hashtags[0]
              .toLowerCase()
              .replace(/,/g, " ")
              .includes(searchFilter))
      )
    );
  }, [events, searchFilter]);

  useEffect(() => {
    if (reset) {
      setFilteredEvents(events);
      setReset(false);
    }
  }, [reset]);
  useEffect(() => {
    setFilteredEvents(
      events &&
      events.filter(({ EventSession }) => {
        let isOk = false;
        EventSession.forEach((dates) => {
          if (
            moment(dateSelected).format("yyyy-MM-DD") ===
            moment(dates.startDate).format("yyyy-MM-DD")
          ) {
            isOk = true;
          }
        });
        return isOk;
      })
    );
  }, [dateSelected]);

  function deleteOneEvent(id) {
    setEvents((events) => {
      return events.filter((events) => events.id !== id);
    });

    deleteEvent(id);
  }

  return (
    <React.Fragment>
      <div className="events__container">
        <header>
          <div className="events__first-event">
            {OneEvent && Object.keys(OneEvent).length > 0 && (
              <Item key={"index"} event={OneEvent} isFirst={true} />
            )}
          </div>
          <div className="events__calendar">
            <CustomCalendar
              dates={sessions}
              setDate={setDateSelected}
              setReset={setReset}
            />
          </div>
        </header>
        <div className="events__all-events">
          <div>
            <h2>Événements à venir</h2>
            <div className="divquipermetdemettrelesdeuxboutonsurlamemeligne">
              <FilterForm
                filtersCallback={setFilters}
                published={editing}
                category={false}
                publishedDate={false}
                btnOpen={true}
              />
              <Search
                onChange={(filter) => setSearchFilter(filter.toLowerCase())}
                className={"search__reduced"}
              />
            </div>
          </div>
          <Items
            events={filteredEvents || []}
            deleteOneEvent={deleteOneEvent}
          />
        </div>
        {editing && (
          <div className="events__all-events">
            <div>
              <h2>Événements archivés</h2>
            </div>
            <Items
              events={filteredEvents || []}
              deleteOneEvent={deleteOneEvent}
              onlyExceeded={true}
            />
          </div>
        )}
      </div>
    </React.Fragment>
  );
}
