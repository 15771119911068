/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";

import { fetchAllThemes } from "../../../api/Thematics/Themes";
import { updateTheme } from "../../../api/Thematics/Themes";
import PermissionContext from "../../../contexts/permission";

// components
import { Card } from "../../../components/molecules/AdminPanel/";

import ErrorDiv from "../../../components/molecules/ErrorsMessages/errorForm";

import "./thematicsForm.css";

export default function Thematics({ getId, setisvalid = null }) {
  const [themesInfo, setThemesInfo] = useState([]);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [text, setText] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [dataRHcontent, setDataRHcontent] = useState(null);
  const [themeInfo, setThemeInfo] = useState({
    n1: { label: "", value: "" },
    n2: { label: "", value: "" },
    n3: { label: "", value: "" },
  });
  const { theme } = useParams();
  const { Role, AdminOfMainThemes } = useContext(PermissionContext);

  async function getAllThemes() {
    setThemesInfo(await fetchAllThemes());
  }
  useEffect(() => {
    getAllThemes();
  }, [theme]);

  useEffect(() => {
    if (themeInfo) {
      getId(themeInfo.id);
    }
  }, [themeInfo.id]);

  useEffect(() => {
    const themeParsed = theme.replace(/_/g, " ");
    let themeWhereName = {};
    if (themesInfo.length) {
      themeWhereName = themesInfo.filter(
        (item) =>
          themeParsed ===
          item.name
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
      );
      if (themeWhereName.length === 0) {
        return window.alert("page introuvable");
      }

      setThemeInfo(...themeWhereName);
    }
  }, [themesInfo]);

  function updateThemeState(key, value) {
    setThemeInfo((themeInfo) => ({
      ...themeInfo,
      [key]: value,
    }));
  }

  const submitTheme = async () => {
    const { id, n1, n2, n3 } = themeInfo;
    const res = await updateTheme({
      id,
      n1,
      n2,
      n3,
      description: text,
      content: dataRHcontent,
    });
    if (!res) {
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
      }, 1500);
      return;
    }
    setError(true);
  };

  const isValid = AdminOfMainThemes.map((step) => {
    if (step.id === themeInfo.id) {
      return true;
    } else return false;
  });

  const isValidRole = Role.map((step) => {
    if (step.name === "ADMIN") {
      return true;
    } else return false;
  });

  if (!isValidRole.includes(true) && !isValid.includes(true)) {
    setisvalid(false);
    return (
      <div style={{ paddingTop: "10vh" }}>
        <Card>
          <div
            style={{
              fontFamily: "var(--cera-regular)",
              margin: "60px 20px",
              textAlign: "center",
            }}
          >
            Vous n'avez pas accès à la modification de cette thèmatique
          </div>
        </Card>
      </div>
    );
  }

  return (
    <div className="before-card">
      <h1
        style={{ color: "var(--" + themeInfo.color + "-color)" }}
        className="title"
      >
        {themeInfo && themeInfo.name}
      </h1>
      <Card>
        <div className="admin-them-form-body">
          <h1 className="title">Modifier le Chapeau</h1>
          <textarea
            className="admin-input"
            type="text"
            placeholder="Votre text"
            onChange={(e) => setText(e.target.value)}
            defaultValue={themeInfo.description}
            required
          />
          <h1 className="title">Modifier les chiffres clés</h1>
          <div className="admin-them-form-container">
            <div>
              <input
                type="text"
                className="admin-input"
                value={themeInfo.n1.value}
                placeholder="ex: 30"
                onChange={({ target }) =>
                  updateThemeState("n1", {
                    label: themeInfo.n1.label,
                    value: target.value,
                  })
                }
              />
              <input
                type="text"
                className="admin-input"
                value={themeInfo.n1.label}
                placeholder="ex: Titre"
                onChange={({ target }) =>
                  updateThemeState("n1", {
                    label: target.value,
                    value: themeInfo.n1.value,
                  })
                }
              />
            </div>
            <div>
              <input
                type="text"
                className="admin-input"
                value={themeInfo.n2.value}
                placeholder="ex: 30"
                onChange={({ target }) =>
                  updateThemeState("n2", {
                    label: themeInfo.n2.label,
                    value: target.value,
                  })
                }
              />
              <input
                type="text"
                className="admin-input"
                value={themeInfo.n2.label}
                placeholder="ex: Titre"
                onChange={({ target }) =>
                  updateThemeState("n2", {
                    label: target.value,
                    value: themeInfo.n2.value,
                  })
                }
              />
            </div>
            <div>
              <input
                type="text"
                className="admin-input"
                value={themeInfo.n3.value}
                placeholder="ex: 30"
                onChange={({ target }) =>
                  updateThemeState("n3", {
                    label: themeInfo.n3.label,
                    value: target.value,
                  })
                }
              />
              <input
                type="text"
                className="admin-input"
                value={themeInfo.n3.label}
                placeholder="ex: Titre"
                onChange={({ target }) =>
                  updateThemeState("n3", {
                    label: target.value,
                    value: themeInfo.n3.value,
                  })
                }
              />
            </div>
          </div>
          {/* {themeInfo && themeInfo.id == 5 && (
            <div style={{ marginTop: "75px" }}>
              <CKEditor
                noSave={true}
                placeholder={
                  themeInfo && themeInfo.Content
                    ? themeInfo.Content.html
                    : "Commencer l'édition ..."
                }
                ckeditorCallback={(content) => setDataRHcontent(content)}
              />
            </div>
          )} */}
          <button
            className="save-btn"
            onClick={(e) => {
              e.preventDefault();
              submitTheme();
            }}
          >
            Enregistrer
          </button>
        </div>
        {error && <ErrorDiv message="Erreur: un problème est survenu..." />}
        {success && (
          <div
            style={{
              backgroundColor: "var(--green-color)",
              color: "white",
              padding: 10,
              fontFamily: "var(--cera-bold)",
              textAlign: "center",
              marginTop: 10,
            }}
          >
            Changements enregistrés avec succès !
          </div>
        )}
      </Card>
    </div>
  );
}
