export default function ThumbnailIcon() {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 13 14">
        <g id="Groupe_1871" data-name="Groupe 1871" transform="translate(-1127.5 -92.5)">
            <rect id="Rectangle_1447" data-name="Rectangle 1447" width="12" height="13" rx="2" transform="translate(1128 93)" fill="none" stroke="currentColor" stroke-width="1" />
            <g id="Ellipse_202" data-name="Ellipse 202" transform="translate(1131 94)" fill="none" stroke="currentColor" stroke-width="1">
                <circle cx="3" cy="3" r="3" stroke="none" />
                <circle cx="3" cy="3" r="2.5" fill="none" />
            </g>
            <line id="Ligne_248" data-name="Ligne 248" x2="6" transform="translate(1131 101.5)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-width="1" />
            <line id="Ligne_249" data-name="Ligne 249" x2="4" transform="translate(1132 103.5)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-width="1" />
        </g>
    </svg>
    )
}