import React, { useEffect, useState, useContext } from "react";

import CommentForm from "./CommentForm";
import { CommentFooter } from "./CommentFooter";
//:: Context
import socketContext from "../../../contexts/socket";
import { UnderComment } from "./UnderComment";
import OptionsIcon from "components/atoms/icons/options";
import { PencilIcon } from "components/atoms/icons/pencil";
import { TrashIcon } from "components/atoms/icons/trash";
import DefaultModal from "components/molecules/Modals/Default";
import { Link } from "react-router-dom";
import axios from "axios";
import getFormatedToken from "helpers/getFormatedToken";

import MentionsForm from "../MentionsForm";
import FormatChatMessage from "helpers/FormatChatMessage";
import Attachment from "../Attachment";

export default function CommentContent({
  comment,
  messageLiked,
  avatarPath,
  userId,
  onDeleteComment,
  onUpdateComment,
  users = [],
}) {
  const [commentContent, setCommentContent] = useState(null);
  const [underComment, setUnderComment] = useState(null);
  const [isCommenting, setIsCommenting] = useState(false);
  const [isOnUpdate, setOnUpdate] = useState(false);
  const [contentToUpdate, setContentToUpdate] = useState(comment.content);
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const { socket } = useContext(socketContext);

  useEffect(() => {
    setCommentContent(comment);
    setUnderComment(comment.Comments);
    if (comment.comments) {
      setUnderComment(comment.comments.reverse());
    }
  }, [comment]);

  const sendUnderComment = (content, file, messageId) => {
    setIsCommenting(false);
    socket.emit(
      "feed:create:comment",
      { content, messageId },
      async (err, data) => {
        if (err) console.log(err);
        if (file) {
          const formData = new FormData();
          formData.append("file", file);
          try {
            await axios({
              method: "POST",
              url: `${process.env.REACT_APP_API_URL}/api/messages/${data.id}/upload`,
              data: formData,
              ...getFormatedToken(true),
            });
            setTimeout(() => {
              window.location.reload();
            }, 1550);
          } catch (error) {
            console.log(error);
            return;
          }
        }
        if (data) {
          if (!underComment) {
            setUnderComment([data]);
          } else {
            setUnderComment([...underComment, data]);
          }
        }
      }
    );
  };

  const handleComment = (value) => {
    setIsCommenting(value);
    console.log("value", value);
    setTimeout(async () => {
      const inputMessage = document.getElementById(`comment-form-${value?.id}`);
      if (!inputMessage) return;
      inputMessage.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
      inputMessage.focus({ preventScroll: true });
    }, 100);
  };

  const handleUpdateComment = (messageId) => {
    if (contentToUpdate === comment.content || contentToUpdate === "") {
      setOnUpdate(false);
      return;
    }
    onUpdateComment(contentToUpdate, messageId);
    setOnUpdate(false);
  };

  const handleDeleteComment = (commentId) => {
    onDeleteComment(commentId);
    setConfirmDeleteModal(false);
  };

  const handleDeleteUnderComment = (commentId) => {
    socket.emit("feed:delete:message", { messageId: commentId }, (err) => {
      if (err) {
        console.log(err);
        return;
      }
      setUnderComment(underComment.filter((c) => c.id !== commentId));
    });
  };

  const handleUpdateUnderComment = (content, commentId) => {
    socket.emit(
      "feed:update:message",
      { messageId: commentId, content: content, userId: userId },
      (err) => {
        if (err) {
          console.log(err);
          return;
        }
        const updatedComment = underComment.map((c) => {
          if (c.id === commentId) {
            c.content = content;
          }
          return c;
        });
        setUnderComment(updatedComment);
      }
    );
  };
  const DisplayFile = ({ files }) => {
    if (files?.length) {
      return files.map((file) => (
        <div className="comment-file">
          <Attachment filePath={file.blobPath} isInComment={true} />
        </div>
      ));
    } else return <></>;
  };

  return (
    <>
      {commentContent && (
        <div>
          <div className="feed-input-box">
            <div
              className="comment-img"
              style={{
                backgroundImage: `url(${comment && comment.author && comment.author.avatarPath
                  ? comment.author.avatarPath
                  : "/assets/icons/user-solid.svg"
                  })`,
              }}
            />
            {!isOnUpdate ? (
              <div className="container-comment-and-undercomment">
                <div className="content-comment">
                  <Link className="" to={`/users/${commentContent.author.id}`}>
                    <h4>
                      {commentContent.author + " " + commentContent.author
                        ? commentContent.author.firstname +
                        " " +
                        commentContent.author.lastname
                        : "__username"}
                    </h4>
                  </Link>

                  <FormatChatMessage text={commentContent.content} />
                  <DisplayFile files={commentContent.Attachments} />
                </div>

                <CommentFooter
                  comment={commentContent}
                  socket={socket}
                  messageLiked={messageLiked}
                  setIsWritingMode={(e) => handleComment(e)}
                  userId={userId}
                />
              </div>
            ) : (
              <div className="container-comment-and-undercomment">
                <MentionsForm
                  className="comment-input"
                  placeholder="Ecrivez un commentaire..."
                  onChange={(e) => setContentToUpdate(e.target.value)}
                  value={contentToUpdate}
                  users={users}
                />
                <div className="actions-btn">
                  <button onClick={() => setOnUpdate(false)} className="cancel">
                    Annuler
                  </button>
                  <button
                    onClick={() => {
                      handleUpdateComment(commentContent.id);
                    }}
                  >
                    Enregistrer
                  </button>
                </div>
              </div>
            )}
            {userId === comment.author.id && (
              <div className="message-options">
                <button>
                  <OptionsIcon />
                </button>
                {isOnUpdate ? (
                  <ul>
                    <li onClick={() => setOnUpdate(false)}>Annuler</li>
                  </ul>
                ) : (
                  <ul>
                    <li onClick={() => setOnUpdate(true)}>
                      <PencilIcon /> Modifier
                    </li>
                    <li onClick={() => setConfirmDeleteModal(true)}>
                      <TrashIcon />
                      Supprimer
                    </li>
                  </ul>
                )}
              </div>
            )}
          </div>
          {isCommenting && (
            <div className="under-comment-box">
              <CommentForm
                sender={{
                  sendFct: sendUnderComment,
                  messageId: isCommenting?.id,
                }}
                avatarPath={avatarPath}
                isComment={true}
                users={users}
              />
            </div>
          )}
          {/* Display undercomments */}
          <UnderComment
            messageLiked={messageLiked}
            underComment={underComment}
            socket={socket}
            avatarPath={avatarPath}
            userId={userId}
            sendUnderComment={sendUnderComment}
            onDeleteComment={handleDeleteUnderComment}
            onUpdateComment={handleUpdateUnderComment}
            users={users}
          />
          <DefaultModal
            isOpen={confirmDeleteModal}
            setIsOpen={setConfirmDeleteModal}
            title="Supprimer votre commentaire ?"
          >
            <p className="text-center">
              Êtes-vous sûr de vouloir supprimer ce commentaire ? Cette action
              est irréversible.
            </p>
            <div className="d-flex gap-10 mt-20 justify-content-center">
              <button
                className="btn text-main"
                onClick={() => setConfirmDeleteModal(false)}
              >
                Annuler
              </button>
              <button
                className="btn bg-main"
                onClick={() => handleDeleteComment(comment.id)}
              >
                Supprimer
              </button>
            </div>
          </DefaultModal>
        </div>
      )}
    </>
  );
}
