export default function NewsletterIcon() {
    return (
        <svg id="newsletter" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 21.504 21.504">
            <path id="Tracé_574" data-name="Tracé 574" d="M20.746,6.728,19,5.276v-4.2A1.075,1.075,0,0,0,17.92,0H3.584A1.075,1.075,0,0,0,2.509,1.075v4.2L.758,6.728A2.1,2.1,0,0,0,0,8.344V20.071A1.434,1.434,0,0,0,1.434,21.5H20.071A1.434,1.434,0,0,0,21.5,20.071V8.344A2.1,2.1,0,0,0,20.746,6.728Zm.042,1.616V20.071a.71.71,0,0,1-.054.27l-7.252-6.007,7.3-6.04C20.782,8.31,20.787,8.327,20.787,8.344Zm-.5-1.064a1.364,1.364,0,0,1,.251.283L19,8.841V6.208ZM3.584.717H17.92a.358.358,0,0,1,.358.358V9.434L12.92,13.868l-1.162-.962a1.583,1.583,0,0,0-2.012,0l-1.161.962L3.226,9.434V1.075A.358.358,0,0,1,3.584.717ZM.771,20.34a.714.714,0,0,1-.054-.27V8.344c0-.017.005-.034.006-.051l7.3,6.04ZM2.509,6.208V8.841L.965,7.562a1.364,1.364,0,0,1,.251-.283ZM1.434,20.787a.664.664,0,0,1-.072-.007L10.2,13.459a.862.862,0,0,1,1.1,0l8.841,7.321a.663.663,0,0,1-.072.007Z" transform="translate(0)" fill="currentColor" />
            <path id="Tracé_575" data-name="Tracé 575" d="M11.358,12.584a.358.358,0,0,0,.358-.358V10.434l1.505,2.007a.358.358,0,0,0,.645-.215V9.358a.358.358,0,0,0-.717,0V11.15L11.645,9.143A.358.358,0,0,0,11,9.358v2.867A.358.358,0,0,0,11.358,12.584Z" transform="translate(-7.058 -5.774)" fill="currentColor" />
            <path id="Tracé_576" data-name="Tracé 576" d="M23.509,9.717a.358.358,0,1,0,0-.717h-2.15A.358.358,0,0,0,21,9.358v2.867a.358.358,0,0,0,.358.358h2.15a.358.358,0,1,0,0-.717H21.717V11.15h1.075a.358.358,0,0,0,0-.717H21.717V9.717Z" transform="translate(-13.474 -5.774)" fill="currentColor" />
            <path id="Tracé_577" data-name="Tracé 577" d="M31.358,9A.358.358,0,0,0,31,9.358v2.867a.358.358,0,0,0,.666.185l.768-1.28.768,1.28a.358.358,0,0,0,.307.174.364.364,0,0,0,.1-.013.358.358,0,0,0,.263-.346V9.358a.358.358,0,1,0-.717,0v1.573l-.41-.683a.373.373,0,0,0-.614,0l-.41.683V9.358A.358.358,0,0,0,31.358,9Z" transform="translate(-19.889 -5.774)" fill="currentColor" />
            <path id="Tracé_578" data-name="Tracé 578" d="M42.434,9.714a1.246,1.246,0,0,1,.824.257.358.358,0,0,0,.5-.51A1.9,1.9,0,0,0,42.434,9C41.63,9,41,9.469,41,10.072c0,.72.776.917,1.342,1.06.361.091.809.205.809.374,0,.146-.279.358-.717.358a1.247,1.247,0,0,1-.822-.253.358.358,0,1,0-.507.507,1.9,1.9,0,0,0,1.329.463c.8,0,1.434-.472,1.434-1.075,0-.726-.78-.924-1.35-1.068-.338-.086-.8-.2-.8-.365C41.717,9.926,42,9.714,42.434,9.714Z" transform="translate(-26.305 -5.771)" fill="currentColor" />
            <path id="Tracé_579" data-name="Tracé 579" d="M11.358,5.717h12.9a.358.358,0,1,0,0-.717h-12.9a.358.358,0,1,0,0,.717Z" transform="translate(-7.058 -3.208)" fill="currentColor" />
            <path id="Tracé_580" data-name="Tracé 580" d="M11.358,21.717h12.9a.358.358,0,1,0,0-.717h-12.9a.358.358,0,1,0,0,.717Z" transform="translate(-7.058 -13.474)" fill="currentColor" />
            <path id="Tracé_581" data-name="Tracé 581" d="M25.111,26H14.358a.358.358,0,0,0,0,.717H25.111a.358.358,0,1,0,0-.717Z" transform="translate(-8.982 -16.682)" fill="currentColor" />
            <path id="Tracé_582" data-name="Tracé 582" d="M27.093,31H21.358a.358.358,0,1,0,0,.717h5.734a.358.358,0,0,0,0-.717Z" transform="translate(-13.474 -19.889)" fill="currentColor" />
        </svg>

    )
}