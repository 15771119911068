import axios from "axios";
import getFormatedToken from "../../helpers/getFormatedToken";
import filtersCompose from "../../helpers/FiltersCompose";

export async function fetchAllQuestions(filters) {
  let response;
  if (!filters) {
    response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/questions`,
      getFormatedToken()
    );
  } else {
    response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/questions${filtersCompose(
        filters
      )}`,
      getFormatedToken()
    );
  }
  const { data } = response.data;
  return data.questions;
}

export async function createNewQuestion(
  entitled,
  answer,
  mainThemeId,
  subThemeId
) {
  try {
    let questionData = {};
    questionData["entitled"] = entitled;
    questionData["answer"] = answer;
    if (mainThemeId) {
      questionData["mainThemesId"] = mainThemeId;
    }
    if (subThemeId) {
      questionData["subThemesId"] = subThemeId;
    }
    await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/api/questions`,
      data: JSON.stringify(questionData),
      ...getFormatedToken(false, true),
    });
  } catch (error) {
    // TODO: Handle error properly
    // console.log(error);
    return error;
  }
}

export async function editQuestion(
  id,
  entitled,
  answer
) {
  try {
    let questionData = {};
    questionData["entitled"] = entitled;
    questionData["answer"] = answer;
    await axios({
      method: "PUT",
      url: `${process.env.REACT_APP_API_URL}/api/questions/${id}`,
      data: questionData,
      ...getFormatedToken(false, true),
    });
  } catch (error) {
    // TODO: Handle error properly
    // console.log(error);
    return error;
  }
}

export async function deleteQuestion(id) {
  if (!id) {
    return;
  }
  await axios({
    method: "DELETE",
    url: `${process.env.REACT_APP_API_URL}/api/questions/${id}`,
    ...getFormatedToken(true),
  });
}
