/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import moment from "moment";
import "moment/locale/fr";
import "./Calendar.css";
moment.locale("fr");

export default function CustomCalendar({ setDate, dates, setReset }) {
  const [value, onChange] = useState(new Date());
  const [datesCSS, setDatesCSS] = useState(false);
  function createCssDates(dates) {
    let datesArray = [];

    for (const key in dates) {
      if (
        moment(dates[key].startDate).format("D MMMM YYYY") ===
        moment(value).format("D MMMM YYYY")
      ) {
        datesArray.push(
          `.react-calendar__month-view__days__day[class*="react-calendar__tile--active"] >  abbr[aria-label="${moment(
            value
          ).format("D MMMM YYYY")}"] {
              background-image: url("/assets/img/bg-calendar-no-opacity.svg");
              color: #fff;
        }`
        );
      }
      datesArray.push(
        `abbr[aria-label="${moment(dates[key].startDate).format(
          "D MMMM YYYY"
        )}"] {background-image: url("/assets/img/bg-calendar-opacity.svg");color: var(--main-color);}`
      );
    }
    setDatesCSS(datesArray);
  }

  useEffect(() => {
    createCssDates(dates);
  }, [dates, value]);

  return (
    <React.Fragment>
      <style>{datesCSS && datesCSS.map((labelcss) => labelcss)}</style>
      <button
        onClick={() => {
          onChange(new Date());
          setReset(true);
        }}
        className="reset-calendar"
      >
        Réinitialiser
      </button>
      <div>
        <Calendar
          onChange={(value) => {
            setDate(value);
            onChange(value);
          }}
          value={value}
          locale="fr-FR"
          showNeighboringMonth={false}
        />
      </div>
    </React.Fragment>
  );
}
