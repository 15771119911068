/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";

import FilterForm from "../../../components/Filter/FilterForm";
import { Card } from "../../../components/molecules/AdminPanel/index";
import CKEditor from "../../../components/molecules/CKEditor/CKEditorBasic";
import ErrorDiv from "../../../components/molecules/ErrorsMessages/errorForm";
import { FiltersContext } from "../../../contexts/filters";

import {
  createNewEvent,
  updateOneEvent,
  deleteOneSession,
} from "../../../api/Events/Events";

import moment from "moment";
import "./EventsForm.css";
import { Link, useHistory, useParams } from "react-router-dom";
import LoadingBar from "../../../components/molecules/Loaders/Bar";
import CropImage from "../../../components/molecules/CropImage";

export default function LinksForm({ onClick, eventProps }) {
  const [sessions, setSessions] = useState([]);
  const [dateStart, setDateStart] = useState(moment().format("yyyy-MM-DD"));
  const [dateEnd, setDateEnd] = useState(moment().format("yyyy-MM-DD"));
  const [timeStart, setTimeStart] = useState("00:00");
  const [timeEnd, setTimeEnd] = useState("00:00");
  const [title, setTitle] = useState(null);
  const [text, setText] = useState(null);
  const [hashtag, setHashtag] = useState(null);
  const [file, setFile] = useState(null);
  const [fileBlob, setFileBlob] = useState(null);
  const [banner, setBanner] = useState(null);
  const [link, setLink] = useState(null);
  const [bannerBlob, setBannerBlob] = useState(null);
  const [isFirst, setIsFirst] = useState(false);
  const [next, setNext] = useState(false);
  const [filter, setFilter] = useState([]);
  const [content, setContent] = useState();
  const [errorF, setErrorF] = useState(false);
  const [sending, setSending] = useState(false);
  const [success, setSuccess] = useState(false);

  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    const zob = document.querySelector(".reset_filter_title");
    const zob2 = document.querySelector(".calendar_selection");
    if (zob) zob.style = "display: none";
    if (zob2) zob2.style = "display: none";
    if (eventProps) {
      setTitle(eventProps.title);
      setText(eventProps.text);
      setLink(eventProps.link);
      if (eventProps.hashtags) {
        let hashtagString = "";
        eventProps.hashtags.forEach((elem) => {
          if (hashtagString.length === 0) {
            hashtagString = elem;
          } else {
            hashtagString = hashtagString + "," + elem;
          }
        });
        setHashtag(hashtagString);
      }
      setIsFirst(eventProps.isFirst);
      let sessionsPropsArray = [];
      eventProps.EventSession.forEach((elem) => {
        let sessionsProps = {};
        const Dtemp = moment(elem.startDate).format("YYYY-MM-DD");
        const DEtemp = moment(elem.endDate).format("YYYY-MM-DD");
        const Htemp = moment(elem.startDate).format("HH:MM");
        const Htemp_ = moment(elem.endDate).format("HH:MM");
        sessionsProps.id = elem.id;
        sessionsProps.date = Dtemp;
        sessionsProps.dateEnd = DEtemp;
        sessionsProps.timeStart = Htemp;
        sessionsProps.timeEnd = Htemp_;
        sessionsPropsArray.length > 0
          ? (sessionsPropsArray = [...sessionsPropsArray, sessionsProps])
          : (sessionsPropsArray = [sessionsProps]);
      });

      if (sessionsPropsArray.length > 0) {
        setSessions(sessionsPropsArray);
      }
      if (eventProps.Content && eventProps.Content.html) {
        setContent(eventProps.Content.html);
      }
    }
  }, [eventProps]);

  const createEvent = async (e, state = true) => {
    e.preventDefault();
    let published = state;
    if (!title) {
      setErrorF("Veuillez ajouter un titre");
      return;
    }
    if (!content || content === "<i>Commencer l'édition...</i>") {
      setErrorF("Veuillez ajouter un contenu");
      return;
    }
    if (!text) {
      setErrorF("Veuillez ajouter un text");
      return;
    }
    if (sessions.length < 1) {
      setErrorF("Veuillez ajouter une session");
      return;
    }
    for (const key in sessions) {
      if (
        (sessions[key].dateStart && sessions[key].timeStart) ===
        (sessions[key].dateEnd && sessions[key].timeEnd)
      ) {
        setErrorF("Veuillez saisir au moins deux horaires différentes");
        return;
      }
    }
    let filt = conversionDuTableauAvantLenvoie();
    if (!filt) {
      filt = {};
    }
    if (id) {
      const res = await updateOneEvent(
        id,
        title,
        text,
        hashtag,
        isFirst,
        filt.branches,
        filt.mainThemes,
        filt.subThemes,
        file,
        banner,
        sessions,
        content,
        published,
        link
      );
      if (!res) {
        setSuccess(true);
        setTimeout(() => {
          history.push("/admin/events");
        }, 800);
        return;
      }
      setErrorF(
        "ERREUR, un problème est survenue lors du traitement de votre demande."
      );
    } else {
      const res = await createNewEvent(
        title,
        text,
        hashtag,
        isFirst,
        filt.branches,
        filt.mainThemes,
        filt.subThemes,
        file,
        banner,
        sessions,
        content,
        published,
        link
      );
      if (!res) {
        setSuccess(true);
        setTimeout(() => {
          onClick();
        }, 800);
        return;
      }
      setErrorF(
        "ERREUR, un problème est survenue lors du traitement de votre demande."
      );
    }
  };

  const conversionDuTableauAvantLenvoie = () => {
    let mainThemes = [];
    let subThemes = [];
    let branches = [];

    filter.forEach((row, index) => {
      if (row.includes("main_theme")) {
        mainThemes.push(row.split(":")[1]);
      } else if (row.includes("sub_theme")) {
        subThemes.push(row.split(":")[1]);
      } else if (row.includes("branch")) {
        branches.push(row.split(":")[1]);
      }
    });
    return { mainThemes, subThemes, branches };
  };

  const addSession = (e) => {
    e.preventDefault();
    if (dateStart && dateEnd) {
      let session = {};
      session.dateStart = dateStart;
      session.dateEnd = dateEnd;
      session.timeStart = timeStart;
      session.timeEnd = timeEnd;
      if (sessions.length > 0) {
        setSessions([...sessions, session]);
      } else {
        setSessions([session]);
      }
      setDateStart(moment().format("yyyy-MM-DD"));
      setDateEnd(moment().format("yyyy-MM-DD"));
      setTimeStart("00:00");
      setTimeEnd("00:00");
    }
  };

  /*   useEffect(() => {
    console.log("session", sessions);
  }, [sessions]); */

  const deleteSession = async (e, sessionIndex, sessionId) => {
    e.preventDefault();

    // ~ Delete Session from State
    setSessions((sessions) => {
      return sessions.filter((session, index) => {
        return session.id !== sessionId || index !== sessionIndex;
      });
    });

    // ~ Delete Session from API
    if (!sessionId) {
      return;
    }

    await deleteOneSession(eventProps.id, sessionId);
  };

  if (!next) {
    return (
      <div style={{ paddingTop: "70px" }}>
        <Card>
          <Link to="/admin/events">
            <a className="back" onClick={onClick}>
              <i className="icon-short-arrow" style={{ fontSize: "9px" }} />
              Retour
            </a>
          </Link>
          <h1 className="title">Étape 1/2</h1>
          <form className="admin-form">
            <div className="admin-input-box">
              <input
                className="admin-input"
                type="text"
                placeholder="Titre de l'event*"
                onChange={(e) => setTitle(e.target.value)}
                value={title && title}
                required
              />
              <textarea
                className="admin-input"
                type="text"
                placeholder="Description de l'event*"
                onChange={(e) => {
                  if (e.target.value.length <= 100) {
                    setText(e.target.value);
                  }
                }}
                value={text && text}
                required
              />
              <p
                align="right"
                style={{
                  color: text && text.length >= 100 ? "red" : "grey",
                  width: "90%",
                  fontSize: "12px",
                  marginBottom: "5px",
                }}
              >
                {text && text.length}/100
              </p>
              <input
                className="admin-input"
                type="text"
                placeholder="#Hashtag"
                onChange={(e) => {
                  const temp = e.target.value.replace(" ", ",");
                  setHashtag(temp);
                }}
                value={hashtag && hashtag}
              />
              <label className="admin-input">
                <input
                  type="checkbox"
                  onChange={() => setIsFirst(!isFirst)}
                  checked={isFirst ? "true" : ""}
                />
                Epingler
              </label>
            </div>
            <div>
              <label
                className="upload-style"
                htmlFor="upload-file"
                style={{
                  backgroundImage: `linear-gradient(0deg, #161b4b2b 0%, #161b4b2b 100%), url("${fileBlob
                    ? fileBlob
                    : eventProps
                      ? eventProps.logoImgPath
                      : ""
                    }")`,
                }}
              >
                <i className="icon-cloud" style={{ fontSize: "2rem" }} />
                <p className="btn btn-small bg-main">
                  {file || (eventProps && eventProps.logoImgPath)
                    ? "Changer de vignette"
                    : "Sélectionnez une vignette"}
                </p>
              </label>
              <input
                id="upload-file"
                type="file"
                onChange={(e) => {
                  setFile(e.target.files[0]);
                }}
                name="file"
              />
              {file ? (
                <CropImage
                  setImage={setFile}
                  setImageBlob={setFileBlob}
                  src={file}
                  ratio={4 / 3}
                />
              ) : (
                ""
              )}
              <br />
              <p className="text-center">
                Recommandé: 1280px x 720px, 5MO (max)
              </p>
            </div>
          </form>
          <div
            style={{
              borderBottom: "1px rgba(0, 0, 0, 0.15) solid",
              marginBottom: "30px",
            }}
          ></div>
          <form className="admin-date-form">
            <h2 style={{ display: "flex", alignItems: "center", gap: 10 }}>
              Date <i className="icon-minute" />
            </h2>
            {sessions.map((step, index) => (
              <div key={index} className="date-box">
                <p>Du </p>
                <p name="dateStart" className="admin-date-input">
                  {moment(step.dateStart ? step.dateStart : step.date).format(
                    "DD/MM/yyyy"
                  )}
                </p>
                <p>à</p>
                <p name="time_start" className="admin-date-input">
                  {step.timeStart}
                </p>
                <p>jusqu'au</p>
                <p name="dateEnd" className="admin-date-input">
                  {moment(step.dateEnd).format("DD/MM/yyyy")}
                </p>
                <p>à</p>
                <p name="time_end" className="admin-date-input">
                  {step.timeEnd}
                </p>
                <label onClick={(e) => deleteSession(e, index, step.id)}>
                  <i
                    className="icon-trash"
                    style={{ color: "var(--red-color)" }}
                  />
                </label>
              </div>
            ))}
            <div>
              <div className="date-box" style={{ marginTop: "40px" }}>
                <div>
                  <p>Début</p>
                  <input
                    name="dateStart"
                    onKeyDown={(e) => e.preventDefault()}
                    type="date"
                    className="admin-date-form-input"
                    value={
                      dateStart ? dateStart : moment().format("yyyy-MM-DD")
                    }
                    onChange={(e) => setDateStart(e.target.value)}
                    min={moment().format("yyyy-MM-DD")}
                  />
                </div>
                <div>
                  <p>Heure : Minutes</p>
                  <input
                    type="time"
                    className="admin-date-form-input"
                    value={timeStart}
                    onChange={(e) => setTimeStart(e.target.value)}
                  />
                </div>
              </div>
              <div className="date-box" style={{ marginTop: "20px" }}>
                <div>
                  <p>Fin</p>
                  <input
                    name="dateEnd"
                    onKeyDown={(e) => e.preventDefault()}
                    type="date"
                    className="admin-date-form-input"
                    value={dateEnd ? dateEnd : moment().format("yyyy-MM-DD")}
                    onChange={(e) => setDateEnd(e.target.value)}
                    min={moment().format("yyyy-MM-DD")}
                  />
                </div>
                <div>
                  <p>Heure : Minutes</p>
                  <input
                    type="time"
                    className="admin-date-form-input"
                    value={timeEnd}
                    onChange={(e) => setTimeEnd(e.target.value)}
                  />
                </div>
              </div>
              <button onClick={(e) => addSession(e)}>
                Valider la session +
              </button>
            </div>
          </form>
          <div
            style={{
              borderBottom: "1px rgba(0, 0, 0, 0.15) solid",
              marginBottom: "30px",
            }}
          ></div>
          <div className="admin-fitler-container">
            <FiltersContext.Provider value={eventProps}>
              <FilterForm
                filtersCallback={setFilter}
                category={false}
                edition={true}
              />
            </FiltersContext.Provider>
            <button
              className="save-btn"
              onClick={(e) => {
                e.preventDefault();
                setNext(true);
              }}
            >
              Suivant
            </button>
          </div>
        </Card>
      </div>
    );
  } else {
    return (
      <div style={{ paddingTop: "70px" }}>
        <Card>
          <a
            className="back"
            onClick={() => {
              setNext(false);
            }}
          >
            <i className="icon-short-arrow" style={{ fontSize: "9px" }} />
            Retour
          </a>
          <h1 className="title" style={{ marginBottom: "30px" }}>
            Étape 2/2
          </h1>
          <div style={{ textAlign: "center" }}>
            <label
              className="upload-style"
              htmlFor="upload-file"
              style={{
                backgroundImage: `linear-gradient(0deg, #161b4b2b 0%, #161b4b2b 100%), url("${bannerBlob
                  ? bannerBlob
                  : eventProps
                    ? eventProps.bannerImgPath
                    : ""
                  }")`,
              }}
            >
              <i className="icon-cloud" style={{ fontSize: "2rem" }} />
              <p className="btn btn-small bg-main">
                {banner || (eventProps && eventProps.bannerImgPath)
                  ? "Changer la bannière"
                  : "Sélectionnez une bannière"}
              </p>
            </label>
            <input
              id="upload-file"
              type="file"
              onChange={(e) => {
                setBanner(e.target.files[0]);
              }}
              name="file"
            />
            {banner ? (
              <CropImage
                setImage={setBanner}
                setImageBlob={setBannerBlob}
                src={banner}
                ratio={16 / 9}
              />
            ) : (
              ""
            )}
            <br />
            <p className="text-center default-p">
              Recommandé: 1280px x 720px, 5MO (max)
            </p>
            <br />
            <div
              style={{
                borderBottom: "1px rgba(0, 0, 0, 0.15) solid",
                marginBottom: "30px",
              }}
            ></div>
            <br />
            <div className="admin-event-register-link">
              <h2>Lien d'inscription</h2>
              <input
                className="admin-input"
                type="url"
                placeholder="https://example.com"
                value={link}
                onChange={(e) => setLink(e.target.value)}
                required
              />
            </div>
            <br />
            <CKEditor
              placeholder={content && `${content}`}
              ckeditorCallback={setContent}
              updateState={(e) => {
                createEvent(e, false);
              }}
            />
            {errorF && <ErrorDiv message={errorF} />}
          </div>
          <div className="btn-box-admin d-flex align-items-center gap-10">
            {sending && !errorF && <LoadingBar done={success ? true : false} />}

            {sending ? (
              <button
                disabled
                className="save-btn"
                style={{ backgroundColor: "#66666b" }}
              >
                Publier
              </button>
            ) : (
              <button
                className="save-btn"
                onClick={(e) => {
                  createEvent(e);
                  setSending(true);
                  setTimeout(() => {
                    setSending(false);
                    setErrorF(false);
                  }, 8000);
                }}
              >
                Publier
              </button>
            )}
          </div>
        </Card>
      </div>
    );
  }
}
