import React from "react";

export default function StepZero() {
  return (
    <React.Fragment>
      <img
        src="/assets/img/propose-content-illustration.svg"
        alt="Proposez vos contenus"
      />
      <h2>
        Vous avez trouvé un contenu intéressant et vous souhaitez le partager
        avec la communauté ?
      </h2>
      <h3>
        Remplissez ce formulaire en 3 étapes pour nous soumettre votre contenu.
      </h3>
    </React.Fragment>
  );
}
