import { useState } from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
import CookiesGDPR from "../molecules/CookiesGDPR";
import { JMSHome } from "components/molecules/Modals/JMSHome";
const Footer = () => {
  const version = localStorage.getItem("::version");
  const [openModalGDPR, setOpenModalGDPR] = useState(false);

  return (
    <>
      <CookiesGDPR openModal={openModalGDPR} setOpenModal={setOpenModalGDPR} />
      <JMSHome />
      <div className="footer_layout">
        <div>
          <div className="first_footer_layout">
            <img
              className="first_img_footer_layout"
              alt="Bouygues"
              src="/assets/icons/Footer/logo_bouygues.svg"
            />
          </div>
          <div className="middle_footer_layout">
            <div className="middle_top_footer_layout">
              <img
                className="img_middle_footer"
                alt="logo Bouygues C"
                src="/assets/icons/Footer/bouygues_construction_logo.svg"
              />
              <img
                className="img_middle_footer"
                alt="logo Bouygues Immo"
                src="/assets/icons/Footer/bouygues_immobilier_logo.jpg"
              />
              <img
                className="img_middle_footer"
                alt="logo Colas"
                src="/assets/icons/Footer/colas-logo.svg"
              />
              <img
                className="img_middle_footer"
                alt="logo Equans"
                src="/assets/icons/Footer/EQUANS_logotype_RGB.png"
              />
              <img
                className="img_middle_footer"
                alt="logo TF1"
                src="/assets/icons/Footer/groupe_TF1_logo.svg"
              />
              <img
                className="img_middle_footer"
                alt="logo Bouygues Telecom"
                src="/assets/icons/Footer/Bouygues_Telecom_RVB.png"
              />
            </div>
            <p>Le portail de la communauté RH du groupe Bouygues</p>
          </div>
          <div className="last_footer_layout">
            <div className="footer__version">
              <img
                alt="HRTeam Logo"
                src="/assets/icons/Footer/logo_HR_team.svg"
              />
              <p>v{version}</p>
            </div>
            <p>
              Le portail de la communauté RH
              <br />
              du groupe Bouygues
            </p>
            <div>
              <p>
                <Link
                  to="/mentions-legales"
                  className="text-deco-none text-main"
                >
                  Mentions légales
                </Link>
              </p>
              <p>
                <Link
                  to="/politique-donnees-personnelles"
                  className="text-deco-none text-main"
                >
                  Politique de données personnelles
                </Link>
              </p>

              <p
                className="text-deco-none text-main toggle"
                onClick={() => {
                  setOpenModalGDPR(true);
                }}
              >
                Gérer les cookies
              </p>
              <p className="text-deco-none text-main">
                Contact :{" "}
                <a href="mailto:hrteam@bouygues.com" className=" text-main">
                  Hrteam@bouygues.com
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
