export default function Title({ openWindow, windowStatus }) {
  const close = () => {
    openWindow(!windowStatus);
  };
  return (
    <div className="propose-content__title" onClick={close}>
      <p>
        Proposez votre contenu <i className="icon-write-bubble"></i>
      </p>
      <p>
        <i className="icon-close"></i>
      </p>
    </div>
  );
}
