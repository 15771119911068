export default function MailIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.07"
      height="12.742"
      viewBox="0 0 16.07 12.742"
    >
      <g transform="translate(-2.304 -5.5)">
        <path
          d="M4.468,6H16.209a1.472,1.472,0,0,1,1.468,1.468v8.806a1.472,1.472,0,0,1-1.468,1.468H4.468A1.472,1.472,0,0,1,3,16.274V7.468A1.472,1.472,0,0,1,4.468,6Z"
          fill="none"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1"
        />
        <path
          d="M17.677,9l-7.339,5.137L3,9"
          transform="translate(0 -1.532)"
          fill="none"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1"
        />
      </g>
    </svg>
  );
}
