import React from 'react'

// Context
import { FilterContext } from './context'

const ResetButton = ({ className, children }) => (
  <FilterContext.Consumer>
    {({ resetFilters }) => (
      <div className={className} onClick={() => resetFilters()}>{children}</div>
    )}
  </FilterContext.Consumer>
)

export default ResetButton
