import React, { useState, useEffect } from "react";
import "./style.css";
import { Link } from "react-router-dom";
import { fetchOneBranches } from "../../../../api/Branche";

export default function User({ data, dataToggle, toggle }) {
  const [branche, setBranche] = useState({});

  async function getOneBranche(id) {
    setBranche(await fetchOneBranches(id));
  }

  useEffect(() => {
    if (data?.branchId) {
      getOneBranche(data.branchId);
    }
  }, [data?.branchId]);

  return (
    <>
      {dataToggle ? (
        <div
          className="card-user__container toggle"
          onClick={() => toggle(true)}
        >
          <div className={`bg-${dataToggle.color} datatoogle`}>
            <i className={`icon-${dataToggle.icon}`}></i>
          </div>
          <div>
            <h3 className="card-user__name">{dataToggle.title}</h3>
            <p className="card-user__job">{dataToggle.description}</p>
          </div>
        </div>
      ) : (
        <Link to={`/users/${data.id}`} className="text-deco-none">
          <div className="card-user__container">
            <img
              src={
                data.avatarPath
                  ? data.avatarPath
                  : "/assets/icons/user-solid.svg"
              }
              alt="user"
            />
            <div>
              <h3 className="card-user__name">
                {data.firstname} {data.lastname}
              </h3>
              <p className="card-user__job">{data.job}</p>
              <p className="card-user__branch">{branche.name}</p>
            </div>
          </div>
        </Link>
      )}
    </>
  );
}
