import React from "react";

const Theme = ({ theme }) => {
  return (
    <React.Fragment>
      {theme && theme.icon && (
        <img
          src={`/assets/img/icons/${theme && theme.icon}`}
          alt="icon thematique"
          className="icon__theme"
        />
      )}
    </React.Fragment>
  );
};

export default Theme;
