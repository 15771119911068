import React from "react";
import "./subtheme.css";

// components
import DefaultLayout from "../../layouts/DefaultLayout";
import ThematicsPage from "./ThematicsPage";

export default function Thematics() {
  return (
    <DefaultLayout>
      <ThematicsPage />
    </DefaultLayout>
  );
}
