import axios from "axios";
import getFormatedToken from "../helpers/getFormatedToken";

export async function sendNewsletter(form) {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/send-newsletter`,
      form,
      getFormatedToken()
    );
    return response;
  } catch (error) {
    // TODO: Handle error properly
    console.log(error);
  }
}
