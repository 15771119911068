import React from 'react'

// Context
import { FilterContext } from './context'

const Item = ({ value, render, className }) => (
  <FilterContext.Consumer>
    { ({ isFilterActive, toggleFilter }) => (
      <div
        className={className}
        onClick={() => {
          if (!value) {
            return
          }

          toggleFilter(value)
        }}
      >
        {render(isFilterActive(value))}
      </div>
    )}
  </FilterContext.Consumer>
)

export default Item
