/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { Link, useLocation } from "react-router-dom";

const getStyle = (pathname, path, exactMatch) => {
  if (!exactMatch) {
    if (pathname === path) {
      return "active";
    }
  } else {
    if (pathname === "/") {
      return "active";
    }
  }
};

const isExternalURL = (path) => {
  return path.toLowerCase().includes("https://");
};

function SidebarItem({ title, icon, path, onClick, exactMatch }) {
  const { pathname } = useLocation();

  return (
    <li className={getStyle(pathname, path, exactMatch)} onClick={onClick}>
      {isExternalURL(path) ? (
        <a href={path} target="_blank">
          <i className={"icon-" + icon}></i>
          <p>{title}</p>
          <span className="side-bar__hover-title">{title}</span>
        </a>
      ) : (
        <Link to={path}>
          <i className={"icon-" + icon}></i>
          <p>{title}</p>
          <span className="side-bar__hover-title">{title}</span>
        </Link>
      )}
    </li>
  );
}

export default SidebarItem;
