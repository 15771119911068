export const sinceCalculator = (date) => {
  // Get the current date
  const currentDate = new Date();
  // Get the date of the message
  const messageDate = new Date(date);
  // Get the difference between the current date and the message date
  const difference = currentDate - messageDate;
  // Get the difference in seconds
  const differenceInSeconds = Math.floor(difference / 1000);
  // Get the difference in minutes
  const differenceInMinutes = Math.floor(differenceInSeconds / 60);
  // Get the difference in hours
  const differenceInHours = Math.floor(differenceInMinutes / 60);
  // Get the difference in days
  const differenceInDays = Math.floor(differenceInHours / 24);
  // Get the difference in weeks
  const differenceInWeeks = Math.floor(differenceInDays / 7);
  // Get the difference in months
  const differenceInMonths = Math.floor(differenceInDays / 30);
  // Get the difference in years
  const differenceInYears = Math.floor(differenceInDays / 365);
  // If the difference is less than 60 seconds
  if (differenceInSeconds < 60) {
    // Return the difference in seconds
    return differenceInSeconds + "s";
  }
  // If the difference is less than 60 minutes
  else if (differenceInMinutes < 60) {
    // Return the difference in minutes
    return differenceInMinutes + " min";
  }
  // If the difference is less than 24 hours
  else if (differenceInHours < 24) {
    // Return the difference in hours
    return differenceInHours + " h";
  }
  // If the difference is less than 7 days
  else if (differenceInDays < 7) {
    // Return the difference in days
    return differenceInDays + " j";
  }
  // If the difference is less than 4 weeks
  else if (differenceInWeeks < 4) {
    // Return the difference in weeks
    return differenceInWeeks + " sem";
  }
  // If the difference is less than 12 months
  else if (differenceInMonths < 12) {
    // Return the difference in months
    return differenceInMonths + " mois";
  }
  // If the difference is less than 365 days
  else if (differenceInYears < 365) {
    // Return the difference in years
    return differenceInYears + " an(s)";
  }
};
