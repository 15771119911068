import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "rjs-custom-build-ckeditor5-resize-img";
import moment from "moment";

import "./newsletter.css";
import { useEffect, useState } from "react";
import { fetchAllEvents } from "api/Events/Events";
import { fetchAllNews } from "api/News/News";
import Search from "components/Search/SearchFilter";

export default function NewsletterForm({ newsletter, setNewsletter }) {
  const [eventFormIsOpen, setEventFormIsOpen] = useState(false);
  const [newsFormIsOpen, setNewsFormIsOpen] = useState(false);
  const [events, setEvents] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [searchEvent, setSearchEvent] = useState(null);
  const [news, setNews] = useState([]);
  const [filteredNews, setFilteredNews] = useState([]);
  const [searchNews, setSearchNews] = useState(null);

  async function getAllEvents() {
    setEvents(await fetchAllEvents([]));
  }

  async function getAllNews() {
    setNews(await fetchAllNews([]));
  }

  useEffect(() => {
    if (searchEvent) {
      setFilteredEvents(
        events.filter((event) =>
          event.title.toLowerCase().includes(searchEvent.toLowerCase())
        )
      );
    } else {
      setFilteredEvents(events);
    }
  }, [searchEvent || events]);

  useEffect(() => {
    if (searchNews) {
      setFilteredNews(
        news.filter((news) =>
          news.title.toLowerCase().includes(searchNews.toLowerCase())
        )
      );
    } else {
      setFilteredNews(news);
    }
  }, [searchNews || news]);

  return (
    <div>
      <div className="container-newsletter">
        <label>Titre de la newsletter</label>
        <input
          className="newsletter-admin-input"
          style={{ marginBottom: "12px" }}
          type="text"
          placeholder="Titre"
          value={newsletter.title}
          onChange={(e) => {
            setNewsletter({ ...newsletter, title: e.target.value });
          }}
        />
      </div>
      <div className="container-newsletter">
        <label>Contenu de la newsletter</label>
        <CKEditor
          config={{
            toolbar: ["bold", "italic", "link"],
            placeholder: "Contenu de la newsletter...",
          }}
          editor={ClassicEditor}
          data={newsletter.content}
          onChange={(event, editor) => {
            setNewsletter({ ...newsletter, content: editor.getData() });
          }}
        />
      </div>
      <div className="newsletter-separator"></div>
      {/* EVENTS PICKER */}
      <div className="newsletter_Events">
        <h3>Events</h3>
        <p>
          Vous pouvez ajouter jusqu'à 3 événements à la newsletter en cliquant
          sur le bouton ci-dessous.
        </p>
        {eventFormIsOpen && (
          <>
            <Search
              placeholder={"Rechercher un event par titre..."}
              onChange={(e) => setSearchEvent(e)}
            />

            <div className="newsletter_News">
              {filteredEvents?.length &&
                filteredEvents
                  ?.filter((e) => e.Content.state == "PUBLISHED")
                  .map((n) => (
                    <div className="news-list-container">
                      <h3>
                        {n.title}
                        <br />{" "}
                      </h3>
                      {moment(n.EventSession[0].startDate).format("DD/MM")}
                      <div
                        style={{
                          backgroundImage: "url(" + `${n.logoImgPath}` + ")",
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                          height: "40%",
                          width: "100%",
                        }}
                      ></div>
                      <label>
                        <input
                          type="checkbox"
                          className="checkbox"
                          checked={newsletter.events.some((e) => e.id === n.id)}
                          onChange={(e) => {
                            if (
                              newsletter.events.length >= 3 &&
                              e.target.checked
                            ) {
                              e.target.checked = false;
                              return;
                            }
                            if (e.target.checked) {
                              setNewsletter({
                                ...newsletter,
                                events: [...newsletter.events, n],
                              });
                            } else {
                              setNewsletter({
                                ...newsletter,
                                events: newsletter.events.filter(
                                  (e) => e.id !== n.id
                                ),
                              });
                            }
                          }}
                        />
                      </label>
                    </div>
                  ))}
            </div>

            {/*             <Items
              events={filteredEvents}
              actionBar={(event) => {
                return (
                  <label style={{ width: "100%" }}>
                    <input
                      type="checkbox"
                      className="checkbox"
                      checked={newsletter.events.some((e) => e.id === event.id)}
                      onChange={(e) => {
                        if (newsletter.events.length >= 3 && e.target.checked) {
                          e.target.checked = false;
                          return;
                        }
                        if (e.target.checked) {
                          setNewsletter({
                            ...newsletter,
                            events: [...newsletter.events, event],
                          });
                        } else {
                          setNewsletter({
                            ...newsletter,
                            events: newsletter.events.filter(
                              (e) => e.id !== event.id
                            ),
                          });
                        }
                      }}
                    />
                  </label>
                );
              }}
            /> */}
          </>
        )}
        {!eventFormIsOpen && newsletter?.events?.length > 0 && (
          <div>
            <br />
            <p
              style={{ borderBottom: "solid 1px black", width: "max-content" }}
            >
              Events sélectionnés :
            </p>
            <br />
            <div className="list-news-selection">
              {newsletter.events.map((n) => (
                <p>
                  <label />
                  {n.title}
                </p>
              ))}
            </div>
          </div>
        )}
        <button
          className="btn btn-small bg-main mt-10"
          onClick={(e) => {
            e.preventDefault();
            getAllEvents();
            setEventFormIsOpen(!eventFormIsOpen);
          }}
        >
          {eventFormIsOpen ? "Valider" : "Sélectionner"}
        </button>
      </div>

      {/* NEWS PICKER */}
      <div className="newsletter_Events">
        <h3>News</h3>
        <p>
          Vous pouvez ajouter jusqu'à 3 actualités à la newsletter en cliquant
          sur le bouton ci-dessous.
          <br /> Pour épingler une news, sélectionnez la en premier.
        </p>

        {newsFormIsOpen && (
          <>
            <Search
              placeholder={"Rechercher une news par titre..."}
              onChange={(e) => setSearchNews(e)}
            />
            <div className="newsletter_News">
              {filteredNews?.length &&
                filteredNews?.map((n) => (
                  <div className="news-list-container">
                    <h3>{n.title}</h3>
                    <div
                      style={{
                        backgroundImage: "url(" + `${n.logoImgPath}` + ")",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        height: "40%",
                        width: "100%",
                      }}
                    ></div>
                    <label>
                      <input
                        type="checkbox"
                        className="checkbox"
                        checked={newsletter.news.some((e) => e.id === n.id)}
                        onChange={(e) => {
                          if (newsletter.news.length >= 3 && e.target.checked) {
                            e.target.checked = false;
                            return;
                          }
                          if (e.target.checked) {
                            setNewsletter({
                              ...newsletter,
                              news: [...newsletter.news, n],
                            });
                          } else {
                            setNewsletter({
                              ...newsletter,
                              news: newsletter.news.filter(
                                (e) => e.id !== n.id
                              ),
                            });
                          }
                        }}
                      />
                    </label>
                  </div>
                ))}
            </div>
          </>
        )}
        {!newsFormIsOpen && newsletter?.news?.length > 0 && (
          <div>
            <br />
            <p
              style={{ borderBottom: "solid 1px black", width: "max-content" }}
            >
              News sélectionnées :
            </p>
            <br />
            <div className="list-news-selection">
              {newsletter.news.map((n, index) => (
                <p>
                  <label />
                  {n.title}{" "}
                  {index == 0 && (
                    <strong style={{ fontWeight: 600 }}>- News épinglée</strong>
                  )}
                </p>
              ))}
            </div>
          </div>
        )}
        <button
          className="btn btn-small bg-main"
          style={{ marginTop: "20px" }}
          onClick={(e) => {
            e.preventDefault();
            getAllNews();
            setNewsFormIsOpen(!newsFormIsOpen);
          }}
        >
          {newsFormIsOpen ? "Valider" : "Sélectionner"}
        </button>
      </div>
    </div>
  );
}
