import axios from "axios";
import getFormatedToken from "helpers/getFormatedToken";

export async function searchOnEntites(query) {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/api/entities/sample/${query}`,
    getFormatedToken()
  );
  console.log("response", response);
  const { data } = response.data;
  return data;
}
