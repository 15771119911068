import { useState } from "react";
import CenterLayout from "../../../layouts/CenterLayout";

import { Card } from "../../../components/molecules/AdminPanel/index";
import { Link } from "react-router-dom";

import "./newsletter.css";
import NewsletterPreview from "./NewsletterPreview";
import { sendNewsletter } from "api/sendNewsletter";
import NewsletterForm from "./NewsletterForm";

export default function ANewsletter() {
  const [newsletter, setNewsletter] = useState({
    title: "",
    content: "",
    events: [],
    news: [],
  });
  const [preview, setPreview] = useState(false);
  const [sendStatus, setSendStatus] = useState({
    sending: false,
    sended: false,
    status: false,
    message: null,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (window.confirm("Êtes-vous sûr de vouloir envoyer cette newsletter ?")) {
      setSendStatus({
        sending: true,
        sended: false,
        status: false,
        message: null,
      });
      const formattedNewsletter = [];
      formattedNewsletter.push({
        title: newsletter.title,
        content: newsletter.content,
        events: newsletter.events.map((event) => event.id),
        news: newsletter.news.map((news) => news.id),
      });
      const res = await sendNewsletter(formattedNewsletter);
      if (res && res.status === 200) {
        setSendStatus({
          sending: false,
          sended: true,
          status: true,
          message: "La newsletter a bien été envoyée",
        });
      } else {
        setSendStatus({
          sending: false,
          sended: true,
          status: false,
          message: "Une erreur est survenue",
        });
      }
    } else return;
  };

  return (
    <CenterLayout title="Newsletter" className="Newsletter_Pages">
      <div className="Aform-body-admin">
        <Card>
          <Link to="/account" className="back">
            <i className="icon-short-arrow" style={{ fontSize: "9px" }} />{" "}
            Retour
          </Link>
          <h1 className="title">Créer une newsletter</h1>
          <form className="admin-form">
            <div className="admin-input-newsletter-box">
              {!preview ? (
                <NewsletterForm
                  newsletter={newsletter}
                  setNewsletter={setNewsletter}
                />
              ) : (
                <NewsletterPreview
                  title={newsletter.title}
                  content={newsletter.content}
                  events={newsletter.events}
                  news={newsletter.news}
                />
              )}
              <div className="btn-ck-box">
                <button
                  style={{ padding: "8px 35px" }}
                  id="ck-preview"
                  onClick={(e) => {
                    e.preventDefault();
                    setPreview(!preview);
                  }}
                >
                  {!preview ? "Suivant" : "Modifier"}
                </button>
              </div>
            </div>
          </form>
          <div
            style={{
              borderBottom: "1px rgba(0, 0, 0, 0.15) solid",
              marginBottom: "10px",
            }}
          />
          {preview && (
            <div
              className="container-btn-newsletter"
              style={{ marginBottom: "15px" }}
            >
              <button
                className="save-btn"
                style={{ width: "30%" }}
                disabled={sendStatus.sending || sendStatus.sended}
                onClick={handleSubmit}
              >
                {sendStatus.sending ? (
                  <>
                    <p style={{ marginBottom: "5px" }}>
                      Envoi en cours, veuillez patienter
                    </p>
                    <p className="loader" style={{ margin: "auto" }} />
                  </>
                ) : sendStatus.message ? (
                  <p>{sendStatus.message}</p>
                ) : (
                  <p>Envoyer la newsletter</p>
                )}
              </button>
              {!sendStatus.sended && !sendStatus.sending && (
                <span>
                  Attention ! <br />
                  En cliquant sur envoyer, la newsletter sera automatiquement
                  envoyée à tous les utilisateurs de la plateforme.
                  <br />
                  <strong>Cette action est irréversible.</strong>
                </span>
              )}
              {sendStatus.sended && (
                <>
                  <p>Vous pouvez maintenant quitter cette page</p>
                  <Link to="/account" className="back">
                    <i
                      className="icon-short-arrow"
                      style={{ fontSize: "9px" }}
                    />{" "}
                    Quitter l'éditeur
                  </Link>
                </>
              )}
            </div>
          )}
          {/*           <div style={{ width: "fit-content" }}>
            {sendStatus.sended && (
              <p
                className={`${
                  sendStatus.status ? "success-popup" : "error-popup"
                }`}
              >
                {sendStatus.message}
              </p>
            )}
          </div> */}
        </Card>
      </div>
    </CenterLayout>
  );
}
