import React from "react";
import Item from "./Item";
import NotFound from "../molecules/ErrorsMessages/NotFound";
import "./links.css";

const LinksContent = ({ items, deleteOneLink }) => {
  return (
    <React.Fragment>
      {items && items.length < 1 && <NotFound element="lien" />}
      <div className="links__container">
        {items &&
          items.length > 0 &&
          items
            .slice(0)
            .reverse()
            .map((item, index) => (
              <Item item={item} key={index} deleteOneLink={deleteOneLink} />
            ))}
      </div>
    </React.Fragment>
  );
};
export default LinksContent;
