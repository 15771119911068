export default function KeyIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13.36"
      height="13.36"
      viewBox="0 0 13.36 13.36"
    >
      <path
        d="M12.36,4.249A4.251,4.251,0,0,1,7.319,8.424l-.58.652a.579.579,0,0,1-.433.194h-.9v.966a.579.579,0,0,1-.579.579H3.863v.966a.579.579,0,0,1-.579.579H.579A.579.579,0,0,1,0,11.781V9.9a.58.58,0,0,1,.17-.41L4.076,5.581A4.249,4.249,0,1,1,12.36,4.249ZM8.111,3.09A1.159,1.159,0,1,0,9.27,1.931,1.159,1.159,0,0,0,8.111,3.09Z"
        transform="translate(0.5 0.5)"
        fill="none"
        stroke="currentColor"
        stroke-width="1"
      />
    </svg>
  );
}
