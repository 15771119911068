import axios from "axios";
import getFormatedToken from "helpers/getFormatedToken";

export async function searchOnExpertises(query) {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/api/expertises/sample/${query}`,
    getFormatedToken()
  );

  const { data } = response.data;
  return data;
}
