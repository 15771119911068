import { MentionsInput, Mention } from "react-mentions";

export default function MentionsForm({
  users = [],
  onChange = () => {},
  value = "",
  className = "",
  placeholder = "Commencer une discussion...",
  ...props
}) {
  return (
    <MentionsInput
      className={className}
      placeholder={placeholder}
      name="message"
      onChange={onChange}
      allowSuggestionsAboveCursor={true}
      value={value}
      autoFocus
      {...props}
    >
      <Mention
        trigger="@"
        data={users}
        displayTransform={(id, display) => `@${display}`}
        renderSuggestion={(suggestion) => (
          <div className="message-input__suggestions__user">
            <div
              className="message-input__suggestions__user__avatar"
              style={{
                backgroundImage: `url(${
                  suggestion.avatarPath || "/assets/icons/user-solid.svg"
                })`,
              }}
            />
            <p>{suggestion.firstname + " " + suggestion.lastname}</p>
          </div>
        )}
      />
    </MentionsInput>
  );
}
