import React from "react";

import WelcomePage from "./WelcomePage";
import DefaultLayout from "../../layouts/DefaultLayout";

export default function Welcome() {
  return (
    <DefaultLayout
      title={{
        className: "welcome__title",
        name: "Welcome",
      }}
      className="welcome-page__container"
    >
      <WelcomePage />
    </DefaultLayout>
  );
}
