import { useState } from "react";
import ArrowDownIcon from "components/atoms/icons/arrow-down";

export default function DropdownJob({
  list = [],
  setBranches = () => {},
  branches = [],
}) {
  const [open, setOpen] = useState(true);

  const handleSelect = (e, item) => {
    if (e.target.checked) {
      setBranches([...branches, item.id]);
    } else {
      setBranches(branches.filter((i) => i !==item.id));
    }
  };

  return (
    <div className="annuaire__filter-dropdown" data-open={open}>
      <div
        className="annuaire__filter-dropdown_title"
        onClick={() => setOpen(!open)}
      >
        <span>Métiers</span>
        <div />
        <ArrowDownIcon />
      </div>
      <ul className="annuaire__filter-dropdown_list">
        {list.map((item, index) => (
          <li key={index} className="annuaire__filter-dropdown_list_item">
            <input
              type="checkbox"
              name={item.name}
              id={item.name}
              className="rounded_input"
              checked={branches.includes(item.id)}
              onChange={(e) => handleSelect(e, item)}
            />
            <label htmlFor={item.name}>{item.name}</label>
          </li>
        ))}
      </ul>
    </div>
  );
}
