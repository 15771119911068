/* eslint-disable array-callback-return */
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import DropdownIcons from "../../Thematics/DropdownIcons";
import moment from "moment";

export default function FilterEventByDate({ events }) {
  const [EventsArray, setEventsArray] = useState([]);
  useEffect(() => {
    if (events.length >= 1) {
      let EventsArrayTemp = [];
      for (const key in events) {
        if (!events[key].isFirst) {
          let EventSessionInDate = [];
          for (const keySession in events[key].EventSession) {
            if (
              moment(events[key].EventSession[keySession].startDate).diff() > 0
            ) {
              EventSessionInDate.push({
                days: moment(
                  events[key].EventSession[keySession].startDate
                ).diff(),
                ...events[key].EventSession[keySession],
              });
            }
          }

          if (EventSessionInDate.length >= 1) {
            const closest = EventSessionInDate.reduce((a, b) => {
              if (b.days >= 0 && a.days >= 0) {
                return b.days < a.days ? b : a;
              } else if (a.days >= 0) {
                return a;
              } else if (b.days >= 0) {
                return b;
              }
            });
            EventsArrayTemp.push({ ...events[key], session: closest });
          }
        }
      }
      setEventsArray(EventsArrayTemp);
    }
  }, [events]);

  return (
    <>
      {EventsArray.sort((a, b) => a.session.days - b.session.days).map(
        (event, index) => {
          return (
            <Link to={`/events/${event.id}`} key={index}>
              <div className="home__events-item">
                <div className="card-event__date">
                  <h3>{moment(event.session.startDate).format("D")}</h3>
                  <h3>{moment(event.session.startDate).format("MMM")}</h3>
                </div>
                <div>
                  <div>
                    <h2>{event.title}</h2>
                    {event.Branches.length ? (
                      <p>{event.Branches[0].name}</p>
                    ) : (
                      ""
                    )}
                    {event.hashtags.map((hashtag, index) => (
                      <span key={index}>{`#${hashtag.replace(
                        /,/g,
                        " #"
                      )}`}</span>
                    ))}
                  </div>
                  <DropdownIcons thematics={event.MainThemes} />
                </div>
              </div>
            </Link>
          );
        }
      )}
    </>
  );
}
