import React, { useState, useEffect } from "react";
import moment from "moment";

import Theme from "../molecules/Thematics/Icons";
import {
  fetchIsSubscribedEvent,
  sendSubscribeEvent,
  sendUnSubscribeEvent,
} from "../../api/Events/Event";

export default function EventTitle({
  content: {
    id,
    title,
    text,
    logoImgPath,
    bannerImgPath,
    hashtags,
    createAt,
    Branches,
    MainThemes,
    SubThemes,
    EventContent,
    EventSession,
    link
  },
}) {
  const [isSubscribed, setIsSubscribed] = useState(null);
  const eventDateType = (startDate, endDate) => {
    const startformatday = moment(startDate).format("DD/MM/YYYY");
    const startmonth = moment(startDate).format("MM");
    const endformatday = moment(endDate).format("DD/MM/YYYY");
    const endmonth = moment(endDate).format("MM");
    let startHour = moment(startDate).format("HH:mm");
    let endHour = moment(endDate).format("HH:mm");

    if (startformatday === endformatday) {
      return `Le ${moment(startDate).format("D MMMM")}${startHour !== endHour
        ? ` de ${startHour} à ${endHour}`
        : ` à ${startHour}`
        }`;
    } else if (startmonth === endmonth && startformatday !== startmonth) {
      return `Du ${moment(startDate).format("D")} au ${moment(endDate).format(
        "D MMMM"
      )}`;
    } else {
      return `Du ${moment(startDate).format("D MMMM")} au ${moment(
        endDate
      ).format("D MMMM")}`;
    }
  };

  async function getIsSubscribed(id) {
    setIsSubscribed(null);
    setIsSubscribed(await fetchIsSubscribedEvent(id));
  }

  useEffect(() => {
    getIsSubscribed(id);
  }, [id]);

  const subscribe = async () => {
    setIsSubscribed(null);
    if (isSubscribed) {
      await sendUnSubscribeEvent(id);
      setIsSubscribed(false);
    } else {
      await sendSubscribeEvent(id);
      setIsSubscribed(true);
    }
  };

  return (
    <div className="event__main__title">
      <div className="event__thematic">
        {MainThemes.map((mainThemes, index) => (
          <Theme key={index} theme={mainThemes} />
        ))}
      </div>
      <div className="event__title">
        <h1>{title}</h1>
        <div>
          {isSubscribed === null ? (
            <button>
              <div className="loader" style={{ margin: "auto" }}></div>
            </button>
          ) : isSubscribed === true ? (
            <button onClick={subscribe}>
              <i className="icon-remove-notification text-main"></i>
            </button>
          ) : (
            <button onClick={subscribe}>
              <i className="icon-add-notification text-main"></i>
            </button>
          )}
        </div>
      </div>
      <p>
        {hashtags.map((hashtag, index) => (
          <span key={index}>{hashtag}</span>
        ))}
        {hashtags.length > 0 && Branches.length > 0 && <span> - </span>}
        {Branches.map((branch) => (
          <>{branch.name}</>
        ))}
      </p>
      {EventSession.map((row, index) => (
        <h2>{eventDateType(row.startDate, row.endDate)}</h2>
      ))}
      {link && link.length && (
        <a
          href={link}
          target="_blank"
          className="btn bg-blue btn-small"
          rel="noreferrer"
          style={{
            margin: "10px 0",
            display: "inline-block",
          }}

        >
          Inscription
        </a>
      )
      }
    </div >
  );
}
