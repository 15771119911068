import React from "react";
import { Link, useLocation } from "react-router-dom";

export default function SecondaryMenuItem({ link, color }) {
  const { path, title } = link;
  const { pathname } = useLocation();

  return (
    <li className={`third-items ${color} ${pathname === path ? "active" : ""}`}>
      <Link to={path}>
        <p>{title}</p>
      </Link>
    </li>
  );
}
