import axios from "axios";
import filtersCompose from "../helpers/FiltersCompose";
import getFormatedToken from "../helpers/getFormatedToken";

export async function fetchAllFiles(filters) {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/files${filtersCompose(filters)}`,
      getFormatedToken()
    );

    const { data } = response.data;

    return data.files || [];
  } catch (error) {
    // TODO: Handle error properly
    // console.log(error);
  }
}

export async function fetchOneFile(id) {
  if (!id) {
    return;
  }

  const response = await axios({
    method: "GET",
    url: `${process.env.REACT_APP_API_URL}/api/files/${id}`,
    ...getFormatedToken(true),
  });
  const { data } = response.data;
  return data.file;
}
