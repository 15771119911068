/* eslint-disable react/jsx-no-target-blank */
import React, { useContext } from "react";

import "./ItemListShares.css";
import { ThemeIcon } from "../molecules/IconTheme";
import { EditMode } from "../../contexts/editmode";
import { Link } from "react-router-dom";

import PermissionContext from "../../contexts/permission";
import moment from "moment";

const ItemListShares = ({ item, deleteOneFile, isHomepage = false }) => {
  const {
    id,
    title,
    hashtags,
    link,
    description,
    blobPath,
    MainThemes,
    createdAt,
    SubThemes,
  } = item;
  const { Role, AdminOfSubThemes, AdminOfMainThemes } =
    useContext(PermissionContext);
  const { editing } = useContext(EditMode);

  const isValideSub = SubThemes.map((step) => {
    if (AdminOfSubThemes.some(({ id }) => id === step.id)) {
      return true;
    } else {
      return false;
    }
  });
  const isValideMain = MainThemes.map((step) => {
    if (AdminOfMainThemes.some(({ id }) => id === step.id)) {
      return true;
    } else {
      return false;
    }
  });

  const isExternalURL = (path) => {
    return (
      path.toLowerCase().includes("https://") ||
      path.toLowerCase().includes("http://")
    );
  };
  return (
    item && (
      <div className="shares__list disable-shadow-on-hover">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={
            blobPath !== ""
              ? isExternalURL(blobPath)
                ? `${blobPath}`
                : `${window.location.origin}/${blobPath}`
              : `${link}`
          }
        >
          <div className="shares__item-text">
            <h4>{title}</h4>

            {description ? <p>{description}</p> : ""}

            {isHomepage ? (
              <span style={{ textTransform: "inherit" }}>
                Publié le {moment(createdAt).format("DD/MM/YYYY")}
              </span>
            ) : hashtags ? (
              hashtags.map((hashtag, index) => (
                <span key={index}> #{hashtag.replace(/,/g, " #")} </span>
              ))
            ) : (
              ""
            )}
          </div>
          <div className="shares__item-icons">
            <div className="shares__item-thematics">
              <p>
                {MainThemes.length > 0 && <ThemeIcon theme={MainThemes[0]} />}
                {MainThemes &&
                  MainThemes.length > 1 &&
                  `+${MainThemes.length - 1}`}
              </p>
              <div>
                {MainThemes &&
                  MainThemes.map((thematic, index) => {
                    return (
                      index > 0 && <ThemeIcon key={index} theme={thematic} />
                    );
                  })}
              </div>
            </div>

            <div className="shares__items-dl-button">
              <i className="icon-open-part-one" />
              <i className="icon-open-part-two" />
            </div>
            {/* <img src="/assets/img/icons/download.svg" alt="télécharger" /> */}
          </div>
        </a>
        {(Role.length > 0 && Role.some(({ name }) => name === "ADMIN")) ||
        (AdminOfSubThemes.length > 0 && isValideSub.includes(true)) ||
        isValideMain.includes(true) ? (
          editing && (
            <div className="links__edit_container">
              <Link to={"/admin/shares/" + id}>
                <button className="links__edit_button">Modifier</button>
              </Link>
              <div
                className="links__edit_trash"
                onClick={() => {
                  if (
                    window.confirm(
                      `Êtes vous sûr de vouloir suprimer le document : ${title} ?`
                    )
                  ) {
                    deleteOneFile(id);
                  }
                }}
              >
                <i className="icon-trash" />
              </div>
            </div>
          )
        ) : (
          <React.Fragment />
        )}
      </div>
    )
  );
};

export default ItemListShares;
