import React, { useState, useEffect } from "react";

// components
import { Card } from "../../../components/molecules/AdminPanel";
import { HrefBox } from "../../../components/molecules/AdminPanel/";
import { fetchDashboard } from "../../../api/Dashboard";
import { updateDashboard } from "../../../api/Dashboard";

import ErrorDiv from "../../../components/molecules/ErrorsMessages/errorForm";

import "./home.css";

export default function AHome() {
  const [banner, setBanner] = useState(null);
  const [bannerBlob, setBannerBlob] = useState(null);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [sending, setSending] = useState(false);
  const [dashboard, setDashboard] = useState({
    n1: { label: "", value: "" },
    n2: { label: "", value: "" },
    n3: { label: "", value: "" },
    n4: { label: "", value: "" },
  });

  function updateDashboardState(key, value) {
    setDashboard((dashboard) => ({
      ...dashboard,
      [key]: value,
    }));
  }

  async function getDashboard() {
    setDashboard(await fetchDashboard());
  }

  useEffect(() => {
    getDashboard();
  }, []);

  const submitDashboard = async () => {
    const { n1, n2, n3, n4 } = dashboard;

    const res = await updateDashboard({
      n1,
      n2,
      n3,
      n4,
      banner,
    });
    if (!res) {
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
      }, 1500);
      return;
    }
    setError(true);
  };

  async function getBase64Url(file) {
    return new Promise((resolve) => {
      const reader = new FileReader();

      reader.addEventListener(
        "load",
        () => {
          setBannerBlob(reader.result);
        },
        false
      );
      reader.readAsDataURL(file);
    });
  }

  return (
    <div className="before-card" style={{ width: "60vw" }}>
      <Card>
        <div>
          <h1 className="title">Modifier les chiffres clés</h1>
          <div className="form-container-home-admin">
            <div>
              <label
                className="upload-style"
                htmlFor="upload-file"
                style={{
                  padding: "50px 0px",
                  backgroundImage: `linear-gradient(0deg, #161b4b2b 0%, #161b4b2b 100%), url("${
                    bannerBlob
                      ? bannerBlob
                      : dashboard &&
                        dashboard.bannerImgPath &&
                        dashboard.bannerImgPath
                  }")`,
                }}
              >
                <i className="icon-cloud" style={{ fontSize: "2rem" }} />
                <p className="btn btn-small bg-main">
                  {banner || dashboard.bannerImgPath
                    ? "Changer la bannière"
                    : "Selectionnez une bannière"}
                </p>
              </label>
              <input
                id="upload-file"
                type="file"
                onChange={(e) => {
                  setBanner(e.target.files[0]);
                  getBase64Url(e.target.files[0]);
                }}
                name="file"
              />
            </div>
            <div>
              <div className="divendessousquicontientdestrucs">
                <input
                  type="text"
                  id="number-admin-right-home"
                  value={dashboard.n1.value}
                  onChange={({ target }) =>
                    updateDashboardState("n1", {
                      label: dashboard.n1.label,
                      value: target.value,
                    })
                  }
                />
              </div>
              <div className="divendessousquicontientdestrucs">
                <input
                  type="text"
                  value={dashboard.n1.label}
                  onChange={({ target }) =>
                    updateDashboardState("n1", {
                      label: target.value,
                      value: dashboard.n1.value,
                    })
                  }
                />
              </div>

              {/* {bannerBlob ? (
                <img
                  src={bannerBlob}
                  style={{ width: "250px", height: "150px" }}
                />
              ) : (
                dashboard &&
                dashboard.bannerImgPath && (
                  <img
                    src={dashboard.bannerImgPath}
                    style={{ width: "250px", height: "150px" }}
                  />
                )
              )} */}
            </div>

            <div className="home-admin-left-side-container">
              <div className="home-admin-left-side-box">
                <input
                  type="text"
                  value={dashboard.n2.value}
                  onChange={({ target }) =>
                    updateDashboardState("n2", {
                      label: dashboard.n2.label,
                      value: target.value,
                    })
                  }
                />
                <input
                  type="text"
                  value={dashboard.n2.label}
                  onChange={({ target }) =>
                    updateDashboardState("n2", {
                      label: target.value,
                      value: dashboard.n2.value,
                    })
                  }
                />
              </div>
              <div className="home-admin-left-side-box">
                <input
                  type="text"
                  value={dashboard.n3.value}
                  onChange={({ target }) =>
                    updateDashboardState("n3", {
                      label: dashboard.n3.label,
                      value: target.value,
                    })
                  }
                />
                <input
                  type="text"
                  value={dashboard.n3.label}
                  onChange={({ target }) =>
                    updateDashboardState("n3", {
                      label: target.value,
                      value: dashboard.n3.value,
                    })
                  }
                />
              </div>
              <div className="home-admin-left-side-box">
                <input
                  type="text"
                  value={dashboard.n4.value}
                  onChange={({ target }) =>
                    updateDashboardState("n4", {
                      label: dashboard.n4.label,
                      value: target.value,
                    })
                  }
                />
                <input
                  type="text"
                  value={dashboard.n4.label}
                  onChange={({ target }) =>
                    updateDashboardState("n4", {
                      label: target.value,
                      value: dashboard.n4.value,
                    })
                  }
                />
              </div>
            </div>
          </div>
          {sending ? (
            <button
              className="save-btn"
              style={{
                backgroundColor: "#66666b",
                margin: "40px 0px 0px 0px",
              }}
              disabled
            >
              Enregistrer
            </button>
          ) : (
            <button
              className="save-btn"
              style={{ margin: "40px 0px 0px 0px" }}
              onClick={(e) => {
                e.preventDefault();
                submitDashboard();
                setSending(true);
                setTimeout(() => {
                  setSending(false);
                  setError(false);
                }, 2000);
              }}
            >
              Enregistrer
            </button>
          )}
        </div>
        {error && <ErrorDiv message="Erreur: un problème est survenu..." />}
        {success && (
          <div
            style={{
              backgroundColor: "var(--green-color)",
              color: "white",
              padding: 10,
              fontFamily: "var(--cera-bold)",
              textAlign: "center",
              marginTop: 10,
            }}
          >
            Changements enregistrés avec succès !
          </div>
        )}
      </Card>
      <HrefBox />
    </div>
  );
}
