import OptionsIcon from "components/atoms/icons/options";
import { PencilIcon } from "components/atoms/icons/pencil";
import { TrashIcon } from "components/atoms/icons/trash";
import DefaultModal from "components/molecules/Modals/Default";

import FormatChatMessage from "helpers/FormatChatMessage";

import { useState } from "react";
import { Link } from "react-router-dom";
import Attachment from "../Attachment";
import MentionsForm from "../MentionsForm";
import { CommentFooter } from "./CommentFooter";

export const UnderCommentContent = ({
  comment,
  onComment,
  avatarPath,
  messageLiked,
  socket,
  userId,
  onDeleteComment,
  onUpdateComment,
  users = [],
}) => {
  const [isOnUpdate, setOnUpdate] = useState(false);
  const [contentToUpdate, setContentToUpdate] = useState(comment.content);
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);

  const handleUpdateComment = (messageId) => {
    if (contentToUpdate === comment.content || contentToUpdate === "") {
      setOnUpdate(false);
    } else {
      onUpdateComment(contentToUpdate, messageId);
      setOnUpdate(false);
    }
  };

  const handleDeleteComment = (messageId) => {
    onDeleteComment(messageId);
    setConfirmDeleteModal(false);
  };

  const DisplayFile = ({ files }) => {
    if (files?.length) {
      return files.map((file) => (
        <div className="comment-file">
          <Attachment filePath={file.blobPath} isInComment={true} />
        </div>
      ));
    } else return <></>;
  };
  return (
    <div className="under-comment-container" key={comment.id}>
      <div
        className="comment-img"
        style={{
          backgroundImage: `url(${comment && comment.author && comment.author.avatarPath
              ? comment.author.avatarPath
              : "/assets/icons/user-solid.svg"
            })`,
        }}
      />
      {!isOnUpdate ? (
        <div className="container-comment-and-undercomment">
          <div className="under-content-comment">
            <Link to={`/users/${comment.author.id}`}>
              <h4 className="under-h4">
                {comment.author.firstname + " " + comment.author.lastname}
              </h4>
            </Link>

            <FormatChatMessage text={comment.content} />
            <DisplayFile files={comment.Attachments} />
          </div>
          <CommentFooter
            comment={comment}
            socket={socket}
            messageLiked={messageLiked}
            avatarPath={avatarPath}
            setIsWritingMode={(e) => onComment(e)}
            userId={userId}
          />
        </div>
      ) : (
        <div className="container-comment-and-undercomment">
          <MentionsForm
            className="comm-comment-input"
            placeholder="Répondre..."
            onChange={(e) => setContentToUpdate(e.target.value)}
            value={contentToUpdate}
            users={users}
          />
          <div className="actions-btn">
            <button onClick={() => setOnUpdate(false)} className="cancel">
              Annuler
            </button>
            <button onClick={() => handleUpdateComment(comment.id)}>
              Enregistrer
            </button>
          </div>
        </div>
      )}
      {userId === comment.author.id && (
        <div className="message-options">
          <button>
            <OptionsIcon />
          </button>
          {isOnUpdate ? (
            <ul>
              <li onClick={() => setOnUpdate(false)}>Annuler</li>
            </ul>
          ) : (
            <ul>
              <li onClick={() => setOnUpdate(true)}>
                <PencilIcon /> Modifier
              </li>
              <li onClick={() => setConfirmDeleteModal(true)}>
                <TrashIcon />
                Supprimer
              </li>
            </ul>
          )}
        </div>
      )}
      <DefaultModal
        isOpen={confirmDeleteModal}
        setIsOpen={setConfirmDeleteModal}
        title="Supprimer votre commentaire ?"
      >
        <p className="text-center">
          Êtes-vous sûr de vouloir supprimer ce commentaire ? Cette action est
          irréversible.
        </p>
        <div className="d-flex gap-10 mt-20 justify-content-center">
          <button
            className="btn text-main"
            onClick={() => setConfirmDeleteModal(false)}
          >
            Annuler
          </button>
          <button
            className="btn bg-main"
            onClick={() => handleDeleteComment(comment.id)}
          >
            Supprimer
          </button>
        </div>
      </DefaultModal>
    </div>
  );
};
