import { editExpertises } from "api/Expertises/editExpertises";
import { searchOnExpertises } from "api/Expertises/searchExpertises";
import ArrowBackIcon from "components/atoms/icons/arrow-back";
import CloseCircleIcon from "components/atoms/icons/close-circle";
import DefaultModal from "components/molecules/Modals/Default";
import { useEffect, useState } from "react";
import SimilarResult from "./similarResult";

export default function ExpertiseEditionModal({
  isOpen,
  setIsOpen,
  selectedList = [],
  setSelectedList = () => {},
  userId = "",
}) {
  const [list, setList] = useState([]);

  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [similarResult, setSimilarResult] = useState(null);

  const getSearch = async (value) => {
    if (!value.length) return;
    setLoading(true);

    // replace with api call
    const response = await searchOnExpertises(value);

    setLoading(false);
    setList(response);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleAdd = async (e) => {
    e.preventDefault();
    // if expertises already exist
    const exist = selectedList.filter((item) => item.id === search.id).length;
    if (exist) return;
    // if (!search.length) return;
    // if is object
    let response;
    if (typeof search === "object") {
      response = await editExpertises(userId, search.id, null, true);
      setSelectedList([...selectedList, search]);
    } else {
      response = await editExpertises(userId, null, search, false);

      if (response.message === "Similarity") {
        setSimilarResult(response.expertise);
        return;
      }
      setSelectedList([...selectedList, response]);
      setSearch("");
    }

    // setSelectedList([...selectedList, search]);
  };

  const handleSelectSimilar = async (cancel) => {
    if (cancel) {
      const response = await editExpertises(userId, null, search, true);
      setSelectedList([...selectedList, response]);
      setSimilarResult(null);
      setSearch("");
      return;
    }

    const response = await editExpertises(userId, similarResult.id, null, true);
    setSelectedList([...selectedList, response]);
    setSimilarResult(null);
    setSearch("");
  };

  const handleRemove = async (e, value) => {
    e.preventDefault();
    setSelectedList(selectedList.filter((item) => item !== value));
    await editExpertises(userId, value.id, null, true);
  };

  useEffect(() => {
    const timeOutId = setTimeout(() => getSearch(search), 500);
    return () => clearTimeout(timeOutId);
  }, [search]);

  return (
    <DefaultModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      className="expertise-edition-modal"
      title="Missions & Expertises"
      header={false}
    >
      <div className="expertise-edition-modal__content">
        <button
          className="expertise-edition-modal__back-btn"
          onClick={() => setIsOpen(false)}
        >
          <ArrowBackIcon />
          Retour
        </button>
        <h1>Missions & Expertises</h1>
        <form>
          <div className="search-list__container">
            <input
              type="text"
              placeholder="Recrutement"
              onChange={handleSearch}
              value={search.name || search}
            />
            <ul className="search-list__content">
              {!loading ? (
                list.map((item) => (
                  <li onClick={() => setSearch(item)}>{item.name}</li>
                ))
              ) : (
                <li>Chargement...</li>
              )}
            </ul>
          </div>
          <button className="btn btn-small bg-main" onClick={handleAdd}>
            Ajouter
          </button>
        </form>
        {similarResult && (
          <SimilarResult
            newText={similarResult}
            currentText={search}
            onSelected={(value) => {
              handleSelectSimilar(value === search);
            }}
          />
        )}

        <div className="expertise-edition__list">
          {selectedList.map((item) => (
            <span style={{ backgroundColor: "#21297C" }}>
              <button onClick={(e) => handleRemove(e, item)}>
                <CloseCircleIcon />
              </button>
              {item.name}
            </span>
          ))}
        </div>
      </div>
    </DefaultModal>
  );
}
