/* eslint-disable array-callback-return */
import React, { useContext } from "react";
import Item from "./Item";
import moment from "moment";
import NotFound from "../molecules/ErrorsMessages/NotFound";
import { EditMode } from "../../contexts/editmode";
export default function Items({
  events = [],
  deleteOneEvent,
  onlyExceeded = false,
  actionBar = null,
}) {
  const { editing } = useContext(EditMode);

  function filtreClosestDate(sessions) {
    return sessions
      .map(({ startDate }) => startDate)
      .reduce((nearestDate, date) =>
        (moment(date).diff() < moment(nearestDate).diff())
          ? date
          : nearestDate,
      );
  }

  return (
    <React.Fragment>
      {events.length < 1 && <NotFound element="événement" />}
      <div className="events__all-events__container">
        {events.length > 0 && editing
          ? events
            .sort(function (a, b) {
              return (
                new Date(filtreClosestDate(a.EventSession)) -
                new Date(filtreClosestDate(b.EventSession))
              );
            })
            .filter((article) => article && article.Content)
            .map((item, index) => {
              if (onlyExceeded) {
                return (
                  item.Content.state === "ARCHIVED" &&
                  !item.isFirst && (
                    <Item
                      key={index}
                      event={item}
                      deleteOneEvent={deleteOneEvent}
                      actionBar={actionBar}
                    />
                  )
                );
              }
              if (item.Content.state !== "ARCHIVED") {
                return (
                  !item.isFirst && (
                    <Item
                      key={index}
                      event={item}
                      deleteOneEvent={deleteOneEvent}
                      actionBar={actionBar}
                    />
                  )
                );
              }
            })
          : events.length > 0
            ? events
              .filter(
                (article) =>
                  article &&
                  article.Content &&
                  article.Content.state === "PUBLISHED"
              )
              .sort(function (a, b) {
                return (
                  new Date(filtreClosestDate(a.EventSession)) -
                  new Date(filtreClosestDate(b.EventSession))
                );
              })
              .map((item, index) => {
                if (onlyExceeded) {
                  return <></>;
                }
                return (
                  !item.isFirst && (
                    <Item
                      key={index}
                      event={item}
                      deleteOneEvent={deleteOneEvent}
                      actionBar={actionBar}
                    />
                  )
                );
              })
            : ""}
      </div>
    </React.Fragment>
  );
}
