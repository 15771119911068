import axios from "axios";
import getFormatedToken from "../helpers/getFormatedToken";

export async function sendPhoto(formData) {
  try {
    const response = await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/api/library`,
      data: formData,
      ...getFormatedToken(false, true),
    });
    return response.data?.data?.library;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function getPhotos() {
  try {
    const response = await axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/api/library`,
      ...getFormatedToken(false, true),
    });
    return response.data?.data?.library;
  } catch (error) {
    console.log(error);
  }
}

export async function editPhotos(id, data) {
  try {
    const response = await axios({
      method: "PUT",
      url: `${process.env.REACT_APP_API_URL}/api/library/${id}`,
      data: data,
      ...getFormatedToken(false, true),
    });
    return response.data?.data?.library;
  } catch (error) {
    console.log(error);
  }
}

export async function editPhotosFile(id, formData) {
  try {
    const response = await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/api/library/${id}/avatar`,
      data: formData,
      ...getFormatedToken(false, true),
    });
    return response.data?.data?.library;
  } catch (error) {
    console.log(error);
  }
}
export async function deletePhoto(id) {
  try {
    const response = await axios({
      method: "DELETE",
      url: `${process.env.REACT_APP_API_URL}/api/library/${id}`,
      ...getFormatedToken(false, true),
    });
    return response.data?.data?.library;
  } catch (error) {
    console.log(error);
  }
}