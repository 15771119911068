/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";

import "./ContentListShares.css";
import Search from "../../components/Search/SearchFilter";
import ItemListShares from "./Item";
import NotFound from "../molecules/ErrorsMessages/NotFound";
import { EditMode } from "../../contexts/editmode";

//  API
import { fetchAllFiles } from "../../api/Files";
import { fetchAccount } from "../../api/Users/Me";
import { deleteShares } from "../../api/Shares";

const SharesContent = ({ filters }) => {
  const [files, setFiles] = useState([]);
  const [searchFilter, setSearchFilter] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [user, setUser] = useState(null);
  const search = useLocation().search;
  const name = new URLSearchParams(search).get("search");
  const { editing } = useContext(EditMode);

  async function getAllFiles(filters) {
    const temp = await fetchAllFiles(filters);
    let officialFiles = [];
    if (!temp) return;
    temp.forEach((elem) => {
      if (elem.isOfficial) {
        officialFiles.push(elem);
      }
    });
    if (editing) {
      setFiles(temp);
    } else {
      setFiles(officialFiles);
    }
  }

  function deleteOneFile(id) {
    setFiles((files) => {
      return files.filter((file) => file.id !== id);
    });

    deleteShares(id);
  }

  async function getUser() {
    setUser(await fetchAccount());
  }

  useEffect(() => {
    getAllFiles(filters);
    getUser();
  }, [filters]);

  const filteredShares =
    files &&
    files.filter(
      ({ title, hashtags }) =>
        title.toLowerCase().includes(searchFilter) ||
        (hashtags.length > 0 &&
          hashtags[0].toLowerCase().replace(/,/g, " ").includes(searchFilter))
    );

  return (
    <div className="size_shares">
      <Search
        value={name}
        onChange={(filter) => {
          setSearchFilter(filter.toLowerCase());
        }}
        placeholder={"Rechercher un fichier"}
      />
      <div className="scrollListShares">
        {!filteredShares && <NotFound element="Shares" />}
        {filteredShares && Object.keys(filteredShares).length > 0 ? (
          filteredShares
            .sort(function (a, b) {
              return new Date(b.createdAt) - new Date(a.createdAt);
            })
            .map((item, index) => (
              <ItemListShares
                item={item}
                key={index}
                deleteOneFile={deleteOneFile}
              />
            ))
        ) : (
          <NotFound element="Shares" />
        )}
      </div>
    </div>
  );
};

export default SharesContent;
