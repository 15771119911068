import React from "react";

// components
import CenterLayout from "../../../layouts/CenterLayout";
import HomeForm from "./homeForm";

export default function AHome() {
  return (
    <CenterLayout title="Homepage">
      <HomeForm />
    </CenterLayout>
  );
}
