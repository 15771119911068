import React, { useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "rjs-custom-build-ckeditor5-resize-img";
// import Image from "@ckeditor/ckeditor5-image/src/image";
// import ImageResize from "@ckeditor/ckeditor5-image/src/imageresize";

import "./cke.css";

export default function CKEditorBasic({
  placeholder,
  noSave = false,
  updateState = false,
  ckeditorCallback,
}) {
  const [preview, setPreview] = useState(false);

  return (
    <div>
      {preview ? (
        <div
          dangerouslySetInnerHTML={placeholder && { __html: placeholder }}
          className="subtheme__content"
        />
      ) : (
        <CKEditor
          editor={ClassicEditor}
          data={placeholder ? placeholder : " "}
          onChange={(event, editor) => {
            ckeditorCallback(editor.getData());
          }}
          config={{
            ckfinder: {
              uploadUrl: `${process.env.REACT_APP_API_URL}/api/ck-upload/uploads/`,
            },
          }}
        />
      )}

      <div className="btn-ck-box">
        <button
          id="ck-preview"
          onClick={(e) => {
            e.preventDefault();
            setPreview(!preview);
          }}
        >
          {!preview ? "Prévisualiser" : "Éditer"}
        </button>
        {!noSave && (
          <button
            id="ck-save"
            onClick={(e) => {
              updateState && updateState(e);
            }}
          >
            Enregistrer un brouillon
          </button>
        )}
      </div>
    </div>
  );
}
