import axios from "axios";
import getFormatedToken from "../../helpers/getFormatedToken";

export async function GetAllUsers() {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/users`,
      getFormatedToken()
    );

    const { data } = response.data;

    return data;
  } catch (error) {
    // TODO: Handle error properly
    // console.log(error);
  }
}
