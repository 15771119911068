/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import LinksPage from "../../Links/Links";
import CenterLayout from "../../../layouts/CenterLayout";

import { LittleCard } from "../../../components/molecules/AdminPanel/index";
import LinksForm from "./LinksForm";

import { fetchOneLinks } from "../../../api/Links";

export default function ALinksPage({ propsEditing = false }) {
  const [edit, setEdit] = useState(false);
  const [link, setLink] = useState(null);
  const { id } = useParams();

  const getOneLinks = async () => {
    setLink(await fetchOneLinks(id));
  };

  useEffect(() => {
    if (propsEditing && id) {
      getOneLinks();
      setEdit(true);
    }
  }, [propsEditing]);

  return (
    <CenterLayout title="Links" className={false}>
      {edit ? (
        <LinksForm
          onClick={() => setEdit(false)}
          linkProps={propsEditing ? link : null}
        />
      ) : (
        <React.Fragment>
          <LittleCard
            buttonText="Ajouter un lien"
            icon="icon-write-bubble"
            onClick={() => setEdit(true)}
          />
          <LinksPage />
        </React.Fragment>
      )}
    </CenterLayout>
  );
}
