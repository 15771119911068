import React, { Fragment } from "react";
import SideBar from "../components/SideBar/SideBar";
import Footer from "../components/Footer/Footer";
import "./layout.css";
import TopBar from "../components/TopBar";

export default function CenterLayout({ title, children, className }) {
  return (
    <Fragment>
      <TopBar />
      <SideBar />
      <div className="position_from_menu">
        <div className="layout-center__container">
          <h1 className="layout__title">{title}</h1>
          <div
            className={
              className ? "child__container " + className : "child__container"
            }
          >
            {children}
          </div>
        </div>

        <Footer />
      </div>
    </Fragment>
  );
}
