import moment from "moment";

function searchNearestDate(sessions) {
  if (sessions.length <= 0) {
    return (
      <div className="card-event__date">
        <h3>{moment().format("D")}</h3>
        <h3>{moment().format("MMM")}</h3>
      </div>
    );
  }

  const closest = sessions
    .map(({ startDate }) => startDate)
    .reduce((nearestDate, date) =>
      (moment(date).diff() < moment(nearestDate).diff())
        ? date
        : nearestDate,
    );

  return (
    <div className="card-event__date">
      <h3>{moment(closest).format("D")}</h3>
      <h3>{moment(closest).format("MMM")}</h3>
    </div>
  );
}

export default searchNearestDate;
