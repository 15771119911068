import React, { useState } from 'react'

// Context
import { FilterContext } from './context'

// Components
import Filter from './Filter'

const Dropdown = ({ value, render, className, isOpen = false, children }) => {
  const [isCollapsed, setIsCollapsed] = useState(isOpen)

  const getAllFilters = () => React.Children.toArray(children).reduce((accumulator, child) => {
    if (child.type === Filter.Item) {
      accumulator.push(child.props.value)
    }

    return accumulator
  }, [])

  return (
    <FilterContext.Consumer>
      {({ toggleFilter, isFilterActive }) => (
        <div className={className}>
          <div
            onClick={() => {

              setIsCollapsed((isCollapsed) => {
                getAllFilters().map((filter) => toggleFilter(filter, !isCollapsed))
                return !isCollapsed
              })
            }}
          >
            {(value) ? <Filter.Item value={value} render={render} /> : render(isCollapsed)}
          </div>
          {((value === undefined) ? isCollapsed : isFilterActive(value)) && children}
        </div>
      )}
    </FilterContext.Consumer>
  )
}

export default Dropdown
