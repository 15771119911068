import { Link, useLocation } from "react-router-dom";
import "./style.css";
export default function CTAnotFound({ color, thematic_id, contentType }) {
  let location = useLocation();

  return (
    <div className="CTAnotFound">
      <p className="CTAnotFound__message">
        Aucun contenu n’a été ajouté sur cette catégorie jusqu’à présent. Cette
        plateforme étant collaborative, nous comptons sur vous{" "}
        <Link
          style={{ color: "#9d9b9b", fontStyle: "italic" }}
          to={{
            pathname: location.pathname,
            state: {
              proposeContentModal: {
                thematic_id: thematic_id ? thematic_id : 0,
                contentType: contentType ? contentType : 7,
              },
            },
          }}
        >
          pour nous en proposer 
        </Link>
      </p>
    </div>
  );
}
