import axios from "axios";
import getFormatedToken from "../../helpers/getFormatedToken";

export async function fetchOneTheme(id) {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/api/main-themes/${id}`,
    getFormatedToken()
  );
  const { data } = response.data;

  return data.mainTheme;
}
