import React, { useEffect, useState } from "react";
import { GetAllUsers } from "../../../api/Users/GetAllUsers";
import { deleteOneUser } from "../../../api/Users/Users";
import { ThemeIcon } from "../../../components/molecules/IconTheme";
import Search from "../../../components/Search/SearchFilter";
import ReactPaginate from "react-paginate";

import "./UsersList.css";

export default function UsersList() {
  const [users, setUsers] = useState();
  const [searchFilter, setSearchFilter] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  const filterUser = () => {
    let temp = [];
    users.forEach((user) => {
      const name = user.firstname + " " + user.lastname;
      const reverseName = user.lastname + " " + user.firstname;
      if (
        name.toLowerCase().includes(searchFilter.toLowerCase()) ||
        reverseName.toLowerCase().includes(searchFilter.toLowerCase())
      ) {
        temp.push(user);
      }
    });
    return temp;
  };

  async function fetchUser() {
    setUsers(await GetAllUsers());
  }

  const handleUnloadedImage = (e) => {
    console.log("Unloaded");
    e.target.src = "/assets/img/avatar.svg";
  };

  const handleLoadingImage = (e, user) => {
    //e.target.src = imgPath;
    //setImgLoading(false);
    console.log("Loaded");
  };

  const DisplayItem = ({ itemList }) => {
    return (
      <tbody>
        {itemList &&
          itemList.map((user) => (
            <tr style={{ borderBottom: "1px solid #e9e9e9" }}>
              <td>
                {user.avatarPath ? (
                  <>
                    <img
                      src={user.avatarPath}
                      alt={`#avatar-user`}
                      onLoad={(e) => handleLoadingImage(e, user)}
                      onError={(e) => handleUnloadedImage(e)}
                      className="users_list-avatar"
                    />
                  </>
                ) : (
                  <img
                    src={"assets/img/avatar.svg"}
                    alt={"#avatar-default-avatar"}
                    className="users_list-avatar"
                  />
                )}
              </td>
              <td>
                {user.lastname} {user.firstname}
              </td>
              <td>
                {user.Role.length > 0 &&
                  user.Role.map((role) => (
                    <p style={{ padding: "5px" }}>{role.name}</p>
                  ))}
                {user.AdminOfMainThemes.map((theme) => (
                  <p className="users_list_p">
                    <ThemeIcon theme={theme} />
                    {theme.name}
                  </p>
                ))}
                {user.AdminOfSubThemes.map((sub) => (
                  <p className="users_list_p">
                    <div
                      style={{
                        width: "10px",
                        margin: "5px",
                      }}
                      className={`account_bullet-point bg-${sub.color}`}
                    ></div>
                    {sub.name}
                  </p>
                ))}
              </td>
              <td>
                {user.GroupReferentOfMainThemes.map((theme) => (
                  <p className="users_list_p">
                    <ThemeIcon theme={theme} />
                    {theme.name}
                  </p>
                ))}
                {user.GroupReferentOfSubThemes.map((sub) => (
                  <p className="users_list_p">
                    <div
                      style={{
                        width: "10px",
                        margin: "0px 10px 0px 5px",
                      }}
                      className={`account_bullet-point bg-${sub.color}`}
                    />
                    {sub.name}
                  </p>
                ))}
              </td>
              <td>
                {user.ReferentOfMainThemes.map((theme) => (
                  <p className="users_list_p">
                    <ThemeIcon theme={theme} />
                    {theme.name}
                  </p>
                ))}
                {user.ReferentOfSubThemes.map((sub) => (
                  <p className="users_list_p">
                    <div
                      style={{ width: "20px", margin: "5px" }}
                      className={`account_bullet-point bg-${sub.color}`}
                    ></div>
                    {sub.name}
                  </p>
                ))}
              </td>
              <td>
                <a href={"/account/" + user.id}>
                  <i
                    className={"icon-write-bubble"}
                    style={{
                      color: "var(--main-color)",
                      fontSize: "20px",
                      cursor: "pointer",
                      textDecoration: "none",
                    }}
                  />
                </a>
                <i
                  className="icon-trash"
                  style={{
                    color: "red",
                    fontSize: "20px",
                    marginLeft: "20px",
                    cursor: "pointer",
                    textDecoration: "none",
                  }}
                  onClick={() => {
                    if (
                      window.confirm(
                        `Êtes vous sûr de vouloir suprimer l'utilisateur :  ${user.email}?`
                      )
                    ) {
                      deleteOneUser(user.id);
                      setUsers((users) =>
                        users.filter(({ id }) => id !== user.id)
                      );
                    }
                  }}
                />
              </td>
            </tr>
          ))}
      </tbody>
    );
  };

  useEffect(() => {
    fetchUser();
    const theadUsersList = document.getElementById("theadUsersList");
    const tableUsers = document.querySelector(
      ".users_list_container:last-child"
    );

    tableUsers?.addEventListener("scroll", function (e) {
      if (e.target.scrollTop > 0) {
        theadUsersList.style.transform = `translateY(${e.target.scrollTop}px)`;
        theadUsersList.style.backgroundColor = `#e7e7f3`;
      } else {
        theadUsersList.style.backgroundColor = `transparent`;
        theadUsersList.style.transform = `translateY(0px)`;
      }
    });
  }, []);

  useEffect(() => {
    if (searchFilter && searchFilter.length > 0) {
      setFilteredUsers(filterUser());
      setItemOffset(0);
    } else if (users && users.length) {
      setFilteredUsers(users);
    }
  }, [searchFilter, users]);

  //set pagination
  const itemsPerPage = 5;

  useEffect(() => {
    setPageCount(Math.ceil(filteredUsers.length / itemsPerPage));
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(filteredUsers.slice(itemOffset, endOffset));
  }, [filteredUsers, itemOffset]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % filteredUsers.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  return (
    <>
      <div className="users_list_container">
        <Search
          onChange={(filter) => setSearchFilter(filter.toLowerCase())}
          className={"search__reduced"}
        />
      </div>
      <div className="users_list_container">
        <table className="users_list-table">
          <thead id="theadUsersList">
            <tr>
              <th></th>
              <th>Nom Prénom</th>
              <th>Rôle</th>
              <th>Référent Groupe</th>
              <th>Référent Métier</th>
              <th></th>
            </tr>
          </thead>
          <DisplayItem itemList={currentItems} />
        </table>
      </div>
      {currentItems.length < 1 ? (
        <div className="loader"></div>
      ) : (
        <div id="user-pagination-container">
          <ReactPaginate
            pageCount={pageCount}
            nextLabel="Suivant"
            previousLabel="Précédent"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            renderOnZeroPageCount={null}
          />
        </div>
      )}
    </>
  );
}
