/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import ReactCodeInput from "react-code-input";
import Cookies from "universal-cookie";

//:: API imports
import {
  checkMe,
  sendCode,
  sendHelpForm,
  sendLogin,
  sendNewRecoverLink,
} from "../../api/Login";
import { ResetPassword } from "../../api/Users/ResetPassword";
import DefaultModal from "../../components/molecules/Modals/Default";

import "./Login.css";
import getQueryParams from "helpers/getQueryParams";
import { LoginAzure } from "./LoginAzure";
import { LoginClassic } from "./ClassicLogin";

export default function Login({ setter }) {
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [sended, setSender] = useState({ sending: false, success: true });
  const [openModal, setOpenModal] = useState(false);
  const [showClassicLogin, setShowClassicLogin] = useState(false);
  const [openModal0Auth, setOpenModal0Auth] = useState({
    id: null,
    openId: null,
    firstname: null,
    lastname: null,
    avatar: null,
    registration: false,
  });
  const [helpForm, setHelpForm] = useState({
    step: true,
    status: false,
    firstname: null,
    lastname: null,
    email: null,
    message: null,
    sending: false,
    responseMessage: null,
    error: false,
  });
  const [step, setStep] = useState(1);
  const [code, setCode] = useState(null);
  const [user, setUser] = useState(null);
  //REMOVE AFTER AZURE AD LAUNCH FOR USER
  const [showAzure, setShowAzure] = useState(false);
  // ----
  const [authAD, setAuthAD] = useState({
    status: false,
    message: "",
    email: "",
  });
  const [newLinkSent, setNewLinkSent] = useState(false);
  const [resetPasswordInfo, setResetPasswordInfo] = useState({
    loading: false,
    success_message:
      "Si vous êtes inscrit sur le Portail RH, vous avez reçu un e-mail avec vos nouveaux identifiants.<br/>",
    isSent: false,
  });

  // Generate random numbers for the CAPTCHA
  const [num1, setNum1] = useState(Math.floor(Math.random() * 10));
  const [num2, setNum2] = useState(Math.floor(Math.random() * 10));

  // State variables
  const [userAnswer, setUserAnswer] = useState("");
  const [result, setResult] = useState({
    message: "",
    status: false,
  });

  // Function to check the user's answer
  const checkAnswer = () => {
    const correctAnswer = num1 + num2;

    if (isNaN(userAnswer) || userAnswer === "") {
      setResult({ message: "Veuillez entrer un nombre valide", status: false });
    } else if (parseInt(userAnswer) === correctAnswer) {
      setResult({
        message: "Captcha validé",
        status: true,
      });
    } else {
      setResult({
        message: "Veuillez réessayer",
        status: false,
      });
      setNum1(Math.floor(Math.random() * 10));
      setNum2(Math.floor(Math.random() * 10));
      setUserAnswer("");
    }
  };

  const handleInputChange = (e) => {
    setUserAnswer(e.target.value);
  };

  const cookies = new Cookies();

  const handleSenderRes = async (email, password) => {
    try {
      const res = await sendLogin(email, password);
      const { data } = res;

      setSender({
        ...sended,
        sending: false,
        success: true,
      });
      if (data.token) {
        setStep(2);
        setUser(data);
      }
    } catch (error) {
      // TODO: Handle error properly
      // console.log(error);
      setSender({
        ...sended,
        sending: false,
        success: false,
      });
    }
  };

  const handleHelpForm = async () => {
    //check if there is no empty fields in the help form
    if (
      !helpForm.firstname ||
      !helpForm.lastname ||
      !helpForm.email ||
      !helpForm.message
    ) {
      setHelpForm({
        ...helpForm,
        responseMessage: "Veuillez remplir tous les champs",
        sending: false,
        error: true,
      });
      return;
    }
    if (!result.status) {
      setHelpForm({
        ...helpForm,
        responseMessage: "Veuillez remplir le captcha",
        sending: false,
        error: true,
      });
      return;
    }
    await sendHelpForm(helpForm);
    setHelpForm({
      firstname: null,
      lastname: null,
      email: null,
      message: null,
      responseMessage:
        "Votre demande a bien été prise en compte. Nous revenons vers vous dans les plus brefs délais.",
      sending: false,
      error: false,
      status: true,
      step: true,
    });
    //reload page after 3 seconds
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };
  const handleSenderResCode = async (email, code, token) => {
    try {
      const res = await sendCode(email, code, token);
      const { data } = res;
      setter(user.user);

      cookies.set("::token", data.token, {
        secure: false, //<------------- TRUE for SSL / https
        maxAge: 1440 * 60,
      });
      setSender({
        ...sended,
        sending: false,
        success: true,
      });
      document.location.reload();
    } catch (error) {
      // TODO: Handle error properly
      // console.log(error);
      setSender({
        ...sended,
        sending: false,
        success: false,
      });
    }
  };

  const handleResetPassword = async (event) => {
    event.preventDefault();
    if (email && email.length) {
      setResetPasswordInfo({ ...resetPasswordInfo, loading: true });
      try {
        await ResetPassword(email);
      } catch (error) {
        // TODO: Handle error properly
        // console.log(error);
      }
      setResetPasswordInfo({
        ...resetPasswordInfo,
        loading: false,
        isSent: true,
      });
    }
  };

  const checkToken = async (token) => {
    try {
      setSender({ ...sended, sending: true });
      await checkMe(token);
      cookies.set("::token", token, {
        secure: false,
        maxAge: 1440 * 60,
      });
      window.history.replaceState({}, document.title, window.location.pathname);
      document.location.reload();
    } catch (error) {
      console.log("token error");
      setStep(3);
    } finally {
      setSender({ ...sended, sending: false });
    }
  };

  const sendNewLink = async (e) => {
    e.preventDefault();
    try {
      setSender({
        ...sended,
        sending: true,
        success: true,
      });
      const params = getQueryParams(document.location.search);
      await sendNewRecoverLink(params.email);
      setSender({
        ...sended,
        sending: false,
        success: true,
      });
      setNewLinkSent(true);
    } catch (error) {
      setSender({
        ...sended,
        sending: false,
        success: false,
      });
    }
  };

  useEffect(() => {
    const params = getQueryParams(document.location.search);

    if (params.usr && params.email) checkToken(params.usr);

    //REMOVE AFTER AZURE AD LAUNCH FOR USER
    /*     if (params.approbation && params.approbation == "azure") {
      setShowAzure(true);
    } */
    if (params.authad) {
      if (params.authad == "failed") {
        setAuthAD({
          status: true,
          message: params.msg,
          email: params.email,
        });
      } else if (params.authad == "success") {
        cookies.set("::token", params.tk, {
          secure: false,
          maxAge: 1440 * 60,
        });
        //reload page to refresh token
        document.location.href = "/";
      } else if (params.authad == "pending") {
        setOpenModal0Auth({
          status: true,
          id: params.id,
          openId: params.openId,
          firstname: params.fname,
          lastname: params.lname,
          avatar: params.av,
          registration: params.registration,
        });
      }
    }
  }, []);

  if (step === 1) {
    return (
      <>
        <section id="login-body">
          <div className="top-logo">
            <img
              src="/assets/icons/Footer/logo_bouygues.svg"
              alt="logo-topBar"
            />
          </div>
          <div className="form-container">
            <div
              style={{
                backgroundImage: `url(/assets/icons/Footer/logo_HR_team.svg)`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "contain",
                height: "100px",
                width: "100%",
              }}
            ></div>

            <>
              <h2 className="input-box-title">Welcome</h2>
              <div className="input-box">
                <LoginAzure authAD={authAD} />
                <div
                  className={`div-login-microsoft animate ${
                    showClassicLogin ? "show_block" : "hidden_block"
                  }`}
                >
                  <>
                    <div
                      className="email_connect_opt"
                      onClick={() => {
                        setShowClassicLogin(!showClassicLogin);
                      }}
                    >
                      Ou se connecter via email
                      <svg
                        style={{ rotate: showClassicLogin ? "90deg" : "0deg" }}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        width={20}
                        className="animate"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m8.25 4.5 7.5 7.5-7.5 7.5"
                        />
                      </svg>
                    </div>
                  </>
                  <div
                    className="animate"
                    style={{ height: showClassicLogin ? "fit-content" : "0px" }}
                  >
                    <LoginClassic
                      setEmail={setEmail}
                      setPassword={setPassword}
                    />
                    <p className="paragraph-msg-warning">
                      Veillez à bien copier/coller votre mot de passe, <br />
                      sans espace (ni avant ni après)
                    </p>
                    <p style={{ textAlign: "right" }}>
                      <button
                        className="reset-password"
                        onClick={() => setOpenModal(true)}
                      >
                        Mot de passe oublié ?
                      </button>
                      <button
                        className="reset-password"
                        onClick={() =>
                          setHelpForm({
                            ...helpForm,
                            status: true,
                          })
                        }
                      >
                        Besoin d'aide ?
                      </button>
                    </p>
                    {sended.sending ? (
                      <button className="button-login" disabled>
                        <div className="loader" />
                      </button>
                    ) : (
                      <button
                        className="button-login"
                        onClick={() => {
                          handleSenderRes(email, password);
                          setSender({ ...sended, sending: true });
                        }}
                      >
                        SE CONNECTER
                      </button>
                    )}
                  </div>
                </div>
              </div>
              {!sended.success && (
                <div className="error-popup">
                  <h3>ERREUR</h3>
                  <p>Veuillez vérifier votre EMail ou votre mot de passe</p>
                </div>
              )}
            </>

            {/*          <div className="div-login-microsoft">
              <p className="p-login-microsoft">
                Connexion <br />
                HRTEAM à <strong>Microsoft - Azure</strong>
              </p>
              <button
                className="button-login-microsoft"
                style={{ marginTop: "10px" }}
                onClick={(e) => {
                  e.preventDefault();
                  window.open(
                    `${process.env.REACT_APP_API_URL}/api/azure/signin/`,
                    "_self"
                  );
                }}
              >
                <img src="/assets/img/microsoft-logo.png" height={20} />
                S'IDENTIFIER
              </button>
              {authAD.status && (
                <div className="error-popup">
                  <h3>ERREUR</h3>
                  <p>{authAD.message}</p>
                </div>
              )}
            </div>
 */}
            {authAD.status && (
              <div className="error-popup">
                <h3>INFORMATION</h3>
                <p>
                  {authAD.message}{" "}
                  <span
                    style={{
                      cursor: "pointer",
                      fontWeight: 700,
                      textDecoration: "underline",
                    }}
                    onClick={() =>
                      setHelpForm({
                        ...helpForm,
                        status: true,
                      })
                    }
                  >
                    notre formulaire
                  </span>
                  {authAD.email && (
                    <>
                      <br /> <br />
                      Vous essayez de vous connecter avec l'email :{" "}
                      <span style={{ fontWeight: 700 }}>
                        {authAD.email}
                      </span>{" "}
                      qui ne correspond à aucun compte existant.
                      <br />
                      <button
                        onClick={() => {
                          window.open(
                            `${process.env.REACT_APP_API_URL}/api/azure/signout`,
                            "_self"
                          );
                        }}
                        className="error_change_azure_account"
                      >
                        Changer de compte
                        {/*  <LogoutIcon /> */}
                      </button>
                    </>
                  )}
                </p>
              </div>
            )}
          </div>
        </section>
        <DefaultModal
          isOpen={openModal}
          setIsOpen={setOpenModal}
          title="Mot de passe oublié"
        >
          {!resetPasswordInfo.isSent ? (
            <form onSubmit={handleResetPassword}>
              <div className="input_container">
                <label form="password">Adresse mail*</label>
                <input
                  type="text"
                  placeholder="E-Mail"
                  name="email"
                  defaultValue={email}
                  onChange={(event) => setEmail(event.target.value)}
                />
              </div>
              <br />
              {resetPasswordInfo.loading ? (
                <button className="btn btn-small bg-main" disabled>
                  <div className="loader"></div>
                </button>
              ) : (
                <button type="submit" className="btn btn-small bg-main ">
                  Réinitialiser
                </button>
              )}
            </form>
          ) : (
            <div>
              <h1
                style={{ textAlign: "center" }}
                dangerouslySetInnerHTML={{
                  __html: resetPasswordInfo.success_message,
                }}
              ></h1>
              <br />
              <p style={{ textAlign: "center" }}>
                <button
                  onClick={() => {
                    setOpenModal(false);
                    setResetPasswordInfo({
                      loading: false,
                      success_message:
                        "Si vous êtes inscrit sur le Portail RH, vous avez reçu un e-mail avec vos nouveaux identifiants.<br/> <br/>",
                      isSent: false,
                    });
                  }}
                  className="btn btn-small bg-main "
                >
                  Fermer
                </button>
              </p>
            </div>
          )}
        </DefaultModal>
        {/*         <DefaultModal
          isOpen={openModal0Auth.status}
          setIsOpen={setOpenModal0Auth}
          title="Lier votre compte Microsoft"
        >
          <div className="input_container">
            <p>
              Un compte existant avec cette adresse mail a été trouvé. <br />
              <br />
              Voulez vous lier votre compte Microsoft à ce compte ?
              <br />
            </p>
            <div>
              <button
                className="button-login"
                onClick={() => {
                  handleLinkAzureAd(false);
                }}
              >
                Lier mon compte
              </button>
              <button
                className="cancel-auth-link"
                onClick={() => (window.location.href = "/login")}
              >
                Annuler
              </button>
            </div>
          </div>
        </DefaultModal> */}
        <DefaultModal
          isOpen={helpForm.status}
          setIsOpen={setHelpForm}
          title="Besoin d'aide ?"
        >
          <div className="input_container">
            <div>
              <div className="container-button-contact-help">
                <span
                  className={helpForm.step ? "active" : ""}
                  onClick={(e) => {
                    e.preventDefault();
                    setHelpForm({
                      ...helpForm,
                      step: true,
                    });
                  }}
                >
                  Nous envoyer un message
                </span>
                <span
                  className={!helpForm.step ? "active" : ""}
                  onClick={(e) => {
                    e.preventDefault();
                    setHelpForm({
                      ...helpForm,
                      step: false,
                    });
                  }}
                >
                  Nous appeler
                </span>
              </div>
              {helpForm.step ? (
                <form className="form-contact-mail">
                  <span>
                    <input
                      type="text"
                      placeholder="Prénom"
                      required
                      onChange={(e) => {
                        setHelpForm({
                          ...helpForm,
                          firstname: e.target.value,
                        });
                      }}
                    />
                    <input
                      type="text"
                      placeholder="Nom"
                      required
                      onChange={(e) => {
                        setHelpForm({
                          ...helpForm,
                          lastname: e.target.value,
                        });
                      }}
                    />
                  </span>
                  <input
                    type="email"
                    placeholder="Adresse mail"
                    required
                    onChange={(e) => {
                      setHelpForm({
                        ...helpForm,
                        email: e.target.value,
                      });
                    }}
                  />
                  <textarea
                    type="text"
                    placeholder="Décrivez votre problème..."
                    required
                    onChange={(e) => {
                      setHelpForm({
                        ...helpForm,
                        message: e.target.value,
                      });
                    }}
                  />
                  <span id="span-cap-label">Veuillez résoudre ce Captcha</span>
                  <div id="captcha">
                    <span>
                      <span>
                        {num1} + {num2} =
                      </span>
                      <input
                        type="number"
                        className="captcha-input"
                        value={userAnswer}
                        onChange={handleInputChange}
                        placeholder="Répondez"
                      />
                    </span>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        checkAnswer();
                      }}
                    >
                      Check
                    </button>
                  </div>
                  <p
                    id="result"
                    className={`${
                      result.message.length
                        ? result.status
                          ? "result-valid"
                          : "result-error"
                        : ""
                    }`}
                  >
                    {result.message}
                  </p>
                </form>
              ) : (
                <div>
                  <a className="help-tel-a" href="tel:000102030405">
                    00 01 02 03 04 05
                  </a>
                  <br />
                  <span className="info-tel">
                    <p>Du lundi au vendredi de 9h à 18h</p>
                  </span>
                </div>
              )}
            </div>

            {helpForm.step && (
              <div>
                {helpForm.responseMessage && helpForm.error && (
                  <div className="error-popup">
                    <h3>ERREUR</h3>
                    <p>{helpForm.responseMessage}</p>
                  </div>
                )}
                {helpForm.responseMessage && !helpForm.error && (
                  <div className="success-popup">
                    <h3>SUCCÈS</h3>
                    <p>{helpForm.responseMessage}</p>
                  </div>
                )}
                <button
                  className="button-login"
                  onClick={() => {
                    setHelpForm({
                      ...helpForm,
                      sending: true,
                    });
                    handleHelpForm();
                  }}
                >
                  Envoyer
                </button>
                <button
                  className="cancel-auth-link"
                  onClick={() => (window.location.href = "/login")}
                >
                  Annuler
                </button>
              </div>
            )}
          </div>
        </DefaultModal>
      </>
    );
  } else if (step === 2) {
    return (
      <section id="login-body">
        <div className="top-logo">
          <img src="/assets/icons/Footer/logo_bouygues.svg" alt="logo-topBar" />
        </div>
        <div className="form-container">
          <div className="input-code-validation">
            <img
              src="/assets/icons/Footer/logo_HR_team.svg"
              alt="logo-input-box"
            />
            <p>
              Un mail vous a été envoyé
              <br />à l'adresse <span>{email}</span>
              <br />
              avec un code de vérification unique.
            </p>
            <ReactCodeInput
              type="number"
              fields={6}
              className="input-code"
              inputStyle={{
                fontFamily: "monospace",
                margin: "4px",
                MozAppearance: "textfield",
                width: "44px",
                borderRadius: "7px",
                fontSize: "14px",
                height: "33px",
                color: "#000",
                textAlign: "center",
              }}
              onChange={(value) => setCode(value)}
            />
            {!sended.success ? (
              <div className="error-popup">
                <h3>ERREUR</h3>
                <p>Mauvais code de vérification unique.</p>
              </div>
            ) : (
              ""
            )}
            {sended.sending ? (
              <button className="button-login" disabled>
                <div className="loader"></div>
              </button>
            ) : (
              <button
                className="button-login"
                onClick={() => {
                  handleSenderResCode(email, code, user.token);
                  setSender({ ...sended, sending: true });
                }}
              >
                VÉRIFIER
              </button>
            )}
          </div>
        </div>
      </section>
    );
  } else if (step === 3) {
    return (
      <section id="login-body">
        <div className="top-logo">
          <img src="/assets/icons/Footer/logo_bouygues.svg" alt="logo-topBar" />
        </div>
        <div className="form-container">
          <div className="input-code-validation">
            <img
              src="/assets/icons/Footer/logo_HR_team.svg"
              alt="logo-input-box"
              style={{ width: "100%", maxWidth: "300px" }}
            />
            {!newLinkSent ? (
              <>
                <p>
                  Votre lien de connexion est expiré.
                  <br />
                  <br />
                  {/* Vous allez recevoir un nouveau lien de connexion par mail. */}
                  Cliquez sur le bouton ci-dessous pour recevoir <br />
                  un nouveau lien de connexion par mail.
                </p>
                {!sended.success ? (
                  <div className="error-popup">
                    <h3>ERREUR</h3>
                    <p>essayez de rouvrir le lien reçu par mail.</p>
                  </div>
                ) : (
                  ""
                )}
                {sended.sending ? (
                  <button className="button-login" disabled>
                    <div className="loader"></div>
                  </button>
                ) : (
                  <button className="button-login" onClick={sendNewLink}>
                    Renvoyer le lien
                  </button>
                )}
              </>
            ) : (
              <>
                <p>Un nouveau lien de connexion vous a été envoyé</p>
              </>
            )}
          </div>
        </div>
      </section>
    );
  }
}
