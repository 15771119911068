import CategoryButton from "./CategoryButton";
import React, { forwardRef, useState } from "react";

/**
 * @param   { function } onChange
 * @return  { ref }
 */
const FilterCategory = forwardRef(({ onClick }, ref) => {
  const [Library, setLibrary] = useState()
  const [HRTrends, setHRTrends] = useState()

  return (
    <div className="filter_category" onClick={onClick(`${Library},${HRTrends}`)}>
      <p className="filter_title">Catégorie</p>
      <div className="filter_category_button_group">
        <CategoryButton
          srcPic="/assets/icons/CategorieFilter/iconLibrary.svg"
          title="Library"
          value="Library"
          onClick={(value) => setLibrary(value)}
        />
        <CategoryButton
          srcPic="/assets/icons/CategorieFilter/iconHrTrends.svg"
          title="HR Trends"
          value="HRTrends"
          onClick={(value) => setHRTrends(value)}
        />
      </div>
    </div>
  );
});

export default FilterCategory;
