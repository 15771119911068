import axios from "axios";
import getFormatedToken from "../../helpers/getFormatedToken";

export async function fetchProfile(id) {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/api/users/${id}`,
    getFormatedToken()
  );

  const { data } = response.data;
  return data.user;
}
