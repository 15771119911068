import { useState, useEffect } from "react";
import React from "react";
import { Link } from "react-router-dom";
import { SwiperSlide } from "swiper/react";
import { useParams } from "react-router-dom";
import moment from "moment";
import "./style.css";
import "./newscontent.css";

//components
import DefaultLayout from "../../layouts/DefaultLayout";
import GradientCard from "../../components/molecules/Cards/Gradient";

// api
import { fetchOneArticle } from "../../api/News/Article";
import { fetchOneContent } from "../../api/News/Content";
import { fetchAllNews } from "../../api/News/News";

// molecules
import { Slider } from "../../components/molecules/Slider/sliderWithCTA";
import Theme from "../../components/molecules/Thematics/Icons";

export default function Article() {
  const [article, setArticle] = useState([]);
  const [articleContent, setArticleContent] = useState({});
  const [news, setNews] = useState([]);
  const { article_id } = useParams();

  async function getOneArticle(article_id) {
    setArticle(await fetchOneArticle(article_id));
    setArticleContent(await fetchOneContent(article_id));
  }

  async function getAllNews() {
    setNews(await fetchAllNews([]));
  }

  useEffect(() => {
    if (article_id) {
      getOneArticle(article_id);
      getAllNews();
    }
  }, [article_id]);

  const { title, author, bannerImgPath, createdAt, MainThemes } = article;
  return (
    <DefaultLayout className="news_article">
      <div className="news__container">
        {bannerImgPath && (
          <header className="news__header">
            <img src={bannerImgPath ? bannerImgPath : ""} alt={title} />
          </header>
        )}
        <main>
          <section className="news__main">
            <div className="event__main__title">
              <div className="event__thematic">
                {MainThemes &&
                  MainThemes.length > 0 &&
                  MainThemes.map((mainThemes, index) => (
                    <Theme key={index} theme={mainThemes} />
                  ))}
              </div>
              <div className="event__title">
                <h1>{title}</h1>
              </div>
            </div>
            <div
              className="subtheme__content"
              dangerouslySetInnerHTML={{ __html: articleContent.html }}
            ></div>
            <p className="news__author">
              <span>
                {`Article publié le ${moment(createdAt).format("DD/MM/YYYY")} ${author ? "par " + author : ""
                  }`}
              </span>
            </p>
          </section>
          <section className="news__next-news">
            <Slider
              cta={{ text: "Toutes les news", path: "/news" }}
              title="News"
            >
              {news
                .filter(
                  (article) => article.NewsContent[0].Content.state !== "DRAFT"
                )
                .sort(function (a, b) {
                  return new Date(b.createdAt) - new Date(a.createdAt);
                })
                .map((item, index) => {
                  return (
                    index < 3 && (
                      <SwiperSlide key={index}>
                        <Link to={`/news/${item.id}`}>
                          <GradientCard content={item} />
                        </Link>
                      </SwiperSlide>
                    )
                  );
                })}
            </Slider>
          </section>
        </main>
      </div>
    </DefaultLayout>
  );
}
