import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import _ from "underscore";
import "./style.css";
import Opportunities from "../../../../components/Welcome/Opportunities";
import { fetchAllNewcomers } from "../../../../api/Newcomers";
import { fetchAllOpportunities } from "../../../../api/Opportunities";

export default function NewsComersList() {
  const [opportunities, setOpportunities] = useState([]);

  async function getAllOpportunities() {
    setOpportunities(await fetchAllOpportunities());
  }

  const [newcomers, setNewcomers] = useState([]);

  async function getAllNewcomers() {
    const [first, second, third] = _.flatten(
      _.map(await fetchAllNewcomers(), _.values)
    ).reverse();

    /** Keep only the last three newcomers */
    setNewcomers([first, second, third]);
  }

  useEffect(() => {
    getAllNewcomers();
    getAllOpportunities();
  }, []);

  return (
    <>
      <div className="right-container">
        <h2>
          <i className="icon-mouvement"></i> Welcome
        </h2>
        {newcomers?.map((newcomer) => (
          <div className="welcome__item">
            <div className="welcome__second_container">
              <img
                className="welcome__image"
                src={newcomer?.avatarUrl || "/assets/icons/user-solid.svg"}
                alt={newcomer?.fullName || "newcomer"}
              />
              <div className="welcome__third_container">
                <p>{newcomer?.fullName}</p>
                <p className="welcome__p1">{newcomer?.job}</p>
                <p className="welcome__p3">{newcomer?.expertise}</p>
                <p className="welcome__p3">{newcomer?.Branch?.name}</p>
              </div>
            </div>
          </div>
        ))}
        <div className="alignright">
          <Link to="/welcome" className="troispetitspoints">
            <span>.</span>
            <span>.</span>
            <span>.</span>
          </Link>
        </div>
      </div>
      <div className="welcome__container-mobyclic">
        <Opportunities
          opportunities={opportunities}
          isHome={true}
          class="right"
        />
      </div>
    </>
  );
}
