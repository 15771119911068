import "./shortbanner.css";

export function ShortBanner({ image, baseline }) {
  return (
    <div
      className="short-banner__container"
      style={{ backgroundImage: `url(${image})` }}
    >
      <div className="layout__title">{baseline}</div>
    </div>
  );
}
