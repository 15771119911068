import React from "react";

import NewsPage from "./NewsPage";
import CenterLayout from "../../layouts/CenterLayout";

export default function News() {
  return (
    <CenterLayout title="News" className="Shares_Pages News_Pages">
      <NewsPage />
    </CenterLayout>
  );
}
