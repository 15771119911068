import React, { useState, useEffect } from "react";
import { Slider } from "../molecules/Slider/sliderWithCTA";
import { fetchAllNews } from "../../api/News/News";
import NotFound from "../molecules/ErrorsMessages/NotFound";
import GradientCard from "../molecules/Cards/Gradient";
import { Link } from "react-router-dom";
import { SwiperSlide } from "swiper/react";

export default function HomeNewsContent() {
  const [news, setNews] = useState([]);

  async function getAllNews() {
    setNews(await fetchAllNews([]));
  }

  useEffect(() => {
    getAllNews();
  }, []);

  return (
    <div className="home__news">
      {news && news.length ? (
        <Slider cta={{ text: "Toutes les news", path: "/news" }} title="News">
          {news
            .filter(
              (article) => article.NewsContent[0].Content.state !== "DRAFT"
            )
            .slice(0)
            .sort(function (a, b) {
              return new Date(b.createdAt) - new Date(a.createdAt);
            })
            .map((item, index) => {
              return index < 3 ? (
                <SwiperSlide key={index}>
                  <Link to={`/news/${item.id}`}>
                    <GradientCard content={item} />
                  </Link>
                </SwiperSlide>
              ) : (
                ""
              );
            })}
        </Slider>
      ) : (
        <NotFound element="News" />
      )}
    </div>
  );
}
