import React from "react";
import useIosInstallPrompt from "./useIosInstallPrompt";
import useWebInstallPrompt from "./useWebInstallPrompt";

export const InstallPWA = () => {
  const [iosInstallPrompt, handleIOSInstallDeclined] = useIosInstallPrompt();
  const [webInstallPrompt, handleWebInstallDeclined, handleWebInstallAccepted] =
    useWebInstallPrompt();

  if (!iosInstallPrompt && !webInstallPrompt) {
    return null;
  }
  return (
    <div>
      {iosInstallPrompt && (
        <>
          <div className="text-center">
            Tap then &quot;Add to Home Screen&quot;
          </div>
          <div className="d-flex justify-content-center">
            <button onClick={handleIOSInstallDeclined}>Close</button>
          </div>
        </>
      )}
      {webInstallPrompt && (
        <div className="d-flex justify-content-around">
          <button color="primary" onClick={handleWebInstallAccepted}>
            Install
          </button>
          <button onClick={handleWebInstallDeclined}>Close</button>
        </div>
      )}
    </div>
  );
};
