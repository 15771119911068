export default function countPercentCompletionProfil(user) {
    let countCompleteInfo = 0;
    if (!user.phone) countCompleteInfo++;
    if (!user.job) countCompleteInfo++;
    if (!user.Entity?.id) countCompleteInfo++;
    if (!user.geolocation.length) countCompleteInfo++;
    if (!user.bannerPath) countCompleteInfo++;
    if (!user.avatarPath) countCompleteInfo++;
    if (!user.Expertise.length) countCompleteInfo++;

    // les informations de base du profil valent 5 points sur 10
    const percent = Math.round((100 - (countCompleteInfo * 100) / 10));

    return percent || 0;
}