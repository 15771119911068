import React from "react";
import SideBar from "../components/SideBar/SideBar";
import Footer from "../components/Footer/Footer";
import "./layout.css";
import TopBar from "../components/TopBar";

export default function HomeLayout({ children }) {
  return (
    <React.Fragment>
      <TopBar />
      <SideBar />
      <div className="position_from_menu">
        <div className="layout-home__container">
          <div className="child__container">{children}</div>
        </div>
        <Footer />
      </div>
    </React.Fragment>
  );
}
