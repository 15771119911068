export default function PhoneIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14.677"
      height="14.708"
      viewBox="0 0 14.677 14.708"
    >
      <g transform="translate(-0.539)">
        <g transform="translate(0.539 0)">
          <path
            d="M14.831,10.794,12.779,8.742a1.365,1.365,0,0,0-2.272.513,1.4,1.4,0,0,1-1.613.88A6.329,6.329,0,0,1,5.082,6.323a1.329,1.329,0,0,1,.88-1.613,1.365,1.365,0,0,0,.513-2.272L4.422.385a1.464,1.464,0,0,0-1.979,0L1.05,1.778c-1.393,1.466.147,5.351,3.592,8.8s7.331,5.058,8.8,3.592l1.393-1.393A1.464,1.464,0,0,0,14.831,10.794Z"
            transform="translate(-0.539 0)"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
}
