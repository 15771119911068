import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Item from "../Item";
import { TopBarItems } from "../../../utils/topbar.json";
import { fetchAllNotifications } from "../../../api/Notifications/Notifications";

const Profile = ({ data: { firstname, lastname, avatarPath } }) => {
  const [notif, setNotif] = useState([]);

  async function getNotifications() {
    setNotif(await fetchAllNotifications());
  }
  let location = useLocation();

  useEffect(() => {
    getNotifications();
  }, [location]);

  return (
    <div className="mob-navbar__profile">
      <div>
        <img src={avatarPath} alt={firstname + lastname || "user_name"} />
        <div className="mob-navbar__profile-name">
          <h4>
            {firstname} {lastname}
          </h4>
          <Link to="/account">Voir mon profil</Link>
        </div>
      </div>
      <div>
        <Item data={TopBarItems[1]} notif={notif} setNotif={setNotif} />
        {/* <Item data={TopBarItems[2]} /> */}
      </div>
    </div>
  );
};
export default Profile;
