import React from "react";
import CenterLayout from "../../layouts/CenterLayout";
import QuestionPage from "./QuestionPage";

export default function Questions() {
  return (
    <CenterLayout title="FAQ" className={false}>
      <QuestionPage />
    </CenterLayout>
  );
}
