/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Links from "../../Links/Links";

import { fetchAllLinks } from "../../../api/Links";

export default function LinksByThematic({ color, sub_theme, thematic_id }) {
  const [links, setLinks] = useState([]);

  async function getAllLinks() {
    if (sub_theme) {
      setLinks(await fetchAllLinks([`sub_theme:${sub_theme}`]));
    } else if (thematic_id) {
      setLinks(await fetchAllLinks([`main_theme:${thematic_id}`]));
    }
  }

  useEffect(() => {
    if (thematic_id || sub_theme) {
      getAllLinks();
    }
  }, [thematic_id, sub_theme]);

  return (
    <div className="subtheme__links">
      <h2 className={"subtheme__subtitle text-" + color}>links</h2>
      <Links items={links} />
    </div>
  );
}
