/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import HomeLayout from "../../layouts/HomeLayout";
import "./style.css";
import HomeNewsContent from "../../components/ContentListNews/HomeNewsContent";
import HomeEventsContent from "../../components/Events/HomeEventsContent";
import HomeSharesContent from "../../components/ContentListShares/HomeSharesContent";
import HomeLinksContent from "../../components/Links/HomeLinksContent";

import ThematicsList from "../../components/molecules/Thematics/List";
import LastMessage from "../../components/molecules/Feed/LastMessage";
import NewsComersList from "../../components/molecules/NewComers/List";
import HRTrendsList from "../../components/molecules/HRTrends/HRTrends";
import DefaultModal from "../../components/molecules/Modals/Default";
import { fetchDashboard } from "../../api/Dashboard";
import { EditMode } from "../../contexts/editmode";
import { HomeModal } from "../../contexts/homepopup";
import { fetchAccount } from "api/Users/Me";

export default function Home() {
  let history = useHistory();

  const [dashboard, setDashboard] = useState();
  const { editing } = useContext(EditMode);
  const { isOpen, setIsOpen } = useContext(HomeModal);
  const [completeProfile, setCompleteProfile] = useState({
    isOpen: false,
    empty: {
      phone: false,
      job: false,
      entityId: false,
      geolocation: false,
      bannerPath: false,
      avatarPath: false,
      Expertise: false,
    },
  });

  async function getDashboard() {
    setDashboard(await fetchDashboard());
  }

  async function checkUserIsComplete() {
    const completeProfilePreference = localStorage.getItem(
      "completeProfilePreference"
    );
    if (completeProfilePreference === "false") return;

    const user = await fetchAccount();
    if (!user) return;

    if (
      !user.phone ||
      !user.job ||
      !user.Entity?.id ||
      !user.geolocation.length ||
      !user.bannerPath ||
      !user.avatarPath ||
      !user.Expertise.length
    ) {
      let countCompleteInfo = 0;
      if (!user.phone) countCompleteInfo++;
      if (!user.job) countCompleteInfo++;
      if (!user.Entity?.id) countCompleteInfo++;
      if (!user.geolocation.length) countCompleteInfo++;
      if (!user.bannerPath) countCompleteInfo++;
      if (!user.avatarPath) countCompleteInfo++;
      if (!user.Expertise.length) countCompleteInfo++;

      // les informations de base du profil valent 5 points sur 10
      const percent = Math.round(100 - (countCompleteInfo * 100) / 10);

      setCompleteProfile({
        isOpen: true,
        empty: {
          phone: !user.phone,
          job: !user.job,
          entityId: !user.Entity?.id,
          geolocation: !user.geolocation.length,
          bannerPath: !user.bannerPath,
          avatarPath: !user.avatarPath,
          Expertise: !user.Expertise.length,
        },
        percent: percent,
      });
    }
  }

  useEffect(() => {
    if (editing) {
      history.push("/admin");
    }
    checkUserIsComplete();
    getDashboard();
  }, []);

  return (
    <>
      <HomeLayout>
        <div className="home__container">
          <div className="home__main-container">
            <HomeNewsContent />
            {dashboard && dashboard.n1 && (
              <div className="home__numbers-container">
                <div
                  className="home__main-number"
                  style={{
                    backgroundImage: `url(${dashboard.bannerImgPath})`,
                  }}
                >
                  <h2>
                    {/* {new Intl.NumberFormat("fr-FR").format(dashboard.n1.value)} */}
                    {dashboard.n1.value}
                  </h2>
                  <p>{dashboard.n1.label}</p>
                </div>
                <div className="home__secondary-numbers">
                  <div>
                    <h2>
                      {/* {new Intl.NumberFormat("fr-FR").format(
                        dashboard.n2.value
                      )} */}
                      {dashboard.n2.value}
                    </h2>
                    <p>{dashboard.n2.label}</p>
                  </div>
                  <div>
                    <h2>
                      {/* {new Intl.NumberFormat("fr-FR").format(
                        dashboard.n3.value
                      )} */}
                      {dashboard.n3.value}
                    </h2>
                    <p>{dashboard.n3.label}</p>
                  </div>
                  <div>
                    <h2>
                      {/* {new Intl.NumberFormat("fr-FR").format(
                        dashboard.n4.value
                      )} */}
                      {dashboard.n4.value}
                    </h2>
                    <p>{dashboard.n4.label}</p>
                  </div>
                </div>
              </div>
            )}
            <HomeEventsContent />
            <HomeSharesContent />
            <HomeLinksContent />
          </div>
          <div className="home__right-bar">
            <ThematicsList />
            <LastMessage />
            <NewsComersList />
            <HRTrendsList limit={3} isHome={true} />
          </div>
        </div>
      </HomeLayout>

      <DefaultModal
        isOpen={completeProfile.isOpen}
        setIsOpen={(isOpen) =>
          setCompleteProfile({ ...completeProfile, isOpen })
        }
        className="complete-profile-modal"
        title="Pour favoriser le partage et l’échange,<br/> pensez à compléter votre profil !"
      >
        <p>
          Votre profil est actuellement complété à{" "}
          <b className="text-main">{completeProfile.percent}%</b>
        </p>
        <div className="progress-bar">
          <div
            className="progress-bar__progress"
            style={{ width: completeProfile.percent + "%" }}
          ></div>
        </div>
        <p>Vous pouvez rajouter :</p>
        <ul>
          {completeProfile.empty.phone && <li>Un numéro de téléphone</li>}
          {completeProfile.empty.job && <li>Un poste</li>}
          {completeProfile.empty.entityId && <li>Une entité</li>}
          {completeProfile.empty.geolocation && <li>Une adresse</li>}
          {completeProfile.empty.bannerPath && <li>Une bannière</li>}
          {completeProfile.empty.avatarPath && <li>Un avatar</li>}
          {completeProfile.empty.Expertise && <li>Des expertises</li>}
        </ul>

        <hr />
        <div className="mt-30">
          <button
            className="btn bg-main"
            onClick={() => {
              setCompleteProfile({ ...completeProfile, isOpen: false });
              history.push("/account");
            }}
          >
            Compléter mon profil
          </button>
          <button
            className="btn bg-transparent text-main"
            onClick={() => {
              localStorage.setItem("completeProfilePreference", "false");
              setCompleteProfile({ ...completeProfile, isOpen: false });
            }}
          >
            Ne plus afficher
          </button>
        </div>
      </DefaultModal>
    </>
  );
}
