import axios from "axios";
import getFormatedToken from "../../helpers/getFormatedToken";

export async function fetchOneEvent() {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/api/events/one-event`,
    getFormatedToken()
  );

  const { data } = response.data;

  return data.event;
}
