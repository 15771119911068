import moment from "moment";

export default function filtersCompose(filtersArray) {
  let mainThemes = [];
  let subThemes = [];
  let branches = [];
  let startDate = [];
  let endDate = [];
  let isOfficial = [];
  let isPublished = [];
  let filtersString = "";

  filtersArray.forEach((row, index) => {
    if (row.includes("main_theme")) {
      mainThemes.push(row.split(":")[1]);
    } else if (row.includes("sub_theme")) {
      subThemes.push(row.split(":")[1]);
    } else if (row.includes("branch")) {
      branches.push(row.split(":")[1]);
    } else if (row.includes("startDate") && row.split(":")[1] !== "") {
      startDate.push(row.split(":")[1]);
    } else if (row.includes("endDate") && row.split(":")[1] !== "") {
      endDate.push(row.split(":")[1]);
    } else if (row.includes("isOfficial")) {
      isOfficial.push(row.slice(11));
    } else if (row.includes("isPublished")) {
      isPublished.push(row.slice(12));
    }
  });

  if (filtersArray.length > 0) {
    if (mainThemes.length > 0 || subThemes.length > 0 || branches.length > 0) {
      filtersString += "?";
    }
    if (mainThemes.length > 0) {
      filtersString += "mainThemes[]=";
      mainThemes.forEach((row, index) => {
        filtersString += row;
        if (index !== mainThemes.length - 1) {
          filtersString += "&mainThemes[]=";
        }
      });
      if (
        subThemes.length > 0 ||
        branches.length > 0 ||
        startDate.length > 0 ||
        endDate.length > 0
      ) {
        filtersString += "&";
      }
    }
    if (subThemes.length > 0) {
      filtersString += "subThemes[]=";
      subThemes.forEach((row, index) => {
        filtersString += row;
        if (index !== subThemes.length - 1) {
          filtersString += "&subThemes[]=";
        }
      });
      if (branches.length > 0 || startDate.length > 0 || endDate.length > 0) {
        filtersString += "&";
      }
    }
    if (branches.length > 0) {
      filtersString += "branches[]=";
      branches.forEach((row, index) => {
        filtersString += row;
        if (index !== branches.length - 1) {
          filtersString += "&branches[]=";
        }
      });
      if (startDate.length > 0 || endDate.length > 0) {
        filtersString += "&";
      }
    }
    if (startDate.length > 0) {
      if (
        mainThemes.length < 1 &&
        subThemes.length < 1 &&
        branches.length < 1
      ) {
        filtersString += "?";
      }
      filtersString += "startDate=";
      startDate.forEach((row, index) => {
        filtersString += moment(row).toISOString();
      });
      if (endDate.length > 0) {
        filtersString += "&";
      }
    }
    if (endDate.length > 0) {
      if (
        mainThemes.length < 1 &&
        subThemes.length < 1 &&
        branches.length < 1 &&
        startDate.length < 1
      ) {
        filtersString += "?";
      }
      filtersString += "endDate=";
      endDate.forEach((row, index) => {
        filtersString += moment(row).toISOString();
      });
    }
    if (isOfficial.length > 0) {
      isOfficial.forEach((row) => {
        let official = row.split(",");
        let Library = false;
        let HRTrends = false;
        official.forEach((row0) => {
          if (row0.includes("Library")) {
            Library = row0.split(":")[1];
          }
          if (row0.includes("HRTrends")) {
            HRTrends = row0.split(":")[1];
          }
        });

        if (Library === "true" && HRTrends === "false") {
          if (
            subThemes.length > 0 ||
            branches.length > 0 ||
            startDate.length > 0 ||
            endDate.length > 0 ||
            mainThemes.length > 0
          ) {
            filtersString += "&";
          } else {
            filtersString += "?";
          }
          filtersString += "isOfficial=true";
        }
        if (Library === "false" && HRTrends === "true") {
          if (
            subThemes.length > 0 ||
            branches.length > 0 ||
            startDate.length > 0 ||
            endDate.length > 0 ||
            mainThemes.length > 0
          ) {
            filtersString += "&";
          } else {
            filtersString += "?";
          }
          filtersString += "isOfficial=false";
        }
      });
    }
    if (isPublished.length > 0) {
      isPublished.forEach((row) => {
        let state = row.split(",");
        let draft = false;
        let published = false;
        state.forEach((row0) => {
          if (row0.includes("draft")) {
            draft = row0.split(":")[1];
          }
          if (row0.includes("published")) {
            published = row0.split(":")[1];
          }
        });

        if (draft === "true" && published === "false") {
          if (
            subThemes.length > 0 ||
            branches.length > 0 ||
            startDate.length > 0 ||
            endDate.length > 0 ||
            mainThemes.length > 0
          ) {
            filtersString += "&";
          } else {
            filtersString += "?";
          }
          filtersString += "state=DRAFT";
        }
        if (draft === "false" && published === "true") {
          if (
            subThemes.length > 0 ||
            branches.length > 0 ||
            startDate.length > 0 ||
            endDate.length > 0 ||
            mainThemes.length > 0
          ) {
            filtersString += "&";
          } else {
            filtersString += "?";
          }
          filtersString += "state=PUBLISHED";
        }
      });
    }
  }
  return filtersString;
  //result = ?mainTheme[]=3,2&subTheme[]=5,1,2,3,4&branch[]=1
}
