import { Swiper } from "swiper/react";

import "swiper/swiper.min.css";
import "./style.css";

import SwiperCore, { Scrollbar, Mousewheel, Navigation } from "swiper/core";

SwiperCore.use([Scrollbar, Mousewheel, Navigation]);

export default function SliderCollum({ children, className, column }) {
  return (
    <div className={`sliderCollum__container ${className}`}>
      <div className="sliderCollum__pagination-arrow-prev"></div>
      <div className="sliderCollum__pagination-arrow"></div>
      <Swiper
        freeMode={true}
        slidesPerColumn={column}
        slidesPerView={"auto"}
        navigation={{
          prevEl: ".sliderCollum__pagination-arrow-prev",
          nextEl: ".sliderCollum__pagination-arrow",
        }}
      >
        {children}
      </Swiper>
    </div>
  );
}
