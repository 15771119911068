export default function FilterIcon() {
  return (
    <svg
      id="filter_icon"
      xmlns="http://www.w3.org/2000/svg"
      width="24.001"
      height="27.43"
      viewBox="0 0 24.001 27.43"
    >
      <path
        d="M26.786,13.929h.429A1.29,1.29,0,0,0,28.5,12.643V8.357a1.29,1.29,0,0,0-1.286-1.286h-.429V1.928H23.357V7.071h-.429a1.29,1.29,0,0,0-1.286,1.286v4.286a1.29,1.29,0,0,0,1.286,1.286h.429V29.358h3.429ZM23.357,8.786h3.429v3.429H23.357ZM18.642,24.215a1.29,1.29,0,0,0,1.286-1.286V18.643a1.29,1.29,0,0,0-1.286-1.286h-.429V1.928H14.785V17.358h-.429a1.29,1.29,0,0,0-1.286,1.286v4.286a1.29,1.29,0,0,0,1.286,1.286h.429v5.143h3.429V24.215Zm-3.857-5.143h3.429V22.5H14.785ZM10.07,13.929a1.29,1.29,0,0,0,1.286-1.286V8.357A1.29,1.29,0,0,0,10.07,7.071H9.642V1.928H6.213V7.071H5.785A1.29,1.29,0,0,0,4.5,8.357v4.286a1.29,1.29,0,0,0,1.286,1.286h.429V29.358H9.642V13.929h.429ZM6.213,8.786H9.642v3.429H6.213V8.786Z"
        transform="translate(-4.499 -1.928)"
        fill="currentColor"
      />
    </svg>
  );
}
