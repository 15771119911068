import "./threenumbers.css";

export function ThreeNumbers({ color, numbers }) {
  return (
    <div className={`three-numbers bg-${color && color}`}>
      <div>
        <div className="three-numbers__container">
          {numbers &&
            numbers.map((item, index) => (
              <div key={index}>
                <h2>{item && item.value}</h2>
                <p>{item && item.label}</p>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
