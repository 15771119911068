import CategoryButton from "./../Category/CategoryButton";
import React, { forwardRef, useState } from "react";

/**
 * @param   { function } onChange
 * @return  { ref }
 */
const FilterState = forwardRef(({ onClick }, ref) => {
  const [draft, setDraft] = useState()
  const [published, setPublished] = useState()

  return (
    <div className="filter_category" onClick={onClick(`${draft},${published}`)}>
      <p className="filter_title">État</p>
      <div className="filter_category_button_group">
        <CategoryButton
          srcPic="/assets/icons/StateButton/draft.svg"
          title="Brouillon"
          value="draft"
          onClick={(value) => setDraft(value)}
        />
        <CategoryButton
          srcPic="/assets/icons/StateButton/published.svg"
          title="Publié"
          value="published"
          onClick={(value) => setPublished(value)}
        />
      </div>
    </div>
  );
});

export default FilterState;
