import { useContext } from "react";
import DefaultModal from "./Default";
import { HomeModal } from "../../../contexts/homepopup";

export const JMSHome = () => {
  const { isOpen, setIsOpen } = useContext(HomeModal);

  return (
    <DefaultModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      className="welcome-modal"
    >
      {/* <img id="fixed-image" src="/assets/img/Jean-Manuel_Soussan.png" /> */}
      <h1>
        Welcome
        <br />
        on <img src="/assets/icons/Footer/logo_HR_team.svg" alt="" />
      </h1>
      <div className="welcome-modal__content">
        <br />
        <iframe
          src="https://www.youtube-nocookie.com/embed/m7_loaKHoQw"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          style={{ width: "100%", height: "250px" }}
        ></iframe>
      </div>
    </DefaultModal>
  );
};
