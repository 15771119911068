import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";

import { EditMode } from "../../contexts/editmode";
import { ReadNotifications } from "../../api/Notifications/ReadNotifications";
const Item = ({ data, notif, setNotif }) => {
  const [isFocused, setIsFocused] = useState(false);
  const { name, path, icon } = data;
  const { editing } = useContext(EditMode);

  const closeNotifications = () => {
    setIsFocused(!isFocused);
  };
  const readNotificationByid = (id) => {
    try {
      ReadNotifications(id);
    } catch (error) {
      // TODO: Handle error properly
      // console.log(error);
    }
  };
  const readAllNotifications = () => {
    setNotif([]);
    try {
      notif.forEach((n) => {
        ReadNotifications(n.id);
      });
    } catch (error) {
      // TODO: Handle error properly
      // console.log(error);
    }
  };

  const getNotificationType = (path) => {
    if (!path) {
      return;
    }

    const pathSplited = path.split("?")[0].split("/");

    if (pathSplited[1].toLowerCase() === "feed") return "chat";
    if (pathSplited[1].toLowerCase() === "news") return "news";
    if (pathSplited[1].toLowerCase() === "events") return "events";
  };

  if (notif) {
    return (
      <div
        title={name}
        className={`topbar__item__container dropdown ${
          isFocused ? "active" : ""
        }`}
      >
        {notif.length ? (
          <span className="topbar__notif">{notif.length}</span>
        ) : (
          ""
        )}
        <div className="topbar__item" onClick={closeNotifications}>
          <img
            src={
              editing
                ? "/assets/img/icons/" + icon + "-white.svg"
                : "/assets/img/icons/" + icon + ".svg"
            }
            alt={name || "name"}
          />
        </div>

        <div className={`topbar__notif_container ${isFocused ? "active" : ""}`}>
          <div>
            <button onClick={() => setIsFocused(!isFocused)}>
              <i className="icon-full-arrow"></i> précédent
            </button>
            <h1>
              Notifications
              {notif.length ? (
                <button onClick={readAllNotifications}>
                  Effacer les notifications
                </button>
              ) : (
                ""
              )}
            </h1>

            <ul>
              {notif.length > 0 ? (
                notif.map((item, index) => (
                  <Link
                    to={item.Notifications[0].url}
                    key={index}
                    onClick={() => readNotificationByid(item.id)}
                  >
                    <li className="topbar__notif_item">
                      <i
                        className={`icon-${getNotificationType(
                          item.Notifications[0].url
                        )}`}
                      ></i>
                      <div>
                        <h5>{item.Notifications[0].title}</h5>
                        <p>{item.Notifications[0].content}</p>
                      </div>
                    </li>
                  </Link>
                ))
              ) : (
                <li className="topbar__notif_item">
                  <p>Aucune notification</p>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <Link
        to={path}
        title={name}
        id={name}
        className="topbar__item__container"
      >
        <div className="topbar__item">
          <img
            src={
              editing
                ? "/assets/img/icons/" + icon + "-white.svg"
                : "/assets/img/icons/" + icon + ".svg"
            }
            alt={name || "name"}
          />
        </div>
      </Link>
    );
  }
};
export default Item;
