import React, { useState, useEffect, useContext } from "react";
import ItemListNews from "./ItemListNews";
import Search from "../../components/Search/SearchFilter";
import NotFound from "../molecules/ErrorsMessages/NotFound";

import "./ContentListNews.css";

import { fetchAllNews } from "../../api/News/News";
import { deleteNews } from "../../api/News/News";
import { EditMode } from "../../contexts/editmode";

const ContentListNews = ({ filters }) => {
  const [news, setNews] = useState([]);
  const [searchFilter, setSearchFilter] = useState("");

  async function getAllNews(filters) {
    setNews(await fetchAllNews(filters));
  }

  useEffect(() => {
    getAllNews(filters);
  }, [filters]);

  function deleteOneNews(id) {
    setNews((news) => {
      return news.filter((news) => news.id !== id);
    });

    deleteNews(id);
  }

  const filteredNews = news.filter(({ title }) =>
    title.toLowerCase().includes(searchFilter)
  );
  const { editing } = useContext(EditMode);

  return (
    <div className="size_news">
      <Search
        onChange={(filter) => setSearchFilter(filter.toLowerCase())}
        placeholder={"Rechercher un article"}
      />

      <div className="scrollListNews">
        {filteredNews.length <= 0 && <NotFound element="News" />}

        {filteredNews.length > 0 && editing
          ? filteredNews
              .sort(function (a, b) {
                return new Date(b.createdAt) - new Date(a.createdAt);
              })
              .map((item, index) => (
                <ItemListNews
                  item={item}
                  key={index}
                  deleteOneNews={deleteOneNews}
                />
              ))
          : filteredNews.length > 0
          ? filteredNews
              .filter(
                (article) => article.NewsContent[0].Content.state !== "DRAFT"
              )
              .sort(function (a, b) {
                return new Date(b.createdAt) - new Date(a.createdAt);
              })
              .map((item, index) => (
                <ItemListNews
                  item={item}
                  key={index}
                  deleteOneNews={deleteOneNews}
                />
              ))
          : ""}
      </div>
    </div>
  );
};

export default ContentListNews;
