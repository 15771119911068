import moment from "moment";
import { useEffect, useState } from "react";

export default function NewsletterPreview({ title, content, events, news }) {
  const [mainNews, setMainNews] = useState({});

  useEffect(() => {
    if (news && news?.length) {
      //couper le texte des news pour qu'il ne dépasse pas 250 caractères et ajouter "..." à la fin
      news.forEach((n) => {
        if (n.NewsContent[0]?.Content?.html.length > 250) {
          n.NewsContent[0].Content.html =
            n.NewsContent[0].Content.html.substring(0, 250);
          n.NewsContent[0].Content.html += "...";
        }
      });
    }
    if (events && events?.length) {
      //couper le texte des events pour qu'il ne dépasse pas 250 caractères et ajouter "..." à la fin
      events.forEach((e) => {
        if (e.Content.html.length > 250) {
          e.Content.html = e.Content.html.substring(0, 250);
          e.Content.html += "...";
        }
      });
    }
  }, [news, events]);

  useEffect(() => {
    setMainNews(news[0]);
  }, [news]);

  return (
    <div className="newsletter-preview__container">
      <img
        src="/assets/newsletter/bg.png"
        alt="bg"
        className="newsletter-preview__bg"
      />
      <div className="newsletter-preview__logo">
        <img src="/assets/newsletter/logo.png" alt="logo" />
        <p>NEWSLETTER - {moment().format("DD MMM YYYY")}</p>
      </div>
      <div className="newsletter-preview__header">
        <h1 style={{ whiteSpace: "pre-line" }}>{title}</h1>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </div>

      {news?.length ? (
        <table
          style={{
            width: "100%",
            padding: "0px 65px",
            backgroundColor: "#fff",
            marginBottom: "20px",
          }}
        >
          <tr>
            <td className="main_news_td">
              <p style={{ textAlign: "right" }}>
                <img
                  src={`/assets/newsletter/news.png`}
                  alt=""
                  style={{ width: "100%" }}
                />
              </p>
              <div
                style={{
                  backgroundImage:
                    "url(" +
                    `${mainNews.logoImgPath}` +
                    ")",
                  width: "100%",
                  height: "300px",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}
              />
              <div className="div_content_main_news">
                <h1>{mainNews.title}</h1>
                <p dangerouslySetInnerHTML={{ __html: mainNews.content }} />
                <a href="/" style={{ marginLeft: "-10px" }}>
                  <img
                    src={`/assets/newsletter/button-news.png`}
                    style={{ width: "150px" }}
                  />
                </a>
              </div>
            </td>
          </tr>
        </table>
      ) : (
        ""
      )}
      {news &&
        news?.length > 1 &&
        news?.slice(1)?.map((n) => (
          <div className="div_news_table">
            <table className="table1">
              <tr>
                <td
                  style={{
                    backgroundImage: "url(" + `${n.logoImgPath}` + ")",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    width: "40%",
                  }}
                ></td>
                <td className="table1_td2">
                  <h1>{n.title}</h1>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: n.NewsContent[0]?.Content?.html,
                    }}
                  />

                  <a href="">Lire la suite</a>
                </td>
              </tr>
            </table>
          </div>
        ))}

      {/* EVENTS */}
      <section className="div_events_table">
        {events?.length > 0 && (
          <img
            src={`/assets/newsletter/event.png`}
            alt=""
            style={{ width: "100%" }}
          />
        )}

        {events?.map((e) => (
          <div className="div_news_table">
            <table className="table1">
              <tr>
                <td
                  style={{
                    backgroundImage:
                      "url(" +
                      `${e.logoImgPath}` +
                      ")",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    width: "40%",
                  }}
                ></td>
                <td className="event_table1_td2">
                  <h1>{e.title}</h1>
                  <h2>
                    Du{" "}
                    {moment(e.EventSession[0].startDate).format("DD MMM YYYY")}{" "}
                    au {moment(e.EventSession[0].endDate).format("DD MMM YYYY")}
                  </h2>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: e.Content.html,
                    }}
                  />

                  <a href="/">
                    <img
                      src={`/assets/newsletter/button-event.png`}
                      style={{ width: "150px" }}
                    />
                  </a>
                </td>
              </tr>
            </table>
          </div>
        ))}
      </section>
    </div>
  );
}
