import CloseIcon from "components/atoms/icons/close";
import LocationIcon from "components/atoms/icons/location";
import MailIcon from "components/atoms/icons/mail";
import PhoneIcon from "components/atoms/icons/phone";
import joinClasses from "helpers/joinClasses";
import { useEffect } from "react";

export default function Modal({ user, isOpen = false, onClose = () => {} }) {
  const getLocation = (user) => {
    if (!user) return;
    // filter user getlocation to get item with type LOC
    const location = user?.geolocation?.filter((item) => item.type === "LOC");
    if (location?.length) return location[0].name;
    else return null;
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isOpen]);

  return (
    <div className={joinClasses("annuaire__content_modal", isOpen && "active")}>
      <div
        className="annuaire__content_modal-trigger"
        onClick={() => onClose()}
      />
      <div className="annuaire__content_modal_container">
        <button
          onClick={() => onClose()}
          className="annuaire__content_modal_close_btn"
        >
          <CloseIcon />
        </button>
        <div className="annuaire__content_modal_content" id="main-card">
          <div
            className="annuaire__content_modal_banner"
            style={{
              backgroundImage: `url(${
                user?.bannerPath || "/assets/img/banner-profil.png"
              })`,
            }}
          />
          <div className="annuaire__content_modal_avatar">
            <img
              src={user?.avatarPath || "/assets/img/welcome.jpeg"}
              alt={user?.name}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "/assets/img/welcome.jpeg";
              }}
            />
            <p></p>
          </div>

          <div className="annuaire__content_modal_info">
            <h3>
              {user?.firstname} {user?.lastname}
            </h3>
            <h4>{user?.job}</h4>
            <p id="branch">{user?.Branch?.name}</p>
            <p id="entity">{user?.Entity?.name}</p>
            <div className="annuaire__content_modal_contact">
              <p>
                <LocationIcon />

                {getLocation(user) ? (
                  <span>{getLocation(user)}</span>
                ) : (
                  <spann style={{ textTransform: "none" }}>
                    Aucune adresse enregistrée
                  </spann>
                )}
              </p>
              <div id="mail">
                <MailIcon />
                <a href={`mailto:${user?.email}`}>{user?.email}</a>
              </div>
              <div id="phone">
                <PhoneIcon />
                <a href={`tel:${user?.phone}`}>
                  {user?.phone || "Pas de numéro enregistré"}
                </a>
              </div>
            </div>
            <hr style={{ marginBottom: "0px" }} />
            <div className="account__referent">
              {user?.GroupReferentOfMainThemes?.length > 0 ? (
                <>
                  <h3>Référent Groupe</h3>
                  <ul>
                    {user?.GroupReferentOfMainThemes?.map((item, index) => (
                      <li key={index}>
                        <img
                          src={`/assets/img/icons/${item.icon}`}
                          alt="icon"
                        />
                        <p>{item.name}</p>
                      </li>
                    ))}
                  </ul>
                  <ul>
                    {user?.GroupReferentOfSubThemes.map((item, index) => (
                      <li key={index}>
                        <div
                          className={`account_bullet-point bg-${item.color}`}
                        ></div>
                        <p>{item.name}</p>
                      </li>
                    ))}
                  </ul>
                </>
              ) : (
                ""
              )}

              {user?.ReferentOfSubThemes?.length > 0 ? (
                <>
                  <h3>Référent Métier</h3>
                  <ul>
                    {user?.ReferentOfMainThemes.map((item, index) => (
                      <li key={index}>
                        <img
                          src={`/assets/img/icons/${item.icon}`}
                          alt="icon"
                        />
                        <p>{item.name}</p>
                      </li>
                    ))}
                  </ul>
                  <ul>
                    {user?.ReferentOfSubThemes?.map((item, index) => (
                      <li key={index}>
                        <div
                          className={`account_bullet-point bg-${item.MainTheme?.color}`}
                        ></div>
                        <p>{item.name}</p>
                      </li>
                    ))}
                  </ul>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div className="annuaire__content_modal_content" id="assignments-card">
          <h1>Missions et expertises</h1>
          <div className="capsule__container">
            {user?.Expertise?.map((item, index) => (
              <span style={{ backgroundColor: "var(--main-color)" }}>
                {item.name}
              </span>
            ))}
          </div>
        </div>
        <div
          className="annuaire__content_modal_content"
          id="interventions-card"
        >
          <h1>Périmètres d’intervention</h1>
          <div className="capsule__container">
            {user?.InterventionZone?.map((item, index) => {
              return <span key={index + item.id}>{item.name}</span>;
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
