import { useEffect, useState } from "react";
import CloseCircleIcon from "components/atoms/icons/close-circle";
import { searchOnEntites } from "api/Entities/searchEntites";

export default function SearchEntities({
  setEntities = () => {},
  entities = [],
}) {
  const [list, setList] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);

  // if user click to select an item
  const handleSelect = (item) => {
    setTimeout(() => {
      setSearch("");
      setEntities([...entities, item]);
    }, 200);
  };

  const getSearch = async (value) => {
    if (value.length < 2) return setList([]);
    setLoading(true);
    const response = await searchOnEntites(value.toLowerCase());
    setLoading(false);
    setList(response);
  };

  // if user click to remove an item
  const handleRemove = (value) => {
    setEntities(entities.filter((item) => item !== value));
  };

  useEffect(() => {
    const timeOutId = setTimeout(() => getSearch(search), 500);
    return () => clearTimeout(timeOutId);
  }, [search]);

  return (
    <div className="annuaire__filter-search">
      <input
        name="entities"
        placeholder="Entité(s)"
        onChange={(e) => setSearch(e.target.value)}
        value={search}
        className="annuaire__filter-search-input"
      />
      {/* list of results */}
      <ul className="annuaire__filter-search-list" id="entities_list">
        {!loading ? (
          list.map((item, index) => (
            <li key={index} onClick={() => handleSelect(item)}>
              {item.name}
            </li>
          ))
        ) : (
          <li>Chargement...</li>
        )}
      </ul>

      {/* selected items */}
      <div className="annuaire__filter-search-selected">
        {entities.map((item, index) => (
          <div key={index} style={{ backgroundColor: "#161B4B" }}>
            <button onClick={() => handleRemove(item)}>
              <CloseCircleIcon />
            </button>
            <span>{item.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
}
