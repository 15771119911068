import { useEffect, useState, useContext } from "react";
import { EditMode } from "../../../contexts/editmode";

import { sideBarItems } from "../../../utils/sideBarItems";
import { sideBarItemsAdmin } from "../../../utils/sideBarItemsAdmin";

import FirstDropdown from "./FirstDropdown";
import Item from "./Item";

const Menu = () => {
  const [linksFile, setLinksFile] = useState([]);
  const { editing } = useContext(EditMode);

  useEffect(() => {
    if (editing) {
      setLinksFile(sideBarItemsAdmin);
    } else {
      setLinksFile(sideBarItems);
    }
  }, [editing]);

  return (
    <div>
      <ul className="mob-navbar__menu">
        {linksFile.map((barItem, index) =>
          barItem.dropdownItems ? (
            <FirstDropdown key={index} data={barItem} />
          ) : (
            <Item key={index} data={barItem} />
          )
        )}
      </ul>
    </div>
  );
};

export default Menu;
