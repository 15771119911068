import axios from "axios";
import getFormatedToken from "../../helpers/getFormatedToken";

export async function ChangePassword(password) {
  await axios.put(
    `${process.env.REACT_APP_API_URL}/api/users/me/update-password`,
    password,
    getFormatedToken()
  );
}
