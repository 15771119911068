/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Card } from "../../../components/molecules/AdminPanel/index";
import { createNewQuestion, editQuestion } from "../../../api/Questions/Questions";
import { fetchAllThematics } from "../../../api/MainThemesSubThemes";
import { ThemeIcon } from "../../../components/molecules/IconTheme";
import ErrorDiv from "../../../components/molecules/ErrorsMessages/errorForm";
import "./faq.css"

export default function FaqForm({
  onClick,
  isValid = true,
  filter = false,
  isSubTheme = false,
  selectedQuestion = null,
  onCancel = () => { },
}) {
  const [entitled, setEntitled] = useState("");
  const [answer, setAnswer] = useState("");
  const [mainTheme, setMainTheme] = useState([]);
  const [errorTheme, setErrorTheme] = useState(false);
  const [success, setSuccess] = useState(false);

  const [sending, setSending] = useState(false);

  async function getAllMainThemes() {
    setMainTheme(await fetchAllThematics());
  }

  useEffect(() => {
    getAllMainThemes();
  }, []);

  useEffect(() => {
    if (selectedQuestion) {
      setEntitled(selectedQuestion.entitled);
      setAnswer(selectedQuestion.answer);
    }
    else {
      setEntitled("");
      setAnswer("");
    }
  }, [selectedQuestion]);

  const createQuestion = async () => {
    let err = null;
    if (isSubTheme && filter) {
      err = await createNewQuestion(entitled, answer, null, [filter]);
    } else if (!isSubTheme && filter) {
      err = await createNewQuestion(entitled, answer, [filter], null);
    } else {
      err = await createNewQuestion(entitled, answer, null, null);
    }
    console.log(err);
    if (!err) {
      setSuccess(true);
      setTimeout(() => {
        window.location.reload();
      }, 500);
      return;
    }
    setErrorTheme(true);
  };

  const updateQuestion = async () => {
    console.log("selectedQuestion", selectedQuestion);
    let err = null;

    err = await editQuestion(selectedQuestion.id, entitled, answer);

    console.log(err);
    if (!err) {
      setSuccess(true);
      setTimeout(() => {
        window.location.reload();
      }, 500);
      return;
    }
    setErrorTheme(true);
  };

  return (
    <Card>
      <a className="back" onClick={() => onCancel()}>
        <i className="icon-short-arrow" style={{ fontSize: "9px" }} /> Annuler
      </a>
      <h1 className="title">{
        selectedQuestion ? "Modifier" : "Ajouter"} une question RH</h1>
      <form className="admin-form-faq">
        <div className="admin-input-box">
          <input
            className="admin-input"
            type="text"
            placeholder="Question*"
            value={entitled}
            onChange={(e) => setEntitled(e.target.value)}
            required
            style={{ width: "100%" }}
          />
          <textarea
            style={{ width: "100%" }}
            className="admin-input"
            placeholder="Réponse..."
            value={answer && answer}
            onChange={(e) => setAnswer(e.target.value)}
            required
          />
        </div>
      </form>
      <div
        style={{
          borderBottom: "1px rgba(0, 0, 0, 0.15) solid",
          marginBottom: "30px",
        }}
      ></div>
      <div className="admin-fitler-container">
        <div>
          {filter &&
            !isSubTheme &&
            mainTheme.map((theme, index) => (
              <div key={index} className="faq-question-li-list">
                {theme.id === filter && (
                  <React.Fragment>
                    <input
                      checked
                      type="radio"
                      id={theme.name}
                      name="mainTheme"
                      value={theme.id}
                    />
                    <label
                      for={theme.name}
                      className="faq-question-li-list"
                      style={{ alignItems: "center" }}
                    >
                      <ThemeIcon theme={theme} />
                      <p>{theme.name}</p>
                    </label>
                  </React.Fragment>
                )}
              </div>
            ))}
          {isValid || selectedQuestion ? (
            sending ? (
              <button
                className="save-btn"
                style={{ backgroundColor: "#66666b" }}
                disabled
              >
                {selectedQuestion ? "Modifier" : "Publier"} la question
              </button>
            ) : (
              <button
                className="save-btn"
                onClick={async (e) => {
                  e.preventDefault();
                  setSending(true);
                  if (selectedQuestion) {
                    await updateQuestion();
                  } else {
                    await createQuestion();
                  }
                  setTimeout(() => {
                    setSending(false);
                    setSuccess(false);
                    setErrorTheme(false);
                  }, 2000);
                }}
              >
                {selectedQuestion ? "Modifier" : "Publier"} la question
              </button>
            )
          ) : (
            <ErrorDiv message="Il existe déjà trois questions... Veuillez en supprimer au moins une pour en ajouter de nouvelles" />
          )}
          {errorTheme && (
            <ErrorDiv message="Erreur: un problème est survenu..." />
          )}
          {success && (
            <div
              style={{
                backgroundColor: "var(--green-color)",
                color: "white",
                padding: 10,
                fontFamily: "var(--cera-bold)",
                textAlign: "center",
                marginTop: 10,
              }}
            >
              Question ajoutée avec succès !
            </div>
          )}
        </div>
      </div>
    </Card>
  );
}
