export default function ListIcon() {
  return (
    <svg
      id="list_icon"
      xmlns="http://www.w3.org/2000/svg"
      width="13.822"
      height="13.822"
      viewBox="0 0 13.822 13.822"
    >
      <g id="list" transform="translate(-48 -48)">
        <path
          d="M60.227,48H49.595A1.6,1.6,0,0,0,48,49.595V60.227a1.6,1.6,0,0,0,1.595,1.595H60.227a1.6,1.6,0,0,0,1.595-1.595V49.595A1.6,1.6,0,0,0,60.227,48Zm.532,12.227a.532.532,0,0,1-.532.532H49.595a.532.532,0,0,1-.532-.532V49.595a.532.532,0,0,1,.532-.532H60.227a.532.532,0,0,1,.532.532Z"
          fill="currentColor"
        />
        <rect
          width="2.164"
          height="1.731"
          rx="0.866"
          transform="translate(50.15 54.045)"
          fill="currentColor"
        />
        <rect
          width="2.164"
          height="2.164"
          rx="1.082"
          transform="translate(50.15 57.075)"
          fill="currentColor"
        />
        <rect
          width="2.164"
          height="2.164"
          rx="1.082"
          transform="translate(50.15 50.582)"
          fill="currentColor"
        />
        <path
          d="M213.848,240h-5.316a.532.532,0,0,0,0,1.063h5.316a.532.532,0,0,0,0-1.063Z"
          transform="translate(-154.684 -185.62)"
          fill="currentColor"
        />
        <path
          d="M213.848,336h-5.316a.532.532,0,0,0,0,1.063h5.316a.532.532,0,1,0,0-1.063Z"
          transform="translate(-154.684 -278.431)"
          fill="currentColor"
        />
        <path
          d="M213.848,144h-5.316a.532.532,0,0,0,0,1.063h5.316a.532.532,0,0,0,0-1.063Z"
          transform="translate(-154.684 -92.81)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
