import axios from "axios";
import getFormatedToken from "../../helpers/getFormatedToken";

export async function ResetPassword(email) {
  await axios.put(
    `${process.env.REACT_APP_API_URL}/api/authentication/${email}/reset-password `,
    null,
    getFormatedToken()
  );
}
