import axios from "axios";
import getFormatedToken from "helpers/getFormatedToken";

export async function editGeolocation(
  userId = "",
  geolocationId = null,
  geolocations = [],
  type = "ZI"
) {
  let body;

  // if geolocationId, send delete request
  if (geolocationId) {
    body = {
      userId: userId,
      geolocationId: geolocationId,
      geolocation: [],
      zi: false,
    };
  } else {
    body = {
      userId: userId,
      geolocation: geolocations,
      zi: type === "ZI",
    };
  }

  const response = await axios.put(
    `${process.env.REACT_APP_API_URL}/api/geolocation/update`,
    body,
    getFormatedToken()
  );

  const { data } = response.data;
  return data;
}

export async function getReversedGeolocation(ids) {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/api/geolocation/reverse`,
    {
      ids: ids,
    },
    getFormatedToken()
  );
  const { data } = response.data;
  return data;
}
