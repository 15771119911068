import { useEffect } from "react";
import "./style.css";

export default function DefaultModal({
  children,
  isOpen,
  setIsOpen,
  title,
  className,
  zIndex,
  header = true,
}) {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isOpen]);

  return (
    <>
      <div
        style={{ zIndex: zIndex && zIndex }}
        className={`default-modal__backgound-trigger ${
          isOpen ? "d-block" : "d-none"
        } ${className ? className + "-trigger" : ""}`}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      />
      <div
        style={{ zIndex: zIndex && zIndex + 1 }}
        className={`default-modal__container ${className ? className : ""} ${
          isOpen ? "d-block" : "d-none"
        }`}
      >
        {header && (
          <div className="default-modal__header">
            {title ? <h1 dangerouslySetInnerHTML={{ __html: title }}></h1> : ""}
            <button
              onClick={() => {
                setIsOpen(false);
              }}
            >
              <i className="icon-close"></i>
            </button>
          </div>
        )}
        <div className="default-modal__content">{children}</div>
      </div>
    </>
  );
}
