import WarningIcon from "components/atoms/icons/warning";

export default function SimilarResult({
  currentText = "",
  newText = {},
  onSelected = () => {},
}) {
  return (
    <div className="similar-result">
      <WarningIcon />
      <div>
        <h4>Résultat similaire trouvé !</h4>
        <p>
          Utiliser{" "}
          <span onClick={() => onSelected(newText)}>{newText?.name}</span>
        </p>
        <p>
          Ou continuer avec{" "}
          <span onClick={() => onSelected(currentText)}>{currentText}</span>
        </p>
      </div>
    </div>
  );
}
