import axios from "axios";
import getFormatedToken from "../../helpers/getFormatedToken";

export async function fetchAllNotifications() {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/api/notifications`,
    getFormatedToken()
  );
  const { data } = response.data;
  return data;
}
