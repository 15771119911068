import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import DropDownSubItem from "./DropDownSubItem/DropDownSubItem";

const getStyle = (pathname, path) => {
  if (pathname === path || pathname.includes("thematics")) {
    return "active";
  }
};
const SidebarDropDownItem = ({ barItem, openSideBar, isSideBarOpen }) => {
  const { title, icon, path, dropdownItems } = barItem;
  const [isFocused, setIsFocused] = useState(false);

  function dropDown() {
    setIsFocused(!isFocused);
  }
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname.includes(path)) {
      setIsFocused(true);
    } else {
      setIsFocused(false);
    }
  }, [path, pathname]);

  return (
    <ul className="subDropdown">
      <li className={getStyle(pathname, path)} onClick={() => dropDown()}>
        <div className="global_dropdown">
          <i className={"icon-" + icon} />
          <p>{title}</p>
          <i
            className={`dropdown-arrow__icon icon-arrow ${
              isFocused && "active"
            }`}
          />
          <span className="side-bar__hover-title">{title}</span>
        </div>
      </li>
      {dropdownItems.map((item, index) => (
        <DropDownSubItem
          key={index}
          item={item}
          isSideBarOpen={isSideBarOpen}
          openSideBar={openSideBar}
          isParentFocused={isFocused}
        />
      ))}
    </ul>
  );
};

export default SidebarDropDownItem;
