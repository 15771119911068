export default function ArrowBackIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12.218"
      height="8.149"
      viewBox="0 0 12.218 8.149"
    >
      <path
        d="M12.308,11.408a.555.555,0,0,1,0,.781L9.737,14.773h9.816a.552.552,0,0,1,0,1.1H9.737l2.58,2.585a.559.559,0,0,1,0,.781.549.549,0,0,1-.777,0l-3.5-3.522h0a.62.62,0,0,1-.115-.174.527.527,0,0,1-.042-.212.553.553,0,0,1,.157-.386l3.5-3.522A.541.541,0,0,1,12.308,11.408Z"
        transform="translate(-7.882 -11.252)"
        fill="currentColor"
      />
    </svg>
  );
}
