import React from "react";

export default function StepFour({
  openWindow,
  windowStatus,
  resetStep,
  resetContent,
}) {
  const close = () => {
    openWindow(!windowStatus);
    setTimeout(() => {
      resetStep(0);
      resetContent({ title: "", filetype: 0, thematic: [], files: [] });
    }, 200);
  };
  return (
    <React.Fragment>
      <h2>Merci de votre contribution !</h2>
      <button onClick={close}>Terminer</button>
    </React.Fragment>
  );
}
