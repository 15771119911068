import React, { useContext, useState } from "react";

import FilterForm from "../../components/Filter/FilterForm";
import SharesContent from "../../components/ContentListShares";
import "./Shares.css";
import { EditMode } from "../../contexts/editmode";

export default function Shares() {
  const [filters, setFilters] = useState([]);
  const { editing } = useContext(EditMode);

  return (
    <React.Fragment>
      <FilterForm filtersCallback={setFilters} category={editing} />
      <SharesContent filters={filters} />
    </React.Fragment>
  );
}
