import { useEffect, useState } from "react";
import { fetchAllBranches } from "api/Branches";
import joinClasses from "helpers/joinClasses";
import SearchEntities from "./search_entities";
import SearchMissions from "./search_missions";
import SearchLocalisations from "./search_localisations";
import DropdownJob from "./dropdown_job";
export default function FilterAnnuaire({
  isMobileOpen = false,
  onClose = () => {},
  setFilter = () => {},
  filter = {},
  onReset = () => {},
}) {
  const [branches, setBranches] = useState([]);

  async function getAllBranches() {
    setBranches(await fetchAllBranches());
  }

  const handleReset = (e) => {
    e.preventDefault();
    onReset();
  };

  useEffect(() => {
    getAllBranches();
  }, []);

  const handleSelectValues = (value, type) => {
    setFilter((prev) => ({ ...prev, [type]: value }));
  };

  return (
    <div
      className={joinClasses(
        "annuaire__filter-contaier",
        isMobileOpen && "open"
      )}
    >
      <button onClick={handleReset} className="annuaire__filter-reset_btn">
        Réinitialiser le filtre
      </button>
      {/* Input Container */}
      <div className="annuaire_filter_input_container">
        <SearchEntities
          setEntities={(value) => handleSelectValues(value, "entities")}
          entities={filter.entities || []}
        />

        <SearchMissions
          setMissions={(value) => handleSelectValues(value, "expertises")}
          missions={filter.expertises || []}
        />
        <SearchLocalisations
          setLocalisations={(value) =>
            handleSelectValues(value, "geolocation")
          }
          localisations={filter.geolocation || []}
        />
      </div>

      <div className="annuaire__filter-dropdown_container">
        <DropdownJob
          title="Métiers"
          list={branches}
          setBranches={(value) => handleSelectValues(value, "branches")}
          branches={filter.branches || []}
        />
      </div>

      <button className="btn btn-annulaire-filter bg-main" onClick={onClose}>
        Valider
      </button>
    </div>
  );
}
