import React from "react";
import { filetypes } from "../../../utils/filetypes.json";

export default function StepTwo({ changeFileType, currentForm }) {
  const selectFileType = (event) => {
    changeFileType({
      ...currentForm,
      filetype: Number(event.target.value),
    });
  };
  return (
    <React.Fragment>
      <h1>
        étape <i>2</i>/3
      </h1>
      <p>Choisissez le type de contenu :</p>
      <form className="propose-content__radio-container">
        {filetypes.map((filetype, index) => (
          <div key={index} className="propose-content__input-container">
            <input
              type="radio"
              id={`file-${filetype.id}`}
              name="chooseFileType"
              value={filetype.id}
              onChange={selectFileType}
              checked={currentForm.filetype === filetype.id}
            />
            <label htmlFor={`file-${filetype.id}`}>
              {filetype.icon && <i className={`icon-${filetype.icon}`}></i>}
              {filetype.name}
            </label>
          </div>
        ))}
      </form>
    </React.Fragment>
  );
}
