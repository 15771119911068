import React, { useState } from "react";
import { filetypes } from "../../../utils/filetypes.json";

export default function StepThree({ changeFilesList, currentForm }) {
  const [toggleAttachment, setToggleAttachment] = useState(false);
  const [inputType, setInputType] = useState(0);

  const getNameFileType = (id) => {
    const result = filetypes.findIndex((filetype) => filetype.id === id);
    if (filetypes[result]) {
      return filetypes[result].name;
    }
  };

  const selectInput = (event) => {
    if (event.target.name === "addText") {
      changeFilesList({
        ...currentForm,
        file: {
          content: event.target.value,
          type: "text",
        },
      });
    } else if (event.target.name === "addFile") {
      changeFilesList({
        ...currentForm,
        file: {
          content: event.target.files[0],
          type: "file",
        },
      });
    }
  };

  const changeTitle = (event) => {
    changeFilesList({
      ...currentForm,
      title: event.target.value,
    });
  };
  return (
    <React.Fragment>
      <h1>
        étape <i>3</i>/3
      </h1>
      <p>Titre du contenu*</p>
      <form>
        <input
          type="text"
          name="titleFile"
          onChange={changeTitle}
          className="propose-content__input-title"
          placeholder="Titre du contenu"
          value={currentForm.title}
        />
        {inputType === 0 ? (
          <div className="propose-content__add-file">
            <div>
              <p>Type: {getNameFileType(currentForm.filetype)}</p>
            </div>
            <div>
              {currentForm.file &&
                currentForm.file.content &&
                currentForm.file.content.name ? (
                <p>{currentForm.file.content.name}</p>
              ) : (
                <p className="default-title">Upload un fichier .doc,.pdf</p>
              )}
              <input
                type="file"
                name="addFile"
                onChange={(event) => selectInput(event)}
              />
            </div>
          </div>
        ) : (
          <div className="propose-content__add-text">
            <div>
              <p>Type: {getNameFileType(currentForm.filetype)}</p>
            </div>
            <textarea
              name="addText"
              placeholder="Entrer du texte ici"
              onChange={(event) => selectInput(event)}
            ></textarea>
          </div>
        )}
        <div
          className="propose-content__add-attachment"
          onClick={() => setToggleAttachment(!toggleAttachment)}
        >
          <p>Type de pièce jointe</p>
          <button
            className={!toggleAttachment && "d-none"}
            onClick={(e) => {
              e.preventDefault();
              setInputType(0);
            }}
          >
            Fichier
          </button>
          <button
            className={!toggleAttachment && "d-none"}
            onClick={(e) => {
              e.preventDefault();
              setInputType(1);
            }}
          >
            Texte
          </button>
        </div>
        <br />
        <p className="informations">
          Si vous voulez envoyer plusieurs fichiers, veuillez envoyer un fichier
          .zip
        </p>
        <p className="informations">
          Pour les contenus supérieurs à 10MO, veuillez créer un lien de partage
          (WeTransfer par exemple)
        </p>
      </form>
    </React.Fragment>
  );
}
