import React, { forwardRef, useState } from "react";

const FilterCategoryButton = forwardRef(
  ({ title, value, srcPic, altPic, onClick }) => {
    const [isActive, setIsActive] = useState(false);

    const handleButtonClick = (e) => {
      setIsActive(e.currentTarget.checked);
    };

    return (
      <label
        className={
          isActive ? "filter_category_button active" : "filter_category_button"
        }
        onClick={onClick(`${value}:${isActive}`)}
      >
        <input
          type="checkbox"
          style={{ display: "none" }}
          onChange={(e) => {
            handleButtonClick(e);
          }}
          value={value}
        />
        <img alt={altPic || "alt_pic"} src={srcPic} />
        <p>{title}</p>
      </label>
    );
  }
);

export default FilterCategoryButton;
