/* eslint-disable no-useless-escape */
export default function FormatChatMessage({ text, className = "" }) {
  if (!text || typeof text !== "string") return;

  // detect tous les liens
  const regexURL = /(http|ftp|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:\/~+#-]*[\w@?^=%&\/~+#-])/g;
  const matchesURL = text.matchAll(regexURL);
  for (const match of matchesURL) {
    const link = `<a href="${match[0]}" target="_blank">${match[0]}</a>`;
    text = text.replace(match[0], () => link);
  }

  // detect toute les mentions "@[name](uuid)"
  const regex = /@\[([^\]]*)\]\(([^\)]*)\)/g;

  const matches = text.matchAll(regex);

  for (const match of matches) {
    const name = match[1];
    const uuid = match[2];
    const link = `<a href="/users/${uuid}">${name}</a>`;
    text = text.replace(match[0], () => link);
  }

  return <p className={className} dangerouslySetInnerHTML={{ __html: text }} />;
}
