import axios from "axios";
import getFormatedToken from "../../helpers/getFormatedToken";

export async function sendFinishRegistration(form) {
  await axios.post(
    `${process.env.REACT_APP_API_URL}/api/authentication/complete-registration`,
    form,
    getFormatedToken()
  );
}
