import { useRef } from "react";

export default function List({
  list = [],
  onSeletedUser = () => {},
  onScrollEnd = () => {},
  loading = false,
  isEndScroll = false,
}) {
  // scroll ref
  const scrollRef = useRef(null);

  const handleScroll = () => {
    if (loading || isEndScroll) return;
    const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
    // quand on est à 100px de la fin du scroll
    if (scrollTop + clientHeight >= scrollHeight - 300) {
      onScrollEnd();
    }
  };

  return (
    <div
      className="annuaire__content_list"
      ref={scrollRef}
      onScroll={handleScroll}
    >
      {list?.map((item, index) => (
        <div
          className="annuaire__content_list_item"
          key={item.id}
          onClick={() => onSeletedUser(item)}
        >
          <img
            src={item?.avatarPath || "/assets/img/welcome.jpeg"}
            alt={item?.firstname}
            className="annuaire__content_list_item_image"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "/assets/img/welcome.jpeg";
            }}
          />
          <div className="annuaire__content_list_item_content">
            <h3 className="annuaire__content_list_item_content_title">
              {item.firstname} {item.lastname}
            </h3>
            <p className="annuaire__content_list_item_content_job">
              {item?.job}
            </p>
            {/* <p className="annuaire__content_list_item_content_entity">
              {item?.Entity?.name}
            </p> */}
            <p className="annuaire__content_list_item_content_branch">
              <span>{item?.Entity?.name}</span>{" "}
              {item?.Branch?.name && item?.Entity?.name && " - "}
              {item?.Branch?.name}
            </p>
          </div>
        </div>
      ))}
      {loading && (
        <div className="annuaire__loader-container">
          <div className="loader"></div>
          <p>Chargement en cours...</p>
        </div>
      )}
    </div>
  );
}
