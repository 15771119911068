import axios from "axios";
import getFormatedToken from "../helpers/getFormatedToken";
import moment from "moment";

export async function fetchAllNewcomers() {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/api/newcomers`,
    getFormatedToken()
  );

  const { data } = response.data;
  return data.newcomers;
}

export async function createNewcomers(
  job,
  wingzy,
  branchId,
  fullName,
  dateIn,
  avatar
) {
  if (!fullName || !dateIn || !branchId) {
    return;
  }
  const formData = new FormData();
  formData.append("job", job);
  formData.append("wingzy", wingzy);
  formData.append("branchId", branchId);
  formData.append("fullName", fullName);
  const dateInISO = moment(dateIn).toISOString(true);
  formData.append("dateIn", dateInISO);
  formData.append("avatar", avatar);

  await axios({
    method: "POST",
    url: `${process.env.REACT_APP_API_URL}/api/newcomers`,
    data: formData,
    ...getFormatedToken(true),
    validateStatus: () => true,
  });
}

export async function updateNewcomers(
  id,
  job,
  wingzy,
  branchId,
  fullName,
  dateIn,
  avatar
) {
  if (!fullName || !dateIn || !branchId) {
    return;
  }
  const formData = new FormData();
  formData.append("job", job);
  formData.append("wingzy", wingzy);
  formData.append("branchId", branchId);
  formData.append("fullName", fullName);
  const dateInISO = moment(dateIn).toISOString(true);
  formData.append("dateIn", dateInISO);
  formData.append("avatar", avatar);

  const response = await axios({
    method: "PUT",
    url: `${process.env.REACT_APP_API_URL}/api/newcomers/${id}`,
    data: formData,
    ...getFormatedToken(true),
    validateStatus: () => true,
  });
  if (response.status === 201) {
    return true;
  }
}

export async function deleteNewcomers(id) {
  if (!id) {
    return;
  }

  await axios({
    method: "DELETE",
    url: `${process.env.REACT_APP_API_URL}/api/newcomers/${id}`,
    ...getFormatedToken(true),
  });
}

export async function fetchOneWelcome(id) {
  if (!id) {
    return;
  }

  const response = await axios({
    method: "GET",
    url: `${process.env.REACT_APP_API_URL}/api/newcomers/${id}`,
    ...getFormatedToken(true),
  });

  const { data } = response.data;
  return data.newcomer;
}
