import React from "react";
import { Link } from "react-router-dom";
import "./menuItemBloc.css";

export const MenuItemBloc = ({ title, items, color, itemLink }) => {
  return (
    <div className="menu_bloc d-none">
      <Link to={itemLink} className={`bg-${color} menu_bloc_title`}>
        <i className="icon-full-arrow"></i>
        <span> {title}</span>
      </Link>
      <ul className="menu_bloc_list">
        {items &&
          items.map((item, index) => {
            return (
              <Link key={index} to={item.path} className="menu_bloc_list_item">
                <div className={`enumerator bg-${color}`} />
                <p>{item.title}</p>
              </Link>
            );
          })}
      </ul>
    </div>
  );
};
