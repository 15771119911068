import React, { useState, useContext, useEffect } from "react";

import SidebarItem from "./SidebarItem/SidebarItem";
import SidebarDropDownItem from "./SidebarDropDownItem/SidebarDropDownItem";
import "./SideBar.css";

import { EditMode } from "../../contexts/editmode";

import { sideBarItems } from "../../utils/sideBarItems";
import { sideBarItemsAdmin } from "../../utils/sideBarItemsAdmin";

export default function SideBar() {
  const [linksFile, setLinksFile] = useState(sideBarItems);
  const [isSideBarOpen, setIsSideBarOpen] = useState(false);

  const { editing } = useContext(EditMode);

  useEffect(() => {
    if (editing) {
      setLinksFile(sideBarItemsAdmin);
    } else if (!editing) {
      setLinksFile(sideBarItems);
    }
  }, [editing]);

  return (
    <React.Fragment>
      <img
        src="../../../assets/img/baseline.svg"
        alt="osez le progrès"
        className="baseline"
      />
      <div
        id="side-bar"
        className={isSideBarOpen ? "side-bar__unfold" : "side-bar__fold"}
      >
        <div className="side-bar__background" />
        <nav className="side-bar__container" id="navSideBar">
          <ul>
            <li className="side-bar__show-all">
              <button onClick={() => setIsSideBarOpen(!isSideBarOpen)}>
                <i className="icon-arrow"></i>
              </button>
            </li>

            {linksFile.map((barItem, index) =>
              barItem.dropdownItems ? (
                <SidebarDropDownItem
                  key={index}
                  barItem={barItem}
                  openSideBar={() => setIsSideBarOpen(true)}
                  isSideBarOpen={isSideBarOpen}
                />
              ) : (
                <SidebarItem
                  key={index}
                  icon={barItem.icon}
                  path={barItem.path}
                  title={barItem.title}
                  exactMatch={barItem.exactMatch}
                />
              )
            )}
          </ul>
        </nav>
      </div>
    </React.Fragment>
  );
}
