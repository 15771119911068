import React, { useState, useEffect } from "react";
import { fetchProfile } from "../../api/Users/Profile";
import { useParams } from "react-router-dom";

import Item from "../../components/Thematics/Subscribe/Item";
import "./style.css";
import CenterLayout from "../../layouts/CenterLayout";
import LocationIcon from "components/atoms/icons/location";
import MailIcon from "components/atoms/icons/mail";
import PhoneIcon from "components/atoms/icons/phone";
import ArrowBackIcon from "components/atoms/icons/arrow-back";
export default function Profile() {
  const [accountInfo, setAccountInfo] = useState(false);
  const { user_id } = useParams();
  async function getAccount(user_id) {
    if (user_id) {
      try {
        setAccountInfo(await fetchProfile(user_id));
      } catch (error) {
        setAccountInfo(null);
      }
    }
  }

  const getFormatedLocation = () => {
    if (!accountInfo?.geolocation?.length) return null;

    const address = accountInfo?.geolocation?.filter(
      (item) => item.type === "LOC"
    )[0]?.name;

    return address;
  };

  useEffect(() => {
    getAccount(user_id);
  }, [user_id]);

  return (
    <CenterLayout className="profil_container">
      {window.history.state ? (
        <button onClick={() => window.history.back()} className="account__back">
          <ArrowBackIcon />
          Retour
        </button>
      ) : null}
      <div className="account__informations">
        {accountInfo ? (
          <>
            <div className="account__personal_info">
              <div
                className="account__personal_header"
                style={{
                  backgroundImage: `url(${accountInfo?.bannerPath || "/assets/img/banner-profil.png"
                    })`,
                }}
              ></div>
              <div className="account__personal_avatar">
                {accountInfo.avatarPath ? (
                  <img src={accountInfo.avatarPath} alt="avatar" />
                ) : (
                  <img src="/assets/icons/user-solid.svg" alt="avatar" />
                )}
              </div>
              <div className="account__personal-content">
                <div>
                  <h2>
                    {accountInfo.firstname} {accountInfo.lastname}
                  </h2>
                  <h4>{accountInfo.job}</h4>
                  <br />
                  <h4
                    style={{ textTransform: "uppercase", fontWeight: "bold" }}
                  >
                    {accountInfo?.Entity?.name}
                  </h4>
                  <h4>{accountInfo.Branch ? accountInfo.Branch.name : ""}</h4>
                </div>
                <ul className="account__personal-contact">
                  <li>
                    <LocationIcon />
                    {getFormatedLocation() ? (
                      <p style={{ textTransform: "capitalize" }}>
                        {getFormatedLocation()}
                      </p>
                    ) : (
                      <p>Aucune adresse enregistrée</p>
                    )}
                  </li>
                  <li>
                    <MailIcon />
                    <p>{accountInfo?.email}</p>
                  </li>
                  <li>
                    <PhoneIcon />
                    <p>{accountInfo?.phone || "Pas de numéro enregistré"}</p>
                  </li>
                </ul>

                {/* <button
                  class="btn btn-fill bg-main-hover btn-small text-main"
                  onClick={() => setOpenPasswordModal(true)}
                >
                  CHANGER DE MOT DE PASSE
                </button> */}
                <div className="account__referent">
                  {accountInfo.ReferentOfMainThemes ? (
                    <>
                      <h3>Référent Groupe</h3>
                      <ul>
                        {accountInfo.GroupReferentOfMainThemes.map(
                          (item, index) => (
                            <li key={index}>
                              <img
                                src={`/assets/img/icons/${item.icon
                                  }`}
                                alt={item.name}
                              />
                              <p>{item.name}</p>
                            </li>
                          )
                        )}
                      </ul>
                      <ul>
                        {accountInfo.GroupReferentOfSubThemes.map(
                          (item, index) => (
                            <li key={index}>
                              <div
                                className={`account_bullet-point bg-${item.color}`}
                              ></div>
                              <p>{item.name}</p>
                            </li>
                          )
                        )}
                      </ul>
                    </>
                  ) : (
                    ""
                  )}
                  {accountInfo.ReferentOfSubThemes ? (
                    <>
                      <h3>Référent Métier</h3>
                      <ul>
                        {accountInfo.ReferentOfMainThemes.map((item, index) => (
                          <li key={index}>
                            <img
                              src={`/assets/img/icons/${item.icon
                                }`}
                              alt={item.name}
                            />
                            <p>{item.name}</p>
                          </li>
                        ))}
                      </ul>
                      <ul>
                        {accountInfo.ReferentOfSubThemes.map((item, index) => (
                          <li key={index}>
                            <div
                              className={`account_bullet-point bg-${item.MainTheme.color}`}
                            ></div>
                            <p>{item.name}</p>
                          </li>
                        ))}
                      </ul>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="account__right-container">
              <div className="home__subscribe_thematics">
                <h2>Thématiques suivies</h2>
                <br />
                <ul>
                  {accountInfo &&
                    accountInfo.MainThemeSubscribed &&
                    accountInfo.MainThemeSubscribed.map((item, index) => (
                      <li key={index}>
                        <Item themeId={item.id} isPreview />
                      </li>
                    ))}
                </ul>
              </div>
              <div className="account__capsules-card" id="expertises-card">
                <h2>Missions & Expertises</h2>
                <br />
                <div>
                  {accountInfo?.Expertise?.map((item, index) => (
                    <span key={index}>{item?.name}</span>
                  ))}
                </div>
              </div>
              <div className="account__capsules-card" id="interventions-card">
                <h2>Périmètres d’intervention</h2>
                <br />
                <div>
                  {accountInfo?.InterventionZone?.map((item, index) => (
                    <span key={index}>{item.name}</span>
                  ))}
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="account__personal_info">
              <div>
                <div className="py-30 px-30">
                  <h2>
                    {accountInfo === null
                      ? "Utilisateur introuvable"
                      : "Chargement..."}
                  </h2>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </CenterLayout>
  );
}
