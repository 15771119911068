import { useState } from "react";

export const LoginClassic = ({ setEmail, setPassword }) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div style={{ width: "100%" }}>
      <input
        type="text"
        placeholder="EMail"
        name="email"
        onChange={(event) => setEmail(event.target.value)}
      />

      <div className="input-password__container">
        <button onClick={() => setShowPassword(!showPassword)}>
          <i className={`icon-${showPassword ? "eye-off" : "eye"}`} />
        </button>
        <input
          type={showPassword ? "text" : "password"}
          placeholder="Password"
          name="password"
          onChange={(event) => setPassword(event.target.value)}
        />
      </div>
    </div>
  );
};
