import React, { useState, useEffect } from "react";
import { fetchAllThemes } from "../../../api/Thematics/Themes";

export default function StepOne({ changeThemactic, currentForm }) {
  const [themesInfo, setThemesInfo] = useState([]);
  const [toggleSelectAll, setToggleSelectAll] = useState(false);

  const isChecked = (value, array) => {
    const result = array.findIndex((id) => id === Number(value));
    return result >= 0 ? true : false;
  };

  const changeSelectTheme = (event) => {
    let newCurrentForm = [...currentForm.thematic];
    const value = Number(event.target.value);

    const index = newCurrentForm.findIndex((id) => id === value);
    if (index >= 0) {
      newCurrentForm.splice(index, 1);
      changeThemactic({ ...currentForm, thematic: newCurrentForm });
    } else {
      newCurrentForm.push(value);
      changeThemactic({ ...currentForm, thematic: newCurrentForm });
    }
  };

  const selectAllThematics = () => {
    let listOfIdThematics = [];
    if (!toggleSelectAll) {
      for (const key in themesInfo) {
        listOfIdThematics.push(themesInfo[key].id);
      }
    }
    setToggleSelectAll(!toggleSelectAll);
    changeThemactic({ ...currentForm, thematic: listOfIdThematics });
  };

  async function getAllThemes(mainThemeId) {
    setThemesInfo(await fetchAllThemes(mainThemeId));
  }

  useEffect(() => {
    getAllThemes();
  }, []);

  useEffect(() => {
    setToggleSelectAll(themesInfo.length === currentForm.thematic.length);
  }, [themesInfo, currentForm]);

  return (
    <React.Fragment>
      <h1>
        étape <i>1</i>/3
      </h1>
      <p>Votre contenu concerne :</p>
      <form>
        <div className="propose-content__input-container">
          <input
            type="checkbox"
            id="selectAllThematics"
            name="selectAllThematics"
            value="all"
            onChange={selectAllThematics}
            checked={themesInfo.length === currentForm.thematic.length}
          />
          <label htmlFor="selectAllThematics">Toutes les thématiques</label>
        </div>
        {themesInfo.map((theme, index) => (
          <div key={index} className="propose-content__input-container">
            <input
              type="checkbox"
              id={`thematic-${theme.id}`}
              name="chooseThematic"
              value={theme.id}
              onChange={changeSelectTheme}
              checked={isChecked(theme.id, currentForm.thematic)}
            />
            <label htmlFor={`thematic-${theme.id}`}>
              <img
                src={`/assets/img/icons/${theme.icon}`}
                alt={theme.name || "theme_icon"}
              />{" "}
              {theme.name}
            </label>
          </div>
        ))}
      </form>
    </React.Fragment>
  );
}
