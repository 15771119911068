export default function ArrowDownIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11.342"
      height="6.378"
      viewBox="0 0 11.342 6.378"
    >
      <path
        d="M-3247.771,688l5.317,5.317,5.317-5.317"
        transform="translate(3248.125 -687.646)"
        fill="none"
        stroke="currentColor"
        stroke-width="1"
      />
    </svg>
  );
}
