import "./style.css";
import CenterLayout from "../layouts/CenterLayout";

export default function MentionsLegals() {
  return (
    <CenterLayout>
      <div className="legal_mention subtheme__content main">
        <h2>Mentions légales</h2>

        <p>
          Le Groupe Bouygues a le plaisir de vous accueillir sur son site HR
          Team. Ce site a pour objet de fournir un intranet recensant les
          informations importantes à la communauté RH du groupe Bouygues.
          Cependant, les renseignements et présentations contenus dans les pages
          de ce site sont fournis à titre purement indicatif et restent
          susceptibles de modifications à tout moment et sans préavis. Bouygues
          ne peut garantir l’exactitude, la précision ou l’exhaustivité des
          informations mises à disposition sur ce site.
        </p>

        <p>En conséquence, Bouygues décline toute responsabilité :</p>

        <div>
          <ul className="legal_mention_ul">
            <li>
              pour toute imprécision, inexactitude ou omission portant sur des
              informations disponibles sur le site;
            </li>
            <li>
              pour tous dommages, directs et/ou indirects, quelles qu’en soient
              le s causes, origines, natures ou conséquences, provoqués à raison
              de l’accès de quiconque au site ou de l’impossibilité d’y accéder,
              de même que l’utilisation du site et/ou du crédit accordé à une
              quelconque information provenant directement ou indirectement du
              site.
            </li>
          </ul>
        </div>

        <p>
          Tous les éléments du site sont protégés par les droits de propriété
          intellectuelle. La reproduction et l’utilisation de tous documents
          publiés sur ce site sont exclusivement autorisés à des fins
          strictement personnelles et privées et moyennant me ntion de la
          source. Toute reproduction, utilisation ou diffusion de copies
          réalisées à d’autres fins est expressément interdite et sanctionnée
          pénalement.
        </p>

        <p>
          Tout lien ou framing de ce site est strictement interdit et en
          conséquence, aucun élément figurant sur une page du site ne peut être
          partiellement ou totalement, diffusé, transféré ou inséré sur un autre
          site Internet, ni servir à créer des produits dérivés, sauf
          autorisation expresse de Bouygues.
        </p>

        <h2>Identifications</h2>

        <h3>Editeur du site</h3>

        <ul id="ml_site_ul" className="editor_ml_ul">
          <li>BOUYGUES</li>
          <li>Société Anonyme au capital de 378 957 297 euros</li>
          <li>Inscrite au RCS de Paris sous le n°572 015 246</li>
          <li>Siège Social : 32 avenue Hoche – 75 008 Paris</li>
          <li
            style={{
              color: "black",
              textDecoration: "none",
            }}
          >
            Responsable publication : Jean Sébastien Gomez - Agence RJS
          </li>
        </ul>

        <h2>Hébergeur</h2>
        <ul id="ml_site_ul">
          <li>OVH</li>
          <li>2 rue Kellermann 59100 Roubaix</li>
          <li>RCS 424 761 419 – Lille Métropole</li>
        </ul>

        <h2>Cookies</h2>

        <p>
          Des cookies sont utilisés sur le site. En utilisant notre site
          Internet, vous acceptez que des cookies soient placés sur votre
          terminal (ordinateur, mobile, tablette), sous réserve des choix que
          vous aurez exprimés et que vous pouvez modifier à tout moment.
        </p>
        <p>
          Les cookies utilisés sur le site ont une durée de vie maximale de 13
          mois.
        </p>
        <p>Définition – Finalités d’utilisation</p>

        <p>
          Un cookie est une information déposée sur votre disque dur par le
          serveur du site Internet que vous visitez.
        </p>
        <p>
          Le site HR Team utilise des cookies dans le but d’effectuer des
          analyses de fréquentation et des mesures d’audience de notre site en
          collectant des informations relatives à votre navigation . Les
          informations recueillies par Bouygues par ce biais sont anonymes et
          sont coll ectées dans le seul but d’effectuer des mesures et des
          analyses d’audience de notre site et de satisfaction de ses
          utilisateurs. Ces informations font l’objet d’un usage exclusif par
          Bouygues et ne sont aucunement partagées avec des tiers ou connectées
          à t outes autres informations.
        </p>
        <p>Principaux cookies utilisés par le site Mobyclic :</p>
        <p>
          Les cookies utilisés sur le site peuvent être mis en place soit par
          notre site Internet, soit par un site tiers. En outre, ces cookies
          peuvent être de session (dans ce cas, ce dernier sera automatiquement
          supprimé lors de la fermeture du navigateur) ou persistants (dans ce
          cas, le cookie restera stocké dans votre ordinateur jusqu’à sa date
          d’expiration).
        </p>
        <p>Bouygues utilise principalement les cookies suivants :</p>
        <p>
          Matomo : un outil d’analyse d’audience Internet, nous fournissant un
          ensemble de données et un service de suivi des activités des visiteurs
          de notre site. Lorsque vous visitez notre site, nous utilisons les
          cookies pour suivre et recevoir des informations anonymes sur vos a
          ctivités de recherche sur notre site Internet. L’outil Matomo nous
          permet d’améliorer votre expérience en ligne et la convivialité de
          notre site Internet. Il n’est en aucun cas utilisé pour recueillir des
          informations personnelles vous concernant, et n’est jamais rapproché
          de votre profil utilisateur si vous êtes inscrit sur notre site.
        </p>
        <p>
          Quels que soient les choix exprimés, vous pourrez à tout moment
          choisir de désactiver ou de réactiver les cookies.
        </p>
        <p>
          Votre navigateur peut également être paramétré pour vous signaler les
          cookies qui sont déposés dans votre ordinateur et vous demander de les
          accepter ou pas. Vous pouvez accepter ou refuser les cookies au cas
          par cas ou bien les refuser systématiquement.
        </p>
        <p>
          A titre informatif, vous pouvez modifier les paramètres « cookies » de
          votre navigateur en vous rendant sur la page d’aide de celui-ci
          concernant les cookies.
        </p>
      </div>
    </CenterLayout>
  );
}
