import axios from "axios";
import getFormatedToken from "helpers/getFormatedToken";

export async function sendFilters(payload) {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/api/users/filters`,
    payload,
    getFormatedToken()
  );
  const { data } = response.data;
  return data;
}
