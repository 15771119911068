import React, { useState, useEffect, useContext } from "react";

import Welcome from "../../components/Welcome/Welcome";
import Opportunities from "../../components/Welcome/Opportunities";
import "./style.css";
import { fetchAllNewcomers } from "../../api/Newcomers";
import { deleteNewcomers } from "../../api/Newcomers";
import { fetchAllOpportunities } from "../../api/Opportunities";
import { EditMode } from "../../contexts/editmode";

export default function WelcomePage() {
  const [opportunities, setOpportunities] = useState([]);
  const { editing } = useContext(EditMode);
  async function getAllOpportunities() {
    setOpportunities(await fetchAllOpportunities());
  }

  async function deleteWelcome(id) {
    await deleteNewcomers(id);
    await getAllNewcomers();
  }

  const [newcomers, setNewcomers] = useState([]);

  async function getAllNewcomers() {
    setNewcomers(await fetchAllNewcomers());
  }

  useEffect(() => {
    getAllNewcomers();
    getAllOpportunities();
  }, []);

  return (
    <React.Fragment>
      <div className={`welcome__container ${editing ? "admin-mode" : ""}`}>
        <Welcome items={newcomers} deleteWelcome={deleteWelcome} />
        <div className="welcome-page__container-mobyclic">
          <Opportunities opportunities={opportunities} />
        </div>
      </div>
    </React.Fragment>
  );
}
