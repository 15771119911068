import axios from "axios";
import getFormatedToken from "helpers/getFormatedToken";

export async function editZI(userId, interventionZoneId, name, force) {
  const response = await axios.put(
    `${process.env.REACT_APP_API_URL}/api/zi/user/update`,
    {
      userId: userId,
      [interventionZoneId ? "interventionZoneId" : "name"]:
        interventionZoneId || name,
      force: force,
    },
    getFormatedToken()
  );

  const { data } = response.data;
  return data || response.data;
}
