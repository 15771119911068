/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Menu from "./Menu";
import "./mobilenavbar.css";
import Profile from "./Profile";
import { fetchAccount } from "../../../api/Users/Me";
import { useLocation } from "react-router-dom";

const MobileNavbar = ({
  isOpen,
  setIsOpen,
  secondDropdownisFocused,
  setSecondDropdownisFocused,
}) => {
  let location = useLocation();
  const [accountInfo, setAccountInfo] = useState([]);

  async function getAccount() {
    setAccountInfo(await fetchAccount());
  }
  useEffect(() => {
    getAccount();
  }, []);

  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  return (
    <div
      className={
        isOpen ? "mob-navbar__container active" : "mob-navbar__container"
      }
    >
      <Profile data={accountInfo} />
      <Menu
        secondDropdownisFocused={secondDropdownisFocused}
        setSecondDropdownisFocused={setSecondDropdownisFocused}
      />
    </div>
  );
};

export default MobileNavbar;
