import React, { useState, useEffect } from "react";

import { PasswordCheck } from "../../components/molecules/password-validation/PasswordCheck";

//:: API imports
import { ChangePassword } from "../../api/Users/ChangePassword";
import { sendFinishRegistration } from "../../api/Users/CompleteRegistration";
import { PostAvatar } from "../../api/Users/PostAvatar";

import "./Login.css";
import CropImage from "../../components/molecules/CropImage";
import Cookies from "universal-cookie";

export default function CompleteRegistration({ user, setter }) {
  const [secondaryPassword, setSecondaryPassword] = useState(null);
  const [password, setPassword] = useState(null);
  const [firstname, setFirstname] = useState(null);
  const [lastname, setLastname] = useState(null);
  const [avatar, setAvatar] = useState(null);
  const [avatarBlob, setAvatarBlob] = useState(null);
  const [length, setLength] = useState(false);
  const [maj, setMaj] = useState(false);
  const [min, setMin] = useState(false);
  const [number, setNumber] = useState(false);
  const [special, setSpecial] = useState(false);
  const [fetch, setFetch] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [error, setError] = useState(null);
  const [step, setStep] = useState(0);

  const [isShow, setIsShow] = useState(false);
  const [isShow2, setIsShow2] = useState(false);

  useEffect(() => {
    if (user.openId) {
      setStep(1);
    }
  }, step);

  const handleChangePassword = async () => {
    if (password === secondaryPassword) {
      setStep(1);
    }
  };
  async function getBase64Url(file) {
    return new Promise((resolve) => {
      const reader = new FileReader();

      reader.addEventListener(
        "load",
        () => {
          setAvatarBlob(reader.result);
        },
        false
      );
      reader.readAsDataURL(file);
    });
  }
  const changeAvatar = (event) => {
    setAvatar(event.target.files[0]);
    setAvatarBlob(getBase64Url(event.target.files[0]));
  };
  const handleSendFullRegistration = async () => {
    if ((password, firstname, lastname, avatar)) {
      try {
        if (password && !user.openId) {
          await ChangePassword({
            password: password,
          });
        }
        await sendFinishRegistration({
          firstname: firstname,
          lastname: lastname,
        });

        const bodyFormData = new FormData();
        bodyFormData.append("avatar", avatar);
        await PostAvatar(bodyFormData, user.id);

        setter({ ...user, status: "ACTIVE" });
      } catch (error) {
        // TODO: Handle error properly
        // console.log(error);
      }
    } else {
      setError("Veuillez remplir tous les champs et ajouter un avatar");
    }
  };

  useEffect(() => {
    if (password) {
      if (/^(.{12,})/.test(password)) {
        setLength(true);
      } else {
        setLength(false);
      }

      if (/^(?=.*[a-z])/.test(password)) {
        setMin(true);
      } else {
        setMin(false);
      }

      if (/^(?=.*[A-Z])/.test(password)) {
        setMaj(true);
      } else {
        setMaj(false);
      }

      if (/^(?=.*\d)/.test(password)) {
        setNumber(true);
      } else {
        setNumber(false);
      }

      if (/^(?=.*[!@#$%^&*()+_\-=}{[\]|:;"/?.><,`~])/.test(password)) {
        setSpecial(true);
      } else {
        setSpecial(false);
      }
      if (password === secondaryPassword) {
        setFetch(true);
      } else {
        setFetch(false);
      }
    } else {
      setLength(false);
      setMin(false);
      setMaj(false);
      setNumber(false);
      setSpecial(false);
      setFetch(false);
    }
  }, [password, secondaryPassword]);

  function showPassword() {
    let input = document.getElementById("id_password");
    if (isShow) {
      input.type = "password";
      setIsShow(!isShow);
    } else {
      input.type = "text";
      setIsShow(!isShow);
    }
  }
  function showPassword2() {
    let input = document.getElementById("id_password2");
    if (isShow2) {
      input.type = "password";
      setIsShow2(!isShow2);
    } else {
      input.type = "text";
      setIsShow2(!isShow2);
    }
  }

  return (
    <section id="login-body">
      <div className="top-logo">
        <img src="/assets/icons/Footer/logo_bouygues.svg" alt="logo-topBar" />
      </div>
      {step === 0 ? (
        <div className="form-container">
          <h1 className="complete-register__step">
            étape <i>1</i>/2
          </h1>
          <div className="input-box">
            <h3>Modification du mot de passe</h3>
            <div className="password_container">
              <input
                id="id_password"
                type="password"
                placeholder="Nouveau mot de passe"
                name="password"
                onChange={(event) => setPassword(event.target.value)}
              />
              {isShow && isShow ? (
                <img
                  className="eye"
                  src="/assets/icons/Password/Hide.svg"
                  onClick={() => showPassword(isShow)}
                  alt="eye"
                />
              ) : (
                <img
                  className="eye"
                  src="/assets/icons/Password/Show.svg"
                  onClick={() => showPassword(isShow)}
                  alt="eye"
                />
              )}
            </div>
            <div className="password_container">
              <input
                id="id_password2"
                type="password"
                placeholder="Confirmer le nouveau mot de passe"
                name="confirm_password"
                onChange={(event) => setSecondaryPassword(event.target.value)}
              />
              {isShow2 && isShow2 ? (
                <img
                  className="eye"
                  src="/assets/icons/Password/Hide.svg"
                  onClick={() => showPassword2()}
                  alt="eye"
                />
              ) : (
                <img
                  className="eye"
                  src="/assets/icons/Password/Show.svg"
                  onClick={() => showPassword2()}
                  alt="eye"
                />
              )}
            </div>
          </div>
          <PasswordCheck
            length={length}
            maj={maj}
            min={min}
            number={number}
            special={special}
            fetch={fetch}
            callback={setIsValid}
          />
          {isValid ? (
            <button
              className="button-login"
              onClick={() => {
                handleChangePassword();
              }}
            >
              VALIDER
            </button>
          ) : (
            <button
              className="button-login"
              disabled
              style={{ opacity: "0.5", cursor: "not-allowed" }}
            >
              VALIDER
            </button>
          )}
        </div>
      ) : (
        <>
          {avatar ? (
            <CropImage
              setImage={setAvatar}
              setImageBlob={setAvatarBlob}
              src={avatar}
              ratio={4 / 4}
            />
          ) : (
            ""
          )}
          <div className="form-container">
            <h1 className="complete-register__step">
              étape <i>2</i>/2
            </h1>
            <div className="input-box">
              <h3>Info sur l'utilisateur</h3>
              <div className="container_avatar__input">
                <div className="avatar__input">
                  <img
                    src={
                      avatarBlob ? avatarBlob : "/assets/icons/user-solid.svg"
                    }
                    alt="avatar"
                  />
                  <input
                    type="file"
                    placeholder="Avatar"
                    className="input-avatar-ht"
                    name="avatar"
                    onChange={changeAvatar}
                  />
                </div>
                <p>Ajouter une photo de profil</p>
                {/* onChange={(event) => setAvatar(event.target.files[0])} */}
              </div>
              <input
                type="text"
                placeholder="Nom"
                name="lastname"
                onChange={(event) => setLastname(event.target.value)}
              />
              <input
                type="text"
                placeholder="Prénom"
                name="firstname"
                onChange={(event) => setFirstname(event.target.value)}
              />
            </div>

            <button
              className="button-login"
              onClick={() => {
                handleSendFullRegistration();
              }}
            >
              VALIDER
            </button>
            {error && <p className="error-message">{error}</p>}
          </div>
        </>
      )}
      <br />
      <a
        className="comm-button-comment"
        onClick={() => {
          const cookies = new Cookies();
          cookies.remove("::token");
          window.location.href = "/";
        }}
      >
        Cliquez ici si vous n'êtes pas à l'origine de cette inscription
      </a>
    </section>
  );
}
